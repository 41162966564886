/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** This field accepts both ISODateString and ISODateTimeStringexample: 1992-08-14 or 1992-08-14T03:42:00.000Z */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type ASample5 = {
  __typename?: 'ASample5';
  modular_blocks?: Maybe<Array<Maybe<ASample5ModularBlocks>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ASample5ModularBlocks = ASample5ModularBlocksMapp;

export type ASample5ModularBlocksMapp = {
  __typename?: 'ASample5ModularBlocksMapp';
  mapp?: Maybe<ASample5ModularBlocksMappBlock>;
};

export type ASample5ModularBlocksMappBlock = {
  __typename?: 'ASample5ModularBlocksMappBlock';
  referenceConnection?: Maybe<ASample5ModularBlocksMappBlockReferenceConnection>;
};

export type ASample5ModularBlocksMappBlockReferenceConnection = {
  __typename?: 'ASample5ModularBlocksMappBlockReferenceConnection';
  edges?: Maybe<Array<Maybe<ASample5ModularBlocksMappBlockReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ASample5ModularBlocksMappBlockReferenceEdge = {
  __typename?: 'ASample5ModularBlocksMappBlockReferenceEdge';
  node?: Maybe<ASample5ModularBlocksMappBlockReferenceNode>;
};

export type ASample5ModularBlocksMappBlockReferenceNode = CargoMainPage | CargoPetCalculator | CargoRichText | CargoStaffSection | CargoTextImageSection | CargoTileSection;

export type ASample5ModularBlocksMappBlockReferenceWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  CARGO_text_image_section?: InputMaybe<CargoTextImageSectionWhere>;
  CARGO_tile_section?: InputMaybe<CargoTileSectionWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_pet_calculator?: InputMaybe<CargoPetCalculatorWhere>;
  cargo_rich_text?: InputMaybe<CargoRichTextWhere>;
  cargo_staff_section?: InputMaybe<CargoStaffSectionWhere>;
};

export type ASample5ModularBlocksMappBlockWhere = {
  reference?: InputMaybe<ASample5ModularBlocksMappBlockReferenceWhere>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ASample5ModularBlocksWhere = {
  mapp?: InputMaybe<ASample5ModularBlocksMappBlockWhere>;
  mapp_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ASample5OrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type ASample5Where = {
  AND?: InputMaybe<Array<InputMaybe<ASample5Where>>>;
  OR?: InputMaybe<Array<InputMaybe<ASample5Where>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  modular_blocks?: InputMaybe<ASample5ModularBlocksWhere>;
  modular_blocks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AirArtists = {
  __typename?: 'AirArtists';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  video_interviewConnection?: Maybe<AirArtistsVideoInterviewConnection>;
};

export enum AirArtistsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type AirArtistsVideoInterviewConnection = {
  __typename?: 'AirArtistsVideoInterviewConnection';
  edges?: Maybe<Array<Maybe<AirArtistsVideoInterviewEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirArtistsVideoInterviewEdge = {
  __typename?: 'AirArtistsVideoInterviewEdge';
  node?: Maybe<AirArtistsVideoInterviewNode>;
};

export type AirArtistsVideoInterviewNode = AirVideoItem;

export type AirArtistsVideoInterviewWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_video_item?: InputMaybe<AirVideoItemWhere>;
};

export type AirArtistsWhere = {
  AND?: InputMaybe<Array<InputMaybe<AirArtistsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<AirArtistsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  video_interview?: InputMaybe<AirArtistsVideoInterviewWhere>;
  video_interview_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirFrontpage = {
  __typename?: 'AirFrontpage';
  artistsConnection?: Maybe<AirFrontpageArtistsConnection>;
  artists_text?: Maybe<Scalars['String']['output']>;
  artists_title?: Maybe<Scalars['String']['output']>;
  hero_videoConnection?: Maybe<AirFrontpageHeroVideoConnection>;
  historyConnection?: Maybe<AirFrontpageHistoryConnection>;
  history_text?: Maybe<Scalars['String']['output']>;
  history_title?: Maybe<Scalars['String']['output']>;
  line_up_day_oneConnection?: Maybe<AirFrontpageLineUpDayOneConnection>;
  line_up_day_one_text?: Maybe<Scalars['String']['output']>;
  line_up_day_one_title?: Maybe<Scalars['String']['output']>;
  line_up_day_twoConnection?: Maybe<AirFrontpageLineUpDayTwoConnection>;
  line_up_day_two_text?: Maybe<Scalars['String']['output']>;
  line_up_day_two_title?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  meta_data?: Maybe<AirMetadata>;
  system?: Maybe<EntrySystemField>;
  test_modular_blocks?: Maybe<Array<Maybe<AirFrontpageTestModularBlocks>>>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type AirFrontpageArtistsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AirFrontpageHistoryConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AirFrontpageLine_Up_Day_OneConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type AirFrontpageLine_Up_Day_TwoConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AirFrontpageArtistsConnection = {
  __typename?: 'AirFrontpageArtistsConnection';
  edges?: Maybe<Array<Maybe<AirFrontpageArtistsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirFrontpageArtistsEdge = {
  __typename?: 'AirFrontpageArtistsEdge';
  node?: Maybe<AirFrontpageArtistsNode>;
};

export type AirFrontpageArtistsNode = AirArtists;

export type AirFrontpageArtistsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_artists?: InputMaybe<AirArtistsWhere>;
};

export type AirFrontpageHeroVideoConnection = {
  __typename?: 'AirFrontpageHeroVideoConnection';
  edges?: Maybe<Array<Maybe<AirFrontpageHeroVideoEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirFrontpageHeroVideoEdge = {
  __typename?: 'AirFrontpageHeroVideoEdge';
  node?: Maybe<AirFrontpageHeroVideoNode>;
};

export type AirFrontpageHeroVideoNode = AirVideoItem;

export type AirFrontpageHeroVideoWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_video_item?: InputMaybe<AirVideoItemWhere>;
};

export type AirFrontpageHistoryConnection = {
  __typename?: 'AirFrontpageHistoryConnection';
  edges?: Maybe<Array<Maybe<AirFrontpageHistoryEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirFrontpageHistoryEdge = {
  __typename?: 'AirFrontpageHistoryEdge';
  node?: Maybe<AirFrontpageHistoryNode>;
};

export type AirFrontpageHistoryNode = AirGenericcontent;

export type AirFrontpageHistoryWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_genericcontent?: InputMaybe<AirGenericcontentWhere>;
};

export type AirFrontpageLineUpDayOneConnection = {
  __typename?: 'AirFrontpageLineUpDayOneConnection';
  edges?: Maybe<Array<Maybe<AirFrontpageLineUpDayOneEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirFrontpageLineUpDayOneEdge = {
  __typename?: 'AirFrontpageLineUpDayOneEdge';
  node?: Maybe<AirFrontpageLineUpDayOneNode>;
};

export type AirFrontpageLineUpDayOneNode = AirLineUp;

export type AirFrontpageLineUpDayOneWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_line_up?: InputMaybe<AirLineUpWhere>;
};

export type AirFrontpageLineUpDayTwoConnection = {
  __typename?: 'AirFrontpageLineUpDayTwoConnection';
  edges?: Maybe<Array<Maybe<AirFrontpageLineUpDayTwoEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirFrontpageLineUpDayTwoEdge = {
  __typename?: 'AirFrontpageLineUpDayTwoEdge';
  node?: Maybe<AirFrontpageLineUpDayTwoNode>;
};

export type AirFrontpageLineUpDayTwoNode = AirLineUp;

export type AirFrontpageLineUpDayTwoWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_line_up?: InputMaybe<AirLineUpWhere>;
};

export enum AirFrontpageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type AirFrontpageTestModularBlocks = AirFrontpageTestModularBlocksSectionTest;

export type AirFrontpageTestModularBlocksSectionTest = {
  __typename?: 'AirFrontpageTestModularBlocksSectionTest';
  section_test?: Maybe<AirFrontpageTestModularBlocksSectionTestBlock>;
};

export type AirFrontpageTestModularBlocksSectionTestBlock = {
  __typename?: 'AirFrontpageTestModularBlocksSectionTestBlock';
  test_referenceConnection?: Maybe<AirFrontpageTestModularBlocksSectionTestBlockTestReferenceConnection>;
  test_title?: Maybe<Scalars['String']['output']>;
};

export type AirFrontpageTestModularBlocksSectionTestBlockTestReferenceConnection = {
  __typename?: 'AirFrontpageTestModularBlocksSectionTestBlockTestReferenceConnection';
  edges?: Maybe<Array<Maybe<AirFrontpageTestModularBlocksSectionTestBlockTestReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirFrontpageTestModularBlocksSectionTestBlockTestReferenceEdge = {
  __typename?: 'AirFrontpageTestModularBlocksSectionTestBlockTestReferenceEdge';
  node?: Maybe<AirFrontpageTestModularBlocksSectionTestBlockTestReferenceNode>;
};

export type AirFrontpageTestModularBlocksSectionTestBlockTestReferenceNode = AirLineUp;

export type AirFrontpageTestModularBlocksSectionTestBlockTestReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_line_up?: InputMaybe<AirLineUpWhere>;
};

export type AirFrontpageTestModularBlocksSectionTestBlockWhere = {
  test_reference?: InputMaybe<AirFrontpageTestModularBlocksSectionTestBlockTestReferenceWhere>;
  test_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  test_title?: InputMaybe<Scalars['String']['input']>;
  test_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  test_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  test_title_ne?: InputMaybe<Scalars['String']['input']>;
  test_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AirFrontpageTestModularBlocksWhere = {
  section_test?: InputMaybe<AirFrontpageTestModularBlocksSectionTestBlockWhere>;
  section_test_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirFrontpageWhere = {
  AND?: InputMaybe<Array<InputMaybe<AirFrontpageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<AirFrontpageWhere>>>;
  artists?: InputMaybe<AirFrontpageArtistsWhere>;
  artists_count?: InputMaybe<Scalars['Int']['input']>;
  artists_exists?: InputMaybe<Scalars['Boolean']['input']>;
  artists_text?: InputMaybe<Scalars['String']['input']>;
  artists_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  artists_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  artists_text_ne?: InputMaybe<Scalars['String']['input']>;
  artists_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  artists_title?: InputMaybe<Scalars['String']['input']>;
  artists_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  artists_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  artists_title_ne?: InputMaybe<Scalars['String']['input']>;
  artists_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hero_video?: InputMaybe<AirFrontpageHeroVideoWhere>;
  hero_video_exists?: InputMaybe<Scalars['Boolean']['input']>;
  history?: InputMaybe<AirFrontpageHistoryWhere>;
  history_count?: InputMaybe<Scalars['Int']['input']>;
  history_exists?: InputMaybe<Scalars['Boolean']['input']>;
  history_text?: InputMaybe<Scalars['String']['input']>;
  history_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  history_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  history_text_ne?: InputMaybe<Scalars['String']['input']>;
  history_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  history_title?: InputMaybe<Scalars['String']['input']>;
  history_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  history_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  history_title_ne?: InputMaybe<Scalars['String']['input']>;
  history_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_one?: InputMaybe<AirFrontpageLineUpDayOneWhere>;
  line_up_day_one_count?: InputMaybe<Scalars['Int']['input']>;
  line_up_day_one_exists?: InputMaybe<Scalars['Boolean']['input']>;
  line_up_day_one_text?: InputMaybe<Scalars['String']['input']>;
  line_up_day_one_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  line_up_day_one_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_one_text_ne?: InputMaybe<Scalars['String']['input']>;
  line_up_day_one_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_one_title?: InputMaybe<Scalars['String']['input']>;
  line_up_day_one_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  line_up_day_one_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_one_title_ne?: InputMaybe<Scalars['String']['input']>;
  line_up_day_one_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_two?: InputMaybe<AirFrontpageLineUpDayTwoWhere>;
  line_up_day_two_count?: InputMaybe<Scalars['Int']['input']>;
  line_up_day_two_exists?: InputMaybe<Scalars['Boolean']['input']>;
  line_up_day_two_text?: InputMaybe<Scalars['String']['input']>;
  line_up_day_two_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  line_up_day_two_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_two_text_ne?: InputMaybe<Scalars['String']['input']>;
  line_up_day_two_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_two_title?: InputMaybe<Scalars['String']['input']>;
  line_up_day_two_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  line_up_day_two_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  line_up_day_two_title_ne?: InputMaybe<Scalars['String']['input']>;
  line_up_day_two_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_data?: InputMaybe<AirMetadataWhere>;
  meta_data_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  test_modular_blocks?: InputMaybe<AirFrontpageTestModularBlocksWhere>;
  test_modular_blocks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AirGenericcontent = {
  __typename?: 'AirGenericcontent';
  images?: Maybe<AirImage>;
  main_text?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum AirGenericcontentOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type AirGenericcontentWhere = {
  AND?: InputMaybe<Array<InputMaybe<AirGenericcontentWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<AirGenericcontentWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<AirImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AirImage = {
  __typename?: 'AirImage';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
};

export type AirImageWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirLineUp = {
  __typename?: 'AirLineUp';
  artistConnection?: Maybe<AirLineUpArtistConnection>;
  date_playing?: Maybe<Scalars['String']['output']>;
  images?: Maybe<AirImage>;
  schedule_at_time_?: Maybe<Scalars['String']['output']>;
  spotify_link?: Maybe<Link>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  vidoe_musicConnection?: Maybe<AirLineUpVidoeMusicConnection>;
};

export type AirLineUpArtistConnection = {
  __typename?: 'AirLineUpArtistConnection';
  edges?: Maybe<Array<Maybe<AirLineUpArtistEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirLineUpArtistEdge = {
  __typename?: 'AirLineUpArtistEdge';
  node?: Maybe<AirLineUpArtistNode>;
};

export type AirLineUpArtistNode = AirArtists;

export type AirLineUpArtistWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_artists?: InputMaybe<AirArtistsWhere>;
};

export enum AirLineUpOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type AirLineUpVidoeMusicConnection = {
  __typename?: 'AirLineUpVidoeMusicConnection';
  edges?: Maybe<Array<Maybe<AirLineUpVidoeMusicEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AirLineUpVidoeMusicEdge = {
  __typename?: 'AirLineUpVidoeMusicEdge';
  node?: Maybe<AirLineUpVidoeMusicNode>;
};

export type AirLineUpVidoeMusicNode = AirVideoItem;

export type AirLineUpVidoeMusicWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  air_video_item?: InputMaybe<AirVideoItemWhere>;
};

export type AirLineUpWhere = {
  AND?: InputMaybe<Array<InputMaybe<AirLineUpWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<AirLineUpWhere>>>;
  artist?: InputMaybe<AirLineUpArtistWhere>;
  artist_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  date_playing?: InputMaybe<Scalars['String']['input']>;
  date_playing_exists?: InputMaybe<Scalars['Boolean']['input']>;
  date_playing_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  date_playing_ne?: InputMaybe<Scalars['String']['input']>;
  date_playing_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<AirImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  schedule_at_time_?: InputMaybe<Scalars['String']['input']>;
  schedule_at_time__exists?: InputMaybe<Scalars['Boolean']['input']>;
  schedule_at_time__in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  schedule_at_time__ne?: InputMaybe<Scalars['String']['input']>;
  schedule_at_time__nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  spotify_link?: InputMaybe<LinkWhere>;
  spotify_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vidoe_music?: InputMaybe<AirLineUpVidoeMusicWhere>;
  vidoe_music_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AirMetadata = {
  __typename?: 'AirMetadata';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type AirMetadataWhere = {
  meta_description?: InputMaybe<Scalars['String']['input']>;
  meta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_description_ne?: InputMaybe<Scalars['String']['input']>;
  meta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title?: InputMaybe<Scalars['String']['input']>;
  meta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title_ne?: InputMaybe<Scalars['String']['input']>;
  meta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AirTranslationString = {
  __typename?: 'AirTranslationString';
  string_value?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum AirTranslationStringOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type AirTranslationStringWhere = {
  AND?: InputMaybe<Array<InputMaybe<AirTranslationStringWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<AirTranslationStringWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  string_value?: InputMaybe<Scalars['String']['input']>;
  string_value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  string_value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_value_ne?: InputMaybe<Scalars['String']['input']>;
  string_value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AirVideoItem = {
  __typename?: 'AirVideoItem';
  images?: Maybe<AirImage>;
  main_text?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  video_id?: Maybe<Scalars['String']['output']>;
};

export enum AirVideoItemOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type AirVideoItemWhere = {
  AND?: InputMaybe<Array<InputMaybe<AirVideoItemWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<AirVideoItemWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<AirImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  short_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  short_description_ne?: InputMaybe<Scalars['String']['input']>;
  short_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  video_id?: InputMaybe<Scalars['String']['input']>;
  video_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_id_ne?: InputMaybe<Scalars['String']['input']>;
  video_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AllASample5 = {
  __typename?: 'AllASample5';
  items?: Maybe<Array<Maybe<ASample5>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllAirArtists = {
  __typename?: 'AllAirArtists';
  items?: Maybe<Array<Maybe<AirArtists>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllAirFrontpage = {
  __typename?: 'AllAirFrontpage';
  items?: Maybe<Array<Maybe<AirFrontpage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllAirGenericcontent = {
  __typename?: 'AllAirGenericcontent';
  items?: Maybe<Array<Maybe<AirGenericcontent>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllAirLineUp = {
  __typename?: 'AllAirLineUp';
  items?: Maybe<Array<Maybe<AirLineUp>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllAirTranslationString = {
  __typename?: 'AllAirTranslationString';
  items?: Maybe<Array<Maybe<AirTranslationString>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllAirVideoItem = {
  __typename?: 'AllAirVideoItem';
  items?: Maybe<Array<Maybe<AirVideoItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllAuthor = {
  __typename?: 'AllAuthor';
  items?: Maybe<Array<Maybe<Author>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllBoilerFrontpage = {
  __typename?: 'AllBoilerFrontpage';
  items?: Maybe<Array<Maybe<BoilerFrontpage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllBoilerTranslationString = {
  __typename?: 'AllBoilerTranslationString';
  items?: Maybe<Array<Maybe<BoilerTranslationString>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoAircraftSpecifications = {
  __typename?: 'AllCargoAircraftSpecifications';
  items?: Maybe<Array<Maybe<CargoAircraftSpecifications>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoAirportNetwork = {
  __typename?: 'AllCargoAirportNetwork';
  items?: Maybe<Array<Maybe<CargoAirportNetwork>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoCollapsibleSection = {
  __typename?: 'AllCargoCollapsibleSection';
  items?: Maybe<Array<Maybe<CargoCollapsibleSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoFlightSchedule = {
  __typename?: 'AllCargoFlightSchedule';
  items?: Maybe<Array<Maybe<CargoFlightSchedule>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoFlightStatus = {
  __typename?: 'AllCargoFlightStatus';
  items?: Maybe<Array<Maybe<CargoFlightStatus>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoFooter = {
  __typename?: 'AllCargoFooter';
  items?: Maybe<Array<Maybe<CargoFooter>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoFrontpage = {
  __typename?: 'AllCargoFrontpage';
  items?: Maybe<Array<Maybe<CargoFrontpage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoImageSection = {
  __typename?: 'AllCargoImageSection';
  items?: Maybe<Array<Maybe<CargoImageSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoImageSectionItem = {
  __typename?: 'AllCargoImageSectionItem';
  items?: Maybe<Array<Maybe<CargoImageSectionItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoKalTest = {
  __typename?: 'AllCargoKalTest';
  items?: Maybe<Array<Maybe<CargoKalTest>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoLoginScreen = {
  __typename?: 'AllCargoLoginScreen';
  items?: Maybe<Array<Maybe<CargoLoginScreen>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoMainPage = {
  __typename?: 'AllCargoMainPage';
  items?: Maybe<Array<Maybe<CargoMainPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoNavigationMenuItem = {
  __typename?: 'AllCargoNavigationMenuItem';
  items?: Maybe<Array<Maybe<CargoNavigationMenuItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoPetCalculator = {
  __typename?: 'AllCargoPetCalculator';
  items?: Maybe<Array<Maybe<CargoPetCalculator>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoQuickSearch = {
  __typename?: 'AllCargoQuickSearch';
  items?: Maybe<Array<Maybe<CargoQuickSearch>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoRedirects = {
  __typename?: 'AllCargoRedirects';
  items?: Maybe<Array<Maybe<CargoRedirects>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoRichText = {
  __typename?: 'AllCargoRichText';
  items?: Maybe<Array<Maybe<CargoRichText>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoSalesforceForm = {
  __typename?: 'AllCargoSalesforceForm';
  items?: Maybe<Array<Maybe<CargoSalesforceForm>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoSearchScreen = {
  __typename?: 'AllCargoSearchScreen';
  items?: Maybe<Array<Maybe<CargoSearchScreen>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoSizeLimitations = {
  __typename?: 'AllCargoSizeLimitations';
  items?: Maybe<Array<Maybe<CargoSizeLimitations>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoStaffItem = {
  __typename?: 'AllCargoStaffItem';
  items?: Maybe<Array<Maybe<CargoStaffItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoStaffSection = {
  __typename?: 'AllCargoStaffSection';
  items?: Maybe<Array<Maybe<CargoStaffSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTestType = {
  __typename?: 'AllCargoTestType';
  items?: Maybe<Array<Maybe<CargoTestType>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTextImageSection = {
  __typename?: 'AllCargoTextImageSection';
  items?: Maybe<Array<Maybe<CargoTextImageSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTile = {
  __typename?: 'AllCargoTile';
  items?: Maybe<Array<Maybe<CargoTile>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTileSection = {
  __typename?: 'AllCargoTileSection';
  items?: Maybe<Array<Maybe<CargoTileSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTopNavigation = {
  __typename?: 'AllCargoTopNavigation';
  items?: Maybe<Array<Maybe<CargoTopNavigation>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTrackTrace = {
  __typename?: 'AllCargoTrackTrace';
  items?: Maybe<Array<Maybe<CargoTrackTrace>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTranslationString = {
  __typename?: 'AllCargoTranslationString';
  items?: Maybe<Array<Maybe<CargoTranslationString>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllCargoTranslationStringGroups = {
  __typename?: 'AllCargoTranslationStringGroups';
  items?: Maybe<Array<Maybe<CargoTranslationStringGroups>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllFeriaContentSection = {
  __typename?: 'AllFeriaContentSection';
  items?: Maybe<Array<Maybe<FeriaContentSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllFeriaFooter = {
  __typename?: 'AllFeriaFooter';
  items?: Maybe<Array<Maybe<FeriaFooter>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllFeriaMainNavigation = {
  __typename?: 'AllFeriaMainNavigation';
  items?: Maybe<Array<Maybe<FeriaMainNavigation>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllFeriaPage = {
  __typename?: 'AllFeriaPage';
  items?: Maybe<Array<Maybe<FeriaPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllFeriaTextImageSection = {
  __typename?: 'AllFeriaTextImageSection';
  items?: Maybe<Array<Maybe<FeriaTextImageSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllFeriaTranslationGroup = {
  __typename?: 'AllFeriaTranslationGroup';
  items?: Maybe<Array<Maybe<FeriaTranslationGroup>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupAnnualreport = {
  __typename?: 'AllGroupAnnualreport';
  items?: Maybe<Array<Maybe<GroupAnnualreport>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupCalendarentries = {
  __typename?: 'AllGroupCalendarentries';
  items?: Maybe<Array<Maybe<GroupCalendarentries>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupFinancialcalendar = {
  __typename?: 'AllGroupFinancialcalendar';
  items?: Maybe<Array<Maybe<GroupFinancialcalendar>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupFooter = {
  __typename?: 'AllGroupFooter';
  items?: Maybe<Array<Maybe<GroupFooter>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupFrontpage = {
  __typename?: 'AllGroupFrontpage';
  items?: Maybe<Array<Maybe<GroupFrontpage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupGenericContent = {
  __typename?: 'AllGroupGenericContent';
  items?: Maybe<Array<Maybe<GroupGenericContent>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupIcons = {
  __typename?: 'AllGroupIcons';
  items?: Maybe<Array<Maybe<GroupIcons>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupIframe = {
  __typename?: 'AllGroupIframe';
  items?: Maybe<Array<Maybe<GroupIframe>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupLogos = {
  __typename?: 'AllGroupLogos';
  items?: Maybe<Array<Maybe<GroupLogos>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupManagement = {
  __typename?: 'AllGroupManagement';
  items?: Maybe<Array<Maybe<GroupManagement>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupMenu = {
  __typename?: 'AllGroupMenu';
  items?: Maybe<Array<Maybe<GroupMenu>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupNews = {
  __typename?: 'AllGroupNews';
  items?: Maybe<Array<Maybe<GroupNews>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupNewsroom = {
  __typename?: 'AllGroupNewsroom';
  items?: Maybe<Array<Maybe<GroupNewsroom>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupPage = {
  __typename?: 'AllGroupPage';
  items?: Maybe<Array<Maybe<GroupPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupQuarterlyresults = {
  __typename?: 'AllGroupQuarterlyresults';
  items?: Maybe<Array<Maybe<GroupQuarterlyresults>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupStreamPage = {
  __typename?: 'AllGroupStreamPage';
  items?: Maybe<Array<Maybe<GroupStreamPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupTabcontent = {
  __typename?: 'AllGroupTabcontent';
  items?: Maybe<Array<Maybe<GroupTabcontent>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupTable = {
  __typename?: 'AllGroupTable';
  items?: Maybe<Array<Maybe<GroupTable>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupTranslationStrings = {
  __typename?: 'AllGroupTranslationStrings';
  items?: Maybe<Array<Maybe<GroupTranslationStrings>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllGroupTranslationStringsAll = {
  __typename?: 'AllGroupTranslationStringsAll';
  items?: Maybe<Array<Maybe<GroupTranslationStringsAll>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllIcon = {
  __typename?: 'AllIcon';
  items?: Maybe<Array<Maybe<Icon>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllIjcAirports = {
  __typename?: 'AllIjcAirports';
  items?: Maybe<Array<Maybe<IjcAirports>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllIjcFrontpage = {
  __typename?: 'AllIjcFrontpage';
  items?: Maybe<Array<Maybe<IjcFrontpage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllIjcGenericcontent = {
  __typename?: 'AllIjcGenericcontent';
  items?: Maybe<Array<Maybe<IjcGenericcontent>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllIjcKitchen = {
  __typename?: 'AllIjcKitchen';
  items?: Maybe<Array<Maybe<IjcKitchen>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllIjcMenu = {
  __typename?: 'AllIjcMenu';
  items?: Maybe<Array<Maybe<IjcMenu>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllIjcStaff = {
  __typename?: 'AllIjcStaff';
  items?: Maybe<Array<Maybe<IjcStaff>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLjcPage = {
  __typename?: 'AllLjcPage';
  items?: Maybe<Array<Maybe<LjcPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftAircraftData = {
  __typename?: 'AllLoftAircraftData';
  items?: Maybe<Array<Maybe<LoftAircraftData>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftContentbelts = {
  __typename?: 'AllLoftContentbelts';
  items?: Maybe<Array<Maybe<LoftContentbelts>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftFooter = {
  __typename?: 'AllLoftFooter';
  items?: Maybe<Array<Maybe<LoftFooter>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftMenu = {
  __typename?: 'AllLoftMenu';
  items?: Maybe<Array<Maybe<LoftMenu>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftNavigationMenuItem = {
  __typename?: 'AllLoftNavigationMenuItem';
  items?: Maybe<Array<Maybe<LoftNavigationMenuItem>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftPage = {
  __typename?: 'AllLoftPage';
  items?: Maybe<Array<Maybe<LoftPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftPopupPage = {
  __typename?: 'AllLoftPopupPage';
  items?: Maybe<Array<Maybe<LoftPopupPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftProjects = {
  __typename?: 'AllLoftProjects';
  items?: Maybe<Array<Maybe<LoftProjects>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftProjectsGlobe = {
  __typename?: 'AllLoftProjectsGlobe';
  items?: Maybe<Array<Maybe<LoftProjectsGlobe>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftQuoteSalesforce = {
  __typename?: 'AllLoftQuoteSalesforce';
  items?: Maybe<Array<Maybe<LoftQuoteSalesforce>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftTeamMember = {
  __typename?: 'AllLoftTeamMember';
  items?: Maybe<Array<Maybe<LoftTeamMember>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftTiles = {
  __typename?: 'AllLoftTiles';
  items?: Maybe<Array<Maybe<LoftTiles>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllLoftTranslationStrings = {
  __typename?: 'AllLoftTranslationStrings';
  items?: Maybe<Array<Maybe<LoftTranslationStrings>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllNews = {
  __typename?: 'AllNews';
  items?: Maybe<Array<Maybe<News>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllPhotographer = {
  __typename?: 'AllPhotographer';
  items?: Maybe<Array<Maybe<Photographer>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllSysAsset = {
  __typename?: 'AllSysAsset';
  items?: Maybe<Array<Maybe<SysAsset>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllTestingPage = {
  __typename?: 'AllTestingPage';
  items?: Maybe<Array<Maybe<TestingPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllVildarCardSection = {
  __typename?: 'AllVildarCardSection';
  items?: Maybe<Array<Maybe<VildarCardSection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllVildarContentPosts = {
  __typename?: 'AllVildarContentPosts';
  items?: Maybe<Array<Maybe<VildarContentPosts>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllVildarNavigation = {
  __typename?: 'AllVildarNavigation';
  items?: Maybe<Array<Maybe<VildarNavigation>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllVildarPage = {
  __typename?: 'AllVildarPage';
  items?: Maybe<Array<Maybe<VildarPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllVildarTranslationGroup = {
  __typename?: 'AllVildarTranslationGroup';
  items?: Maybe<Array<Maybe<VildarTranslationGroup>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllWinGridsection = {
  __typename?: 'AllWinGridsection';
  items?: Maybe<Array<Maybe<WinGridsection>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllWinPage = {
  __typename?: 'AllWinPage';
  items?: Maybe<Array<Maybe<WinPage>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AllWinTranslationGroup = {
  __typename?: 'AllWinTranslationGroup';
  items?: Maybe<Array<Maybe<WinTranslationGroup>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Author = {
  __typename?: 'Author';
  biography?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Images>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum AuthorOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type AuthorWhere = {
  AND?: InputMaybe<Array<InputMaybe<AuthorWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<AuthorWhere>>>;
  biography?: InputMaybe<Scalars['String']['input']>;
  biography_exists?: InputMaybe<Scalars['Boolean']['input']>;
  biography_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  biography_ne?: InputMaybe<Scalars['String']['input']>;
  biography_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<ImagesWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type BoilerFrontpage = {
  __typename?: 'BoilerFrontpage';
  main_text?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum BoilerFrontpageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type BoilerFrontpageWhere = {
  AND?: InputMaybe<Array<InputMaybe<BoilerFrontpageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<BoilerFrontpageWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type BoilerTranslationString = {
  __typename?: 'BoilerTranslationString';
  string_value?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum BoilerTranslationStringOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type BoilerTranslationStringWhere = {
  AND?: InputMaybe<Array<InputMaybe<BoilerTranslationStringWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<BoilerTranslationStringWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  string_value?: InputMaybe<Scalars['String']['input']>;
  string_value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  string_value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_value_ne?: InputMaybe<Scalars['String']['input']>;
  string_value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoAircraftSpecifications = {
  __typename?: 'CargoAircraftSpecifications';
  key_statistics?: Maybe<CargoAircraftSpecificationsKeyStatistics>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CargoAircraftSpecificationsKeyStatistics = {
  __typename?: 'CargoAircraftSpecificationsKeyStatistics';
  box?: Maybe<Array<Maybe<CargoAircraftSpecificationsKeyStatisticsBox>>>;
};

export type CargoAircraftSpecificationsKeyStatisticsBox = {
  __typename?: 'CargoAircraftSpecificationsKeyStatisticsBox';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CargoAircraftSpecificationsKeyStatisticsBoxWhere = {
  description?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_ne?: InputMaybe<Scalars['String']['input']>;
  description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_ne?: InputMaybe<Scalars['String']['input']>;
  value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoAircraftSpecificationsKeyStatisticsWhere = {
  box?: InputMaybe<CargoAircraftSpecificationsKeyStatisticsBoxWhere>;
  box_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CargoAircraftSpecificationsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoAircraftSpecificationsWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoAircraftSpecificationsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoAircraftSpecificationsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_statistics?: InputMaybe<CargoAircraftSpecificationsKeyStatisticsWhere>;
  key_statistics_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoAirportNetwork = {
  __typename?: 'CargoAirportNetwork';
  airport_code?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoAirportNetworkOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoAirportNetworkWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoAirportNetworkWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoAirportNetworkWhere>>>;
  airport_code?: InputMaybe<Scalars['String']['input']>;
  airport_code_exists?: InputMaybe<Scalars['Boolean']['input']>;
  airport_code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  airport_code_ne?: InputMaybe<Scalars['String']['input']>;
  airport_code_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoCollapsibleSection = {
  __typename?: 'CargoCollapsibleSection';
  collapsible_items?: Maybe<Array<Maybe<CargoCollapsibleSectionCollapsibleItems>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CargoCollapsibleSectionCollapsibleItems = {
  __typename?: 'CargoCollapsibleSectionCollapsibleItems';
  rich_text_body?: Maybe<Scalars['String']['output']>;
  sub_collapsble_items?: Maybe<Array<Maybe<CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItems>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItems = {
  __typename?: 'CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItems';
  content_itemConnection?: Maybe<CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemConnection>;
  rich_text_body?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemConnection = {
  __typename?: 'CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemConnection';
  edges?: Maybe<Array<Maybe<CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemEdge = {
  __typename?: 'CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemEdge';
  node?: Maybe<CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemNode>;
};

export type CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemNode = CargoPetCalculator;

export type CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_pet_calculator?: InputMaybe<CargoPetCalculatorWhere>;
};

export type CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsWhere = {
  content_item?: InputMaybe<CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsContentItemWhere>;
  content_item_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rich_text_body?: InputMaybe<Scalars['String']['input']>;
  rich_text_body_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rich_text_body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rich_text_body_ne?: InputMaybe<Scalars['String']['input']>;
  rich_text_body_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoCollapsibleSectionCollapsibleItemsWhere = {
  rich_text_body?: InputMaybe<Scalars['String']['input']>;
  rich_text_body_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rich_text_body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rich_text_body_ne?: InputMaybe<Scalars['String']['input']>;
  rich_text_body_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sub_collapsble_items?: InputMaybe<CargoCollapsibleSectionCollapsibleItemsSubCollapsbleItemsWhere>;
  sub_collapsble_items_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum CargoCollapsibleSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoCollapsibleSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoCollapsibleSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoCollapsibleSectionWhere>>>;
  collapsible_items?: InputMaybe<CargoCollapsibleSectionCollapsibleItemsWhere>;
  collapsible_items_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoContentbelts = {
  __typename?: 'CargoContentbelts';
  contentbelt_itemsConnection?: Maybe<CargoContentbeltsContentbeltItemsConnection>;
};


export type CargoContentbeltsContentbelt_ItemsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoContentbeltsContentbeltItemsConnection = {
  __typename?: 'CargoContentbeltsContentbeltItemsConnection';
  edges?: Maybe<Array<Maybe<CargoContentbeltsContentbeltItemsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoContentbeltsContentbeltItemsEdge = {
  __typename?: 'CargoContentbeltsContentbeltItemsEdge';
  node?: Maybe<CargoContentbeltsContentbeltItemsNode>;
};

export type CargoContentbeltsContentbeltItemsNode = CargoAircraftSpecifications | CargoCollapsibleSection | CargoFlightSchedule | CargoFlightStatus | CargoImageSection | CargoPetCalculator | CargoRichText | CargoSalesforceForm | CargoSizeLimitations | CargoStaffSection | CargoTextImageSection | CargoTileSection | CargoTrackTrace;

export type CargoContentbeltsContentbeltItemsWhere = {
  CARGO_Salesforce_form?: InputMaybe<CargoSalesforceFormWhere>;
  CARGO_text_image_section?: InputMaybe<CargoTextImageSectionWhere>;
  CARGO_tile_section?: InputMaybe<CargoTileSectionWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_aircraft_specifications?: InputMaybe<CargoAircraftSpecificationsWhere>;
  cargo_collapsible_section?: InputMaybe<CargoCollapsibleSectionWhere>;
  cargo_flight_schedule?: InputMaybe<CargoFlightScheduleWhere>;
  cargo_flight_status?: InputMaybe<CargoFlightStatusWhere>;
  cargo_image_section?: InputMaybe<CargoImageSectionWhere>;
  cargo_pet_calculator?: InputMaybe<CargoPetCalculatorWhere>;
  cargo_rich_text?: InputMaybe<CargoRichTextWhere>;
  cargo_size_limitations?: InputMaybe<CargoSizeLimitationsWhere>;
  cargo_staff_section?: InputMaybe<CargoStaffSectionWhere>;
  cargo_track_trace?: InputMaybe<CargoTrackTraceWhere>;
};

export type CargoContentbeltsWhere = {
  contentbelt_items?: InputMaybe<CargoContentbeltsContentbeltItemsWhere>;
  contentbelt_items_count?: InputMaybe<Scalars['Int']['input']>;
  contentbelt_items_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoFlightSchedule = {
  __typename?: 'CargoFlightSchedule';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoFlightScheduleOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoFlightScheduleWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoFlightScheduleWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoFlightScheduleWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoFlightStatus = {
  __typename?: 'CargoFlightStatus';
  airportsConnection?: Maybe<CargoFlightStatusAirportsConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type CargoFlightStatusAirportsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoFlightStatusAirportsConnection = {
  __typename?: 'CargoFlightStatusAirportsConnection';
  edges?: Maybe<Array<Maybe<CargoFlightStatusAirportsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoFlightStatusAirportsEdge = {
  __typename?: 'CargoFlightStatusAirportsEdge';
  node?: Maybe<CargoFlightStatusAirportsNode>;
};

export type CargoFlightStatusAirportsNode = CargoAirportNetwork;

export type CargoFlightStatusAirportsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_airport_network?: InputMaybe<CargoAirportNetworkWhere>;
};

export enum CargoFlightStatusOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoFlightStatusWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoFlightStatusWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoFlightStatusWhere>>>;
  airports?: InputMaybe<CargoFlightStatusAirportsWhere>;
  airports_count?: InputMaybe<Scalars['Int']['input']>;
  airports_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoFooter = {
  __typename?: 'CargoFooter';
  contact_us_area?: Maybe<CargoFooterContactUsArea>;
  cta_area?: Maybe<CargoFooterCtaArea>;
  footer_copyright_info?: Maybe<CargoFooterFooterCopyrightInfo>;
  logo_fieldConnection?: Maybe<SysAssetConnection>;
  navigationConnection?: Maybe<CargoFooterNavigationConnection>;
  social_media_links?: Maybe<Array<Maybe<CargoFooterSocialMediaLinks>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type CargoFooterNavigationConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoFooterContactUsArea = {
  __typename?: 'CargoFooterContactUsArea';
  details?: Maybe<Array<Maybe<CargoFooterContactUsAreaDetails>>>;
  link?: Maybe<Scalars['String']['output']>;
  link_title?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CargoFooterContactUsAreaDetails = {
  __typename?: 'CargoFooterContactUsAreaDetails';
  phone_number?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
};

export type CargoFooterContactUsAreaDetailsWhere = {
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_number_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_number_ne?: InputMaybe<Scalars['String']['input']>;
  phone_number_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  service?: InputMaybe<Scalars['String']['input']>;
  service_exists?: InputMaybe<Scalars['Boolean']['input']>;
  service_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  service_ne?: InputMaybe<Scalars['String']['input']>;
  service_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoFooterContactUsAreaWhere = {
  details?: InputMaybe<CargoFooterContactUsAreaDetailsWhere>;
  details_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_ne?: InputMaybe<Scalars['String']['input']>;
  link_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_title?: InputMaybe<Scalars['String']['input']>;
  link_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_title_ne?: InputMaybe<Scalars['String']['input']>;
  link_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoFooterCtaArea = {
  __typename?: 'CargoFooterCtaArea';
  cta_description?: Maybe<Scalars['String']['output']>;
  cta_link?: Maybe<Link>;
  cta_title?: Maybe<Scalars['String']['output']>;
};

export type CargoFooterCtaAreaWhere = {
  cta_description?: InputMaybe<Scalars['String']['input']>;
  cta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_description_ne?: InputMaybe<Scalars['String']['input']>;
  cta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_link?: InputMaybe<LinkWhere>;
  cta_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_title?: InputMaybe<Scalars['String']['input']>;
  cta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_title_ne?: InputMaybe<Scalars['String']['input']>;
  cta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoFooterFooterCopyrightInfo = {
  __typename?: 'CargoFooterFooterCopyrightInfo';
  text_line?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CargoFooterFooterCopyrightInfoWhere = {
  text_line?: InputMaybe<Scalars['String']['input']>;
  text_line_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_line_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_line_ne?: InputMaybe<Scalars['String']['input']>;
  text_line_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoFooterNavigationConnection = {
  __typename?: 'CargoFooterNavigationConnection';
  edges?: Maybe<Array<Maybe<CargoFooterNavigationEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoFooterNavigationEdge = {
  __typename?: 'CargoFooterNavigationEdge';
  node?: Maybe<CargoFooterNavigationNode>;
};

export type CargoFooterNavigationNode = CargoNavigationMenuItem;

export type CargoFooterNavigationWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_navigation_menu_item?: InputMaybe<CargoNavigationMenuItemWhere>;
};

export enum CargoFooterOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoFooterSocialMediaLinks = CargoFooterSocialMediaLinksSocialMediaItem;

export type CargoFooterSocialMediaLinksSocialMediaItem = {
  __typename?: 'CargoFooterSocialMediaLinksSocialMediaItem';
  social_media_item?: Maybe<CargoFooterSocialMediaLinksSocialMediaItemBlock>;
};

export type CargoFooterSocialMediaLinksSocialMediaItemBlock = {
  __typename?: 'CargoFooterSocialMediaLinksSocialMediaItemBlock';
  link?: Maybe<Link>;
  social_media_iconConnection?: Maybe<SysAssetConnection>;
};

export type CargoFooterSocialMediaLinksSocialMediaItemBlockWhere = {
  link?: InputMaybe<LinkWhere>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  social_media_icon?: InputMaybe<SysAssetWhere>;
  social_media_icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoFooterSocialMediaLinksWhere = {
  social_media_item?: InputMaybe<CargoFooterSocialMediaLinksSocialMediaItemBlockWhere>;
  social_media_item_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoFooterWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoFooterWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoFooterWhere>>>;
  contact_us_area?: InputMaybe<CargoFooterContactUsAreaWhere>;
  contact_us_area_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_area?: InputMaybe<CargoFooterCtaAreaWhere>;
  cta_area_exists?: InputMaybe<Scalars['Boolean']['input']>;
  footer_copyright_info?: InputMaybe<CargoFooterFooterCopyrightInfoWhere>;
  footer_copyright_info_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo_field?: InputMaybe<SysAssetWhere>;
  logo_field_exists?: InputMaybe<Scalars['Boolean']['input']>;
  navigation?: InputMaybe<CargoFooterNavigationWhere>;
  navigation_count?: InputMaybe<Scalars['Int']['input']>;
  navigation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  social_media_links?: InputMaybe<CargoFooterSocialMediaLinksWhere>;
  social_media_links_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoFrontpage = {
  __typename?: 'CargoFrontpage';
  action_buttons?: Maybe<Array<Maybe<CargoFrontpageActionButtons>>>;
  banner?: Maybe<Array<Maybe<CargoFrontpageBanner>>>;
  contentbelt_global?: Maybe<CargoContentbelts>;
  meta_data?: Maybe<CargoMetaData>;
  salesforce_form_button?: Maybe<CargoFrontpageSalesforceFormButton>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  translationsConnection?: Maybe<CargoFrontpageTranslationsConnection>;
};


export type CargoFrontpageTranslationsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoFrontpageActionButtons = {
  __typename?: 'CargoFrontpageActionButtons';
  iconConnection?: Maybe<SysAssetConnection>;
  internal_link_referenceConnection?: Maybe<CargoFrontpageActionButtonsInternalLinkReferenceConnection>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CargoFrontpageActionButtonsInternalLinkReferenceConnection = {
  __typename?: 'CargoFrontpageActionButtonsInternalLinkReferenceConnection';
  edges?: Maybe<Array<Maybe<CargoFrontpageActionButtonsInternalLinkReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoFrontpageActionButtonsInternalLinkReferenceEdge = {
  __typename?: 'CargoFrontpageActionButtonsInternalLinkReferenceEdge';
  node?: Maybe<CargoFrontpageActionButtonsInternalLinkReferenceNode>;
};

export type CargoFrontpageActionButtonsInternalLinkReferenceNode = CargoMainPage;

export type CargoFrontpageActionButtonsInternalLinkReferenceWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoFrontpageActionButtonsWhere = {
  icon?: InputMaybe<SysAssetWhere>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_link_reference?: InputMaybe<CargoFrontpageActionButtonsInternalLinkReferenceWhere>;
  internal_link_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoFrontpageBanner = {
  __typename?: 'CargoFrontpageBanner';
  banner_image?: Maybe<CargoFrontpageBannerBannerImage>;
  banner_text?: Maybe<CargoFrontpageBannerBannerText>;
  videoConnection?: Maybe<SysAssetConnection>;
  video_poster?: Maybe<CargoImageGlobal>;
};

export type CargoFrontpageBannerBannerImage = {
  __typename?: 'CargoFrontpageBannerBannerImage';
  global_field?: Maybe<CargoImageGlobal>;
};

export type CargoFrontpageBannerBannerImageWhere = {
  global_field?: InputMaybe<CargoImageGlobalWhere>;
  global_field_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoFrontpageBannerBannerText = {
  __typename?: 'CargoFrontpageBannerBannerText';
  cta_button_linkConnection?: Maybe<CargoFrontpageBannerBannerTextCtaButtonLinkConnection>;
  cta_button_text?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CargoFrontpageBannerBannerTextCtaButtonLinkConnection = {
  __typename?: 'CargoFrontpageBannerBannerTextCtaButtonLinkConnection';
  edges?: Maybe<Array<Maybe<CargoFrontpageBannerBannerTextCtaButtonLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoFrontpageBannerBannerTextCtaButtonLinkEdge = {
  __typename?: 'CargoFrontpageBannerBannerTextCtaButtonLinkEdge';
  node?: Maybe<CargoFrontpageBannerBannerTextCtaButtonLinkNode>;
};

export type CargoFrontpageBannerBannerTextCtaButtonLinkNode = CargoTestType;

export type CargoFrontpageBannerBannerTextCtaButtonLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_test_type?: InputMaybe<CargoTestTypeWhere>;
};

export type CargoFrontpageBannerBannerTextWhere = {
  cta_button_link?: InputMaybe<CargoFrontpageBannerBannerTextCtaButtonLinkWhere>;
  cta_button_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_button_text?: InputMaybe<Scalars['String']['input']>;
  cta_button_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_button_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_button_text_ne?: InputMaybe<Scalars['String']['input']>;
  cta_button_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoFrontpageBannerWhere = {
  banner_image?: InputMaybe<CargoFrontpageBannerBannerImageWhere>;
  banner_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  banner_text?: InputMaybe<CargoFrontpageBannerBannerTextWhere>;
  banner_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video?: InputMaybe<SysAssetWhere>;
  video_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video_poster?: InputMaybe<CargoImageGlobalWhere>;
  video_poster_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CargoFrontpageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoFrontpageSalesforceFormButton = {
  __typename?: 'CargoFrontpageSalesforceFormButton';
  button_label?: Maybe<Scalars['String']['output']>;
  page_referenceConnection?: Maybe<CargoFrontpageSalesforceFormButtonPageReferenceConnection>;
};

export type CargoFrontpageSalesforceFormButtonPageReferenceConnection = {
  __typename?: 'CargoFrontpageSalesforceFormButtonPageReferenceConnection';
  edges?: Maybe<Array<Maybe<CargoFrontpageSalesforceFormButtonPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoFrontpageSalesforceFormButtonPageReferenceEdge = {
  __typename?: 'CargoFrontpageSalesforceFormButtonPageReferenceEdge';
  node?: Maybe<CargoFrontpageSalesforceFormButtonPageReferenceNode>;
};

export type CargoFrontpageSalesforceFormButtonPageReferenceNode = CargoMainPage;

export type CargoFrontpageSalesforceFormButtonPageReferenceWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoFrontpageSalesforceFormButtonWhere = {
  button_label?: InputMaybe<Scalars['String']['input']>;
  button_label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_label_ne?: InputMaybe<Scalars['String']['input']>;
  button_label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_reference?: InputMaybe<CargoFrontpageSalesforceFormButtonPageReferenceWhere>;
  page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoFrontpageTranslationsConnection = {
  __typename?: 'CargoFrontpageTranslationsConnection';
  edges?: Maybe<Array<Maybe<CargoFrontpageTranslationsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoFrontpageTranslationsEdge = {
  __typename?: 'CargoFrontpageTranslationsEdge';
  node?: Maybe<CargoFrontpageTranslationsNode>;
};

export type CargoFrontpageTranslationsNode = CargoTranslationStringGroups;

export type CargoFrontpageTranslationsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_translation_string_groups?: InputMaybe<CargoTranslationStringGroupsWhere>;
};

export type CargoFrontpageWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoFrontpageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoFrontpageWhere>>>;
  action_buttons?: InputMaybe<CargoFrontpageActionButtonsWhere>;
  action_buttons_exists?: InputMaybe<Scalars['Boolean']['input']>;
  banner?: InputMaybe<CargoFrontpageBannerWhere>;
  banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentbelt_global?: InputMaybe<CargoContentbeltsWhere>;
  contentbelt_global_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_data?: InputMaybe<CargoMetaDataWhere>;
  meta_data_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  salesforce_form_button?: InputMaybe<CargoFrontpageSalesforceFormButtonWhere>;
  salesforce_form_button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations?: InputMaybe<CargoFrontpageTranslationsWhere>;
  translations_count?: InputMaybe<Scalars['Int']['input']>;
  translations_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoImageGlobal = {
  __typename?: 'CargoImageGlobal';
  desktop_imageConnection?: Maybe<SysAssetConnection>;
  desktop_image_crop?: Maybe<Scalars['String']['output']>;
  mobile_imageConnection?: Maybe<SysAssetConnection>;
  mobile_image_crop?: Maybe<Scalars['String']['output']>;
};

export type CargoImageGlobalWhere = {
  desktop_image?: InputMaybe<SysAssetWhere>;
  desktop_image_crop?: InputMaybe<Scalars['String']['input']>;
  desktop_image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  desktop_image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  desktop_image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  desktop_image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  desktop_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_image?: InputMaybe<SysAssetWhere>;
  mobile_image_crop?: InputMaybe<Scalars['String']['input']>;
  mobile_image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobile_image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  mobile_image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobile_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoImageSection = {
  __typename?: 'CargoImageSection';
  imagesConnection?: Maybe<CargoImageSectionImagesConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type CargoImageSectionImagesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoImageSectionImagesConnection = {
  __typename?: 'CargoImageSectionImagesConnection';
  edges?: Maybe<Array<Maybe<CargoImageSectionImagesEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoImageSectionImagesEdge = {
  __typename?: 'CargoImageSectionImagesEdge';
  node?: Maybe<CargoImageSectionImagesNode>;
};

export type CargoImageSectionImagesNode = CargoImageSectionItem;

export type CargoImageSectionImagesWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_image_section_item?: InputMaybe<CargoImageSectionItemWhere>;
};

export type CargoImageSectionItem = {
  __typename?: 'CargoImageSectionItem';
  hide_title?: Maybe<Scalars['Boolean']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoImageSectionItemOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoImageSectionItemWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoImageSectionItemWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoImageSectionItemWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hide_title?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_ne?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum CargoImageSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoImageSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoImageSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoImageSectionWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<CargoImageSectionImagesWhere>;
  images_count?: InputMaybe<Scalars['Int']['input']>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoKalTest = {
  __typename?: 'CargoKalTest';
  imagesConnection?: Maybe<SysAssetConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type CargoKalTestImagesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CargoKalTestOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoKalTestWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoKalTestWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoKalTestWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<SysAssetWhere>;
  images_count?: InputMaybe<Scalars['Int']['input']>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoLoginScreen = {
  __typename?: 'CargoLoginScreen';
  imageConnection?: Maybe<SysAssetConnection>;
  notice?: Maybe<Scalars['String']['output']>;
  sub_title?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoLoginScreenOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoLoginScreenWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoLoginScreenWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoLoginScreenWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notice?: InputMaybe<Scalars['String']['input']>;
  notice_exists?: InputMaybe<Scalars['Boolean']['input']>;
  notice_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notice_ne?: InputMaybe<Scalars['String']['input']>;
  notice_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  sub_title?: InputMaybe<Scalars['String']['input']>;
  sub_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sub_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sub_title_ne?: InputMaybe<Scalars['String']['input']>;
  sub_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoMainPage = {
  __typename?: 'CargoMainPage';
  algolia_page_rank?: Maybe<Scalars['Float']['output']>;
  contentbelt_global?: Maybe<CargoContentbelts>;
  featured_image?: Maybe<CargoImageGlobal>;
  keyword?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  meta_data?: Maybe<CargoMetaData>;
  navigation_menuConnection?: Maybe<CargoMainPageNavigationMenuConnection>;
  side_menu_hidden?: Maybe<Scalars['Boolean']['output']>;
  system?: Maybe<EntrySystemField>;
  tiles_listConnection?: Maybe<CargoMainPageTilesListConnection>;
  title?: Maybe<Scalars['String']['output']>;
  title_visible?: Maybe<Scalars['Boolean']['output']>;
  translationsConnection?: Maybe<CargoMainPageTranslationsConnection>;
  url?: Maybe<Scalars['String']['output']>;
};


export type CargoMainPageTranslationsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoMainPageNavigationMenuConnection = {
  __typename?: 'CargoMainPageNavigationMenuConnection';
  edges?: Maybe<Array<Maybe<CargoMainPageNavigationMenuEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoMainPageNavigationMenuEdge = {
  __typename?: 'CargoMainPageNavigationMenuEdge';
  node?: Maybe<CargoMainPageNavigationMenuNode>;
};

export type CargoMainPageNavigationMenuNode = CargoNavigationMenuItem;

export type CargoMainPageNavigationMenuWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_navigation_menu_item?: InputMaybe<CargoNavigationMenuItemWhere>;
};

export enum CargoMainPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoMainPageTilesListConnection = {
  __typename?: 'CargoMainPageTilesListConnection';
  edges?: Maybe<Array<Maybe<CargoMainPageTilesListEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoMainPageTilesListEdge = {
  __typename?: 'CargoMainPageTilesListEdge';
  node?: Maybe<CargoMainPageTilesListNode>;
};

export type CargoMainPageTilesListNode = CargoTileSection;

export type CargoMainPageTilesListWhere = {
  CARGO_tile_section?: InputMaybe<CargoTileSectionWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoMainPageTranslationsConnection = {
  __typename?: 'CargoMainPageTranslationsConnection';
  edges?: Maybe<Array<Maybe<CargoMainPageTranslationsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoMainPageTranslationsEdge = {
  __typename?: 'CargoMainPageTranslationsEdge';
  node?: Maybe<CargoMainPageTranslationsNode>;
};

export type CargoMainPageTranslationsNode = CargoTranslationStringGroups;

export type CargoMainPageTranslationsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_translation_string_groups?: InputMaybe<CargoTranslationStringGroupsWhere>;
};

export type CargoMainPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoMainPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoMainPageWhere>>>;
  algolia_page_rank?: InputMaybe<Scalars['Float']['input']>;
  algolia_page_rank_exists?: InputMaybe<Scalars['Boolean']['input']>;
  algolia_page_rank_gt?: InputMaybe<Scalars['Float']['input']>;
  algolia_page_rank_gte?: InputMaybe<Scalars['Float']['input']>;
  algolia_page_rank_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  algolia_page_rank_lt?: InputMaybe<Scalars['Float']['input']>;
  algolia_page_rank_lte?: InputMaybe<Scalars['Float']['input']>;
  algolia_page_rank_ne?: InputMaybe<Scalars['Float']['input']>;
  algolia_page_rank_nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contentbelt_global?: InputMaybe<CargoContentbeltsWhere>;
  contentbelt_global_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  featured_image?: InputMaybe<CargoImageGlobalWhere>;
  featured_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keyword_exists?: InputMaybe<Scalars['Boolean']['input']>;
  keyword_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keyword_ne?: InputMaybe<Scalars['String']['input']>;
  keyword_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_data?: InputMaybe<CargoMetaDataWhere>;
  meta_data_exists?: InputMaybe<Scalars['Boolean']['input']>;
  navigation_menu?: InputMaybe<CargoMainPageNavigationMenuWhere>;
  navigation_menu_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  side_menu_hidden?: InputMaybe<Scalars['Boolean']['input']>;
  side_menu_hidden_exists?: InputMaybe<Scalars['Boolean']['input']>;
  side_menu_hidden_ne?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tiles_list?: InputMaybe<CargoMainPageTilesListWhere>;
  tiles_list_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_visible?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_ne?: InputMaybe<Scalars['Boolean']['input']>;
  translations?: InputMaybe<CargoMainPageTranslationsWhere>;
  translations_count?: InputMaybe<Scalars['Int']['input']>;
  translations_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoMetaData = {
  __typename?: 'CargoMetaData';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_imageConnection?: Maybe<SysAssetConnection>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type CargoMetaDataWhere = {
  meta_description?: InputMaybe<Scalars['String']['input']>;
  meta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_description_ne?: InputMaybe<Scalars['String']['input']>;
  meta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_image?: InputMaybe<SysAssetWhere>;
  meta_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_title?: InputMaybe<Scalars['String']['input']>;
  meta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title_ne?: InputMaybe<Scalars['String']['input']>;
  meta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoNavigationMenuItem = {
  __typename?: 'CargoNavigationMenuItem';
  display_title?: Maybe<Scalars['String']['output']>;
  sub_navigation_itemsConnection?: Maybe<CargoNavigationMenuItemSubNavigationItemsConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type CargoNavigationMenuItemSub_Navigation_ItemsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CargoNavigationMenuItemOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoNavigationMenuItemSubNavigationItemsConnection = {
  __typename?: 'CargoNavigationMenuItemSubNavigationItemsConnection';
  edges?: Maybe<Array<Maybe<CargoNavigationMenuItemSubNavigationItemsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoNavigationMenuItemSubNavigationItemsEdge = {
  __typename?: 'CargoNavigationMenuItemSubNavigationItemsEdge';
  node?: Maybe<CargoNavigationMenuItemSubNavigationItemsNode>;
};

export type CargoNavigationMenuItemSubNavigationItemsNode = CargoMainPage;

export type CargoNavigationMenuItemSubNavigationItemsWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoNavigationMenuItemWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoNavigationMenuItemWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoNavigationMenuItemWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  sub_navigation_items?: InputMaybe<CargoNavigationMenuItemSubNavigationItemsWhere>;
  sub_navigation_items_count?: InputMaybe<Scalars['Int']['input']>;
  sub_navigation_items_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoPetCalculator = {
  __typename?: 'CargoPetCalculator';
  left_image?: Maybe<CargoImageGlobal>;
  right_image?: Maybe<CargoImageGlobal>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoPetCalculatorOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoPetCalculatorWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoPetCalculatorWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoPetCalculatorWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  left_image?: InputMaybe<CargoImageGlobalWhere>;
  left_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  right_image?: InputMaybe<CargoImageGlobalWhere>;
  right_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoQuickSearch = {
  __typename?: 'CargoQuickSearch';
  keywords?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  referenceConnection?: Maybe<CargoQuickSearchReferenceConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoQuickSearchOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoQuickSearchReferenceConnection = {
  __typename?: 'CargoQuickSearchReferenceConnection';
  edges?: Maybe<Array<Maybe<CargoQuickSearchReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoQuickSearchReferenceEdge = {
  __typename?: 'CargoQuickSearchReferenceEdge';
  node?: Maybe<CargoQuickSearchReferenceNode>;
};

export type CargoQuickSearchReferenceNode = CargoMainPage;

export type CargoQuickSearchReferenceWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoQuickSearchWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoQuickSearchWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoQuickSearchWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  keywords_exists?: InputMaybe<Scalars['Boolean']['input']>;
  keywords_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords_ne?: InputMaybe<Scalars['String']['input']>;
  keywords_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  reference?: InputMaybe<CargoQuickSearchReferenceWhere>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoRedirects = {
  __typename?: 'CargoRedirects';
  redirects?: Maybe<Array<Maybe<CargoRedirectsRedirects>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoRedirectsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoRedirectsRedirects = {
  __typename?: 'CargoRedirectsRedirects';
  old_urls?: Maybe<CargoRedirectsRedirectsOldUrls>;
  referenceConnection?: Maybe<CargoRedirectsRedirectsReferenceConnection>;
};

export type CargoRedirectsRedirectsOldUrls = {
  __typename?: 'CargoRedirectsRedirectsOldUrls';
  url?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CargoRedirectsRedirectsOldUrlsWhere = {
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoRedirectsRedirectsReferenceConnection = {
  __typename?: 'CargoRedirectsRedirectsReferenceConnection';
  edges?: Maybe<Array<Maybe<CargoRedirectsRedirectsReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoRedirectsRedirectsReferenceEdge = {
  __typename?: 'CargoRedirectsRedirectsReferenceEdge';
  node?: Maybe<CargoRedirectsRedirectsReferenceNode>;
};

export type CargoRedirectsRedirectsReferenceNode = CargoMainPage;

export type CargoRedirectsRedirectsReferenceWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoRedirectsRedirectsWhere = {
  old_urls?: InputMaybe<CargoRedirectsRedirectsOldUrlsWhere>;
  old_urls_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reference?: InputMaybe<CargoRedirectsRedirectsReferenceWhere>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoRedirectsWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoRedirectsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoRedirectsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  redirects?: InputMaybe<CargoRedirectsRedirectsWhere>;
  redirects_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoRichText = {
  __typename?: 'CargoRichText';
  rich_text?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoRichTextOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoRichTextWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoRichTextWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoRichTextWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  rich_text?: InputMaybe<Scalars['String']['input']>;
  rich_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rich_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rich_text_ne?: InputMaybe<Scalars['String']['input']>;
  rich_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoSalesforceForm = {
  __typename?: 'CargoSalesforceForm';
  form_id?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoSalesforceFormOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoSalesforceFormWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoSalesforceFormWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoSalesforceFormWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  form_id?: InputMaybe<Scalars['String']['input']>;
  form_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  form_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  form_id_ne?: InputMaybe<Scalars['String']['input']>;
  form_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoSearchScreen = {
  __typename?: 'CargoSearchScreen';
  content?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoSearchScreenOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoSearchScreenWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoSearchScreenWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoSearchScreenWhere>>>;
  content?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_ne?: InputMaybe<Scalars['String']['input']>;
  content_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoSizeLimitations = {
  __typename?: 'CargoSizeLimitations';
  system?: Maybe<EntrySystemField>;
  table_rows?: Maybe<Array<Maybe<CargoSizeLimitationsTableRows>>>;
  title?: Maybe<Scalars['String']['output']>;
  x_axis?: Maybe<Array<Maybe<CargoSizeLimitationsXAxis>>>;
  y_axis_labels?: Maybe<Array<Maybe<CargoSizeLimitationsYAxisLabels>>>;
};

export enum CargoSizeLimitationsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoSizeLimitationsTableRows = {
  __typename?: 'CargoSizeLimitationsTableRows';
  table_columns?: Maybe<Array<Maybe<CargoSizeLimitationsTableRowsTableColumns>>>;
};

export type CargoSizeLimitationsTableRowsTableColumns = {
  __typename?: 'CargoSizeLimitationsTableRowsTableColumns';
  value_cm?: Maybe<Scalars['String']['output']>;
  value_inches?: Maybe<Scalars['String']['output']>;
};

export type CargoSizeLimitationsTableRowsTableColumnsWhere = {
  value_cm?: InputMaybe<Scalars['String']['input']>;
  value_cm_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_cm_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_cm_ne?: InputMaybe<Scalars['String']['input']>;
  value_cm_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_inches?: InputMaybe<Scalars['String']['input']>;
  value_inches_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_inches_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_inches_ne?: InputMaybe<Scalars['String']['input']>;
  value_inches_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoSizeLimitationsTableRowsWhere = {
  table_columns?: InputMaybe<CargoSizeLimitationsTableRowsTableColumnsWhere>;
  table_columns_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoSizeLimitationsWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoSizeLimitationsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoSizeLimitationsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  table_rows?: InputMaybe<CargoSizeLimitationsTableRowsWhere>;
  table_rows_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  x_axis?: InputMaybe<CargoSizeLimitationsXAxisWhere>;
  x_axis_exists?: InputMaybe<Scalars['Boolean']['input']>;
  y_axis_labels?: InputMaybe<CargoSizeLimitationsYAxisLabelsWhere>;
  y_axis_labels_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CargoSizeLimitationsXAxis = {
  __typename?: 'CargoSizeLimitationsXAxis';
  value_cm?: Maybe<Scalars['String']['output']>;
  value_inches?: Maybe<Scalars['String']['output']>;
};

export type CargoSizeLimitationsXAxisWhere = {
  value_cm?: InputMaybe<Scalars['String']['input']>;
  value_cm_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_cm_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_cm_ne?: InputMaybe<Scalars['String']['input']>;
  value_cm_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_inches?: InputMaybe<Scalars['String']['input']>;
  value_inches_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_inches_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_inches_ne?: InputMaybe<Scalars['String']['input']>;
  value_inches_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoSizeLimitationsYAxisLabels = {
  __typename?: 'CargoSizeLimitationsYAxisLabels';
  value_cm?: Maybe<Scalars['String']['output']>;
  value_inches?: Maybe<Scalars['String']['output']>;
};

export type CargoSizeLimitationsYAxisLabelsWhere = {
  value_cm?: InputMaybe<Scalars['String']['input']>;
  value_cm_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_cm_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_cm_ne?: InputMaybe<Scalars['String']['input']>;
  value_cm_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_inches?: InputMaybe<Scalars['String']['input']>;
  value_inches_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_inches_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_inches_ne?: InputMaybe<Scalars['String']['input']>;
  value_inches_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoStaffDep = {
  __typename?: 'CargoStaffDep';
  department?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CargoStaffDepWhere = {
  department?: InputMaybe<Scalars['String']['input']>;
  department_exists?: InputMaybe<Scalars['Boolean']['input']>;
  department_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  department_ne?: InputMaybe<Scalars['String']['input']>;
  department_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoStaffDepartment = {
  __typename?: 'CargoStaffDepartment';
  department?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CargoStaffDepartmentWhere = {
  department?: InputMaybe<Scalars['String']['input']>;
  department_exists?: InputMaybe<Scalars['Boolean']['input']>;
  department_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  department_ne?: InputMaybe<Scalars['String']['input']>;
  department_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoStaffItem = {
  __typename?: 'CargoStaffItem';
  company_department?: Maybe<CargoStaffDep>;
  department?: Maybe<CargoStaffDepartment>;
  email?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  job_title?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoStaffItemOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoStaffItemWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoStaffItemWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoStaffItemWhere>>>;
  company_department?: InputMaybe<CargoStaffDepWhere>;
  company_department_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  department?: InputMaybe<CargoStaffDepartmentWhere>;
  department_exists?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_exists?: InputMaybe<Scalars['Boolean']['input']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_ne?: InputMaybe<Scalars['String']['input']>;
  email_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  job_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  job_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  job_title_ne?: InputMaybe<Scalars['String']['input']>;
  job_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_ne?: InputMaybe<Scalars['String']['input']>;
  phone_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoStaffSection = {
  __typename?: 'CargoStaffSection';
  department_display_order?: Maybe<CargoStaffSectionDepartmentDisplayOrder>;
  staff_membersConnection?: Maybe<CargoStaffSectionStaffMembersConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type CargoStaffSectionStaff_MembersConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoStaffSectionDepartmentDisplayOrder = {
  __typename?: 'CargoStaffSectionDepartmentDisplayOrder';
  department?: Maybe<Array<Maybe<CargoStaffDep>>>;
};

export type CargoStaffSectionDepartmentDisplayOrderWhere = {
  department?: InputMaybe<CargoStaffDepWhere>;
  department_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CargoStaffSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoStaffSectionStaffMembersConnection = {
  __typename?: 'CargoStaffSectionStaffMembersConnection';
  edges?: Maybe<Array<Maybe<CargoStaffSectionStaffMembersEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoStaffSectionStaffMembersEdge = {
  __typename?: 'CargoStaffSectionStaffMembersEdge';
  node?: Maybe<CargoStaffSectionStaffMembersNode>;
};

export type CargoStaffSectionStaffMembersNode = CargoStaffItem;

export type CargoStaffSectionStaffMembersWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_staff_item?: InputMaybe<CargoStaffItemWhere>;
};

export type CargoStaffSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoStaffSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoStaffSectionWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  department_display_order?: InputMaybe<CargoStaffSectionDepartmentDisplayOrderWhere>;
  department_display_order_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  staff_members?: InputMaybe<CargoStaffSectionStaffMembersWhere>;
  staff_members_count?: InputMaybe<Scalars['Int']['input']>;
  staff_members_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoTestType = {
  __typename?: 'CargoTestType';
  cta_buttons?: Maybe<CargoTestTypeCtaButtons>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CargoTestTypeCtaButtons = {
  __typename?: 'CargoTestTypeCtaButtons';
  iconConnection?: Maybe<SysAssetConnection>;
  label?: Maybe<Scalars['String']['output']>;
  reference_pageConnection?: Maybe<CargoTestTypeCtaButtonsReferencePageConnection>;
  type_track_link_?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CargoTestTypeCtaButtonsReferencePageConnection = {
  __typename?: 'CargoTestTypeCtaButtonsReferencePageConnection';
  edges?: Maybe<Array<Maybe<CargoTestTypeCtaButtonsReferencePageEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoTestTypeCtaButtonsReferencePageEdge = {
  __typename?: 'CargoTestTypeCtaButtonsReferencePageEdge';
  node?: Maybe<CargoTestTypeCtaButtonsReferencePageNode>;
};

export type CargoTestTypeCtaButtonsReferencePageNode = CargoTestType;

export type CargoTestTypeCtaButtonsReferencePageWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_test_type?: InputMaybe<CargoTestTypeWhere>;
};

export type CargoTestTypeCtaButtonsWhere = {
  icon?: InputMaybe<SysAssetWhere>;
  icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reference_page?: InputMaybe<CargoTestTypeCtaButtonsReferencePageWhere>;
  reference_page_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_track_link_?: InputMaybe<Scalars['String']['input']>;
  type_track_link__exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_track_link__in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_track_link__ne?: InputMaybe<Scalars['String']['input']>;
  type_track_link__nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum CargoTestTypeOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTestTypeWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTestTypeWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTestTypeWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_buttons?: InputMaybe<CargoTestTypeCtaButtonsWhere>;
  cta_buttons_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoTextImageSection = {
  __typename?: 'CargoTextImageSection';
  image?: Maybe<CargoImageGlobal>;
  link_to_external_website?: Maybe<Link>;
  main_text?: Maybe<Scalars['String']['output']>;
  no_image?: Maybe<Scalars['Boolean']['output']>;
  page_referenceConnection?: Maybe<CargoTextImageSectionPageReferenceConnection>;
  page_reference_label?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  title_visible?: Maybe<Scalars['Boolean']['output']>;
  youtube_video_id?: Maybe<Scalars['String']['output']>;
};

export enum CargoTextImageSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTextImageSectionPageReferenceConnection = {
  __typename?: 'CargoTextImageSectionPageReferenceConnection';
  edges?: Maybe<Array<Maybe<CargoTextImageSectionPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoTextImageSectionPageReferenceEdge = {
  __typename?: 'CargoTextImageSectionPageReferenceEdge';
  node?: Maybe<CargoTextImageSectionPageReferenceNode>;
};

export type CargoTextImageSectionPageReferenceNode = CargoMainPage;

export type CargoTextImageSectionPageReferenceWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoTextImageSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTextImageSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTextImageSectionWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<CargoImageGlobalWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_to_external_website?: InputMaybe<LinkWhere>;
  link_to_external_website_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  no_image?: InputMaybe<Scalars['Boolean']['input']>;
  no_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_image_ne?: InputMaybe<Scalars['Boolean']['input']>;
  page_reference?: InputMaybe<CargoTextImageSectionPageReferenceWhere>;
  page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_reference_label?: InputMaybe<Scalars['String']['input']>;
  page_reference_label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_reference_label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_reference_label_ne?: InputMaybe<Scalars['String']['input']>;
  page_reference_label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_visible?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_ne?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  youtube_video_id?: InputMaybe<Scalars['String']['input']>;
  youtube_video_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  youtube_video_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  youtube_video_id_ne?: InputMaybe<Scalars['String']['input']>;
  youtube_video_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CargoTile = {
  __typename?: 'CargoTile';
  image?: Maybe<CargoImageGlobal>;
  link_to_external_website?: Maybe<Link>;
  main_text?: Maybe<Scalars['String']['output']>;
  page_referenceConnection?: Maybe<CargoTilePageReferenceConnection>;
  page_reference_label?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  title_visible?: Maybe<Scalars['Boolean']['output']>;
};

export enum CargoTileOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTilePageReferenceConnection = {
  __typename?: 'CargoTilePageReferenceConnection';
  edges?: Maybe<Array<Maybe<CargoTilePageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoTilePageReferenceEdge = {
  __typename?: 'CargoTilePageReferenceEdge';
  node?: Maybe<CargoTilePageReferenceNode>;
};

export type CargoTilePageReferenceNode = CargoMainPage;

export type CargoTilePageReferenceWhere = {
  CARGO_main_page?: InputMaybe<CargoMainPageWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoTileSection = {
  __typename?: 'CargoTileSection';
  background_color?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  tiles_listConnection?: Maybe<CargoTileSectionTilesListConnection>;
  title?: Maybe<Scalars['String']['output']>;
  title_visible?: Maybe<Scalars['Boolean']['output']>;
};


export type CargoTileSectionTiles_ListConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CargoTileSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTileSectionTilesListConnection = {
  __typename?: 'CargoTileSectionTilesListConnection';
  edges?: Maybe<Array<Maybe<CargoTileSectionTilesListEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoTileSectionTilesListEdge = {
  __typename?: 'CargoTileSectionTilesListEdge';
  node?: Maybe<CargoTileSectionTilesListNode>;
};

export type CargoTileSectionTilesListNode = CargoTile;

export type CargoTileSectionTilesListWhere = {
  CARGO_tile?: InputMaybe<CargoTileWhere>;
  MATCH?: InputMaybe<EvalReferenceEnum>;
};

export type CargoTileSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTileSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTileSectionWhere>>>;
  background_color?: InputMaybe<Scalars['String']['input']>;
  background_color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  background_color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_color_ne?: InputMaybe<Scalars['String']['input']>;
  background_color_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tiles_list?: InputMaybe<CargoTileSectionTilesListWhere>;
  tiles_list_count?: InputMaybe<Scalars['Int']['input']>;
  tiles_list_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_visible?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_ne?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoTileWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTileWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTileWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<CargoImageGlobalWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_to_external_website?: InputMaybe<LinkWhere>;
  link_to_external_website_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_reference?: InputMaybe<CargoTilePageReferenceWhere>;
  page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_reference_label?: InputMaybe<Scalars['String']['input']>;
  page_reference_label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_reference_label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_reference_label_ne?: InputMaybe<Scalars['String']['input']>;
  page_reference_label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_visible?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_visible_ne?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoTopNavigation = {
  __typename?: 'CargoTopNavigation';
  log_in_link?: Maybe<Link>;
  logoConnection?: Maybe<SysAssetConnection>;
  navigation_menu_itemsConnection?: Maybe<CargoTopNavigationNavigationMenuItemsConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type CargoTopNavigationNavigation_Menu_ItemsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type CargoTopNavigationNavigationMenuItemsConnection = {
  __typename?: 'CargoTopNavigationNavigationMenuItemsConnection';
  edges?: Maybe<Array<Maybe<CargoTopNavigationNavigationMenuItemsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoTopNavigationNavigationMenuItemsEdge = {
  __typename?: 'CargoTopNavigationNavigationMenuItemsEdge';
  node?: Maybe<CargoTopNavigationNavigationMenuItemsNode>;
};

export type CargoTopNavigationNavigationMenuItemsNode = CargoNavigationMenuItem;

export type CargoTopNavigationNavigationMenuItemsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_navigation_menu_item?: InputMaybe<CargoNavigationMenuItemWhere>;
};

export enum CargoTopNavigationOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTopNavigationWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTopNavigationWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTopNavigationWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  log_in_link?: InputMaybe<LinkWhere>;
  log_in_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<SysAssetWhere>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  navigation_menu_items?: InputMaybe<CargoTopNavigationNavigationMenuItemsWhere>;
  navigation_menu_items_count?: InputMaybe<Scalars['Int']['input']>;
  navigation_menu_items_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoTrackTrace = {
  __typename?: 'CargoTrackTrace';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CargoTrackTraceOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTrackTraceWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTrackTraceWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTrackTraceWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CargoTranslationString = {
  __typename?: 'CargoTranslationString';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type CargoTranslationStringGroups = {
  __typename?: 'CargoTranslationStringGroups';
  group_code_name?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  translation_stringsConnection?: Maybe<CargoTranslationStringGroupsTranslationStringsConnection>;
};


export type CargoTranslationStringGroupsTranslation_StringsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CargoTranslationStringGroupsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTranslationStringGroupsTranslationStringsConnection = {
  __typename?: 'CargoTranslationStringGroupsTranslationStringsConnection';
  edges?: Maybe<Array<Maybe<CargoTranslationStringGroupsTranslationStringsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CargoTranslationStringGroupsTranslationStringsEdge = {
  __typename?: 'CargoTranslationStringGroupsTranslationStringsEdge';
  node?: Maybe<CargoTranslationStringGroupsTranslationStringsNode>;
};

export type CargoTranslationStringGroupsTranslationStringsNode = CargoTranslationString;

export type CargoTranslationStringGroupsTranslationStringsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  cargo_translation_string?: InputMaybe<CargoTranslationStringWhere>;
};

export type CargoTranslationStringGroupsWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTranslationStringGroupsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTranslationStringGroupsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  group_code_name?: InputMaybe<Scalars['String']['input']>;
  group_code_name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  group_code_name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  group_code_name_ne?: InputMaybe<Scalars['String']['input']>;
  group_code_name_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translation_strings?: InputMaybe<CargoTranslationStringGroupsTranslationStringsWhere>;
  translation_strings_count?: InputMaybe<Scalars['Int']['input']>;
  translation_strings_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum CargoTranslationStringOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type CargoTranslationStringWhere = {
  AND?: InputMaybe<Array<InputMaybe<CargoTranslationStringWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<CargoTranslationStringWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translation?: InputMaybe<Scalars['String']['input']>;
  translation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  translation_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translation_ne?: InputMaybe<Scalars['String']['input']>;
  translation_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type DeletedReferencesConnection = {
  __typename?: 'DeletedReferencesConnection';
  edges?: Maybe<Array<Maybe<DeletedReferencesEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DeletedReferencesEdge = {
  __typename?: 'DeletedReferencesEdge';
  node?: Maybe<Scalars['String']['output']>;
};

export type EntrySystemField = {
  __typename?: 'EntrySystemField';
  branch?: Maybe<Scalars['String']['output']>;
  content_type_uid?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  extensionConnection?: Maybe<SysExtensionConnection>;
  locale?: Maybe<Scalars['String']['output']>;
  publish_details?: Maybe<SystemPublishDetails>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type EntrySystemFieldExtensionConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum EvalReferenceEnum {
  All = 'ALL',
  Any = 'ANY'
}

export type FeriaContentSection = {
  __typename?: 'FeriaContentSection';
  background_color?: Maybe<Scalars['String']['output']>;
  collapsibles?: Maybe<Array<Maybe<FeriaContentSectionCollapsibles>>>;
  staff_profiles?: Maybe<Array<Maybe<FeriaContentSectionStaffProfiles>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FeriaContentSectionCollapsibles = {
  __typename?: 'FeriaContentSectionCollapsibles';
  body_text?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  salesforce_form_url?: Maybe<Scalars['String']['output']>;
  subheading_h3?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FeriaContentSectionCollapsiblesWhere = {
  body_text?: InputMaybe<Scalars['String']['input']>;
  body_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  body_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  body_text_ne?: InputMaybe<Scalars['String']['input']>;
  body_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<ImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  salesforce_form_url?: InputMaybe<Scalars['String']['input']>;
  salesforce_form_url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  salesforce_form_url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salesforce_form_url_ne?: InputMaybe<Scalars['String']['input']>;
  salesforce_form_url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading_h3?: InputMaybe<Scalars['String']['input']>;
  subheading_h3_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subheading_h3_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subheading_h3_ne?: InputMaybe<Scalars['String']['input']>;
  subheading_h3_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum FeriaContentSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type FeriaContentSectionStaffProfiles = {
  __typename?: 'FeriaContentSectionStaffProfiles';
  imageConnection?: Maybe<SysAssetConnection>;
  job_title?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FeriaContentSectionStaffProfilesWhere = {
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  job_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  job_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  job_title_ne?: InputMaybe<Scalars['String']['input']>;
  job_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_ne?: InputMaybe<Scalars['String']['input']>;
  name_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FeriaContentSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<FeriaContentSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<FeriaContentSectionWhere>>>;
  background_color?: InputMaybe<Scalars['String']['input']>;
  background_color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  background_color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_color_ne?: InputMaybe<Scalars['String']['input']>;
  background_color_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  collapsibles?: InputMaybe<FeriaContentSectionCollapsiblesWhere>;
  collapsibles_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  staff_profiles?: InputMaybe<FeriaContentSectionStaffProfilesWhere>;
  staff_profiles_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type FeriaFooter = {
  __typename?: 'FeriaFooter';
  buttons?: Maybe<Array<Maybe<FeriaFooterButtons>>>;
  footer_copyright_info?: Maybe<Scalars['String']['output']>;
  logo_field?: Maybe<Image>;
  system?: Maybe<EntrySystemField>;
  text_field_1?: Maybe<Scalars['String']['output']>;
  text_field_2?: Maybe<Scalars['String']['output']>;
  text_field_3?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type FeriaFooterButtons = FeriaFooterButtonsCta;

export type FeriaFooterButtonsCta = {
  __typename?: 'FeriaFooterButtonsCta';
  cta?: Maybe<FeriaFooterButtonsCtaBlock>;
};

export type FeriaFooterButtonsCtaBlock = {
  __typename?: 'FeriaFooterButtonsCtaBlock';
  button_style?: Maybe<Scalars['String']['output']>;
  external_link_url?: Maybe<Scalars['String']['output']>;
  internal_linkConnection?: Maybe<FeriaFooterButtonsCtaBlockInternalLinkConnection>;
  label?: Maybe<Scalars['String']['output']>;
};

export type FeriaFooterButtonsCtaBlockInternalLinkConnection = {
  __typename?: 'FeriaFooterButtonsCtaBlockInternalLinkConnection';
  edges?: Maybe<Array<Maybe<FeriaFooterButtonsCtaBlockInternalLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FeriaFooterButtonsCtaBlockInternalLinkEdge = {
  __typename?: 'FeriaFooterButtonsCtaBlockInternalLinkEdge';
  node?: Maybe<FeriaFooterButtonsCtaBlockInternalLinkNode>;
};

export type FeriaFooterButtonsCtaBlockInternalLinkNode = FeriaPage;

export type FeriaFooterButtonsCtaBlockInternalLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  feria_page?: InputMaybe<FeriaPageWhere>;
};

export type FeriaFooterButtonsCtaBlockWhere = {
  button_style?: InputMaybe<Scalars['String']['input']>;
  button_style_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_style_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_style_ne?: InputMaybe<Scalars['String']['input']>;
  button_style_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link_url?: InputMaybe<Scalars['String']['input']>;
  external_link_url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  external_link_url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link_url_ne?: InputMaybe<Scalars['String']['input']>;
  external_link_url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internal_link?: InputMaybe<FeriaFooterButtonsCtaBlockInternalLinkWhere>;
  internal_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FeriaFooterButtonsWhere = {
  cta?: InputMaybe<FeriaFooterButtonsCtaBlockWhere>;
  cta_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum FeriaFooterOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type FeriaFooterWhere = {
  AND?: InputMaybe<Array<InputMaybe<FeriaFooterWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<FeriaFooterWhere>>>;
  buttons?: InputMaybe<FeriaFooterButtonsWhere>;
  buttons_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  footer_copyright_info?: InputMaybe<Scalars['String']['input']>;
  footer_copyright_info_exists?: InputMaybe<Scalars['Boolean']['input']>;
  footer_copyright_info_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  footer_copyright_info_ne?: InputMaybe<Scalars['String']['input']>;
  footer_copyright_info_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo_field?: InputMaybe<ImageWhere>;
  logo_field_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_field_1?: InputMaybe<Scalars['String']['input']>;
  text_field_1_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_field_1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_field_1_ne?: InputMaybe<Scalars['String']['input']>;
  text_field_1_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_field_2?: InputMaybe<Scalars['String']['input']>;
  text_field_2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_field_2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_field_2_ne?: InputMaybe<Scalars['String']['input']>;
  text_field_2_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_field_3?: InputMaybe<Scalars['String']['input']>;
  text_field_3_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_field_3_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_field_3_ne?: InputMaybe<Scalars['String']['input']>;
  text_field_3_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type FeriaMainNavigation = {
  __typename?: 'FeriaMainNavigation';
  buttons?: Maybe<Array<Maybe<FeriaMainNavigationButtons>>>;
  logo?: Maybe<Image>;
  pagesConnection?: Maybe<FeriaMainNavigationPagesConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type FeriaMainNavigationPagesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FeriaMainNavigationButtons = FeriaMainNavigationButtonsCta | FeriaMainNavigationButtonsLanguagePicker;

export type FeriaMainNavigationButtonsCta = {
  __typename?: 'FeriaMainNavigationButtonsCta';
  cta?: Maybe<FeriaMainNavigationButtonsCtaBlock>;
};

export type FeriaMainNavigationButtonsCtaBlock = {
  __typename?: 'FeriaMainNavigationButtonsCtaBlock';
  button_style?: Maybe<Scalars['String']['output']>;
  external_link_url?: Maybe<Scalars['String']['output']>;
  internal_linkConnection?: Maybe<FeriaMainNavigationButtonsCtaBlockInternalLinkConnection>;
  label?: Maybe<Scalars['String']['output']>;
};

export type FeriaMainNavigationButtonsCtaBlockInternalLinkConnection = {
  __typename?: 'FeriaMainNavigationButtonsCtaBlockInternalLinkConnection';
  edges?: Maybe<Array<Maybe<FeriaMainNavigationButtonsCtaBlockInternalLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FeriaMainNavigationButtonsCtaBlockInternalLinkEdge = {
  __typename?: 'FeriaMainNavigationButtonsCtaBlockInternalLinkEdge';
  node?: Maybe<FeriaMainNavigationButtonsCtaBlockInternalLinkNode>;
};

export type FeriaMainNavigationButtonsCtaBlockInternalLinkNode = FeriaPage;

export type FeriaMainNavigationButtonsCtaBlockInternalLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  feria_page?: InputMaybe<FeriaPageWhere>;
};

export type FeriaMainNavigationButtonsCtaBlockWhere = {
  button_style?: InputMaybe<Scalars['String']['input']>;
  button_style_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_style_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_style_ne?: InputMaybe<Scalars['String']['input']>;
  button_style_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link_url?: InputMaybe<Scalars['String']['input']>;
  external_link_url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  external_link_url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link_url_ne?: InputMaybe<Scalars['String']['input']>;
  external_link_url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internal_link?: InputMaybe<FeriaMainNavigationButtonsCtaBlockInternalLinkWhere>;
  internal_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FeriaMainNavigationButtonsLanguagePicker = {
  __typename?: 'FeriaMainNavigationButtonsLanguagePicker';
  language_picker?: Maybe<FeriaMainNavigationButtonsLanguagePickerBlock>;
};

export type FeriaMainNavigationButtonsLanguagePickerBlock = {
  __typename?: 'FeriaMainNavigationButtonsLanguagePickerBlock';
  languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type FeriaMainNavigationButtonsLanguagePickerBlockWhere = {
  languages?: InputMaybe<Scalars['String']['input']>;
  languages_exists?: InputMaybe<Scalars['Boolean']['input']>;
  languages_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  languages_ne?: InputMaybe<Scalars['String']['input']>;
  languages_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FeriaMainNavigationButtonsWhere = {
  cta?: InputMaybe<FeriaMainNavigationButtonsCtaBlockWhere>;
  cta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  language_picker?: InputMaybe<FeriaMainNavigationButtonsLanguagePickerBlockWhere>;
  language_picker_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum FeriaMainNavigationOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type FeriaMainNavigationPagesConnection = {
  __typename?: 'FeriaMainNavigationPagesConnection';
  edges?: Maybe<Array<Maybe<FeriaMainNavigationPagesEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FeriaMainNavigationPagesEdge = {
  __typename?: 'FeriaMainNavigationPagesEdge';
  node?: Maybe<FeriaMainNavigationPagesNode>;
};

export type FeriaMainNavigationPagesNode = FeriaPage;

export type FeriaMainNavigationPagesWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  feria_page?: InputMaybe<FeriaPageWhere>;
};

export type FeriaMainNavigationWhere = {
  AND?: InputMaybe<Array<InputMaybe<FeriaMainNavigationWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<FeriaMainNavigationWhere>>>;
  buttons?: InputMaybe<FeriaMainNavigationButtonsWhere>;
  buttons_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo?: InputMaybe<ImageWhere>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pages?: InputMaybe<FeriaMainNavigationPagesWhere>;
  pages_count?: InputMaybe<Scalars['Int']['input']>;
  pages_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type FeriaPage = {
  __typename?: 'FeriaPage';
  contentConnection?: Maybe<FeriaPageContentConnection>;
  hero_banner_image?: Maybe<Image>;
  seo?: Maybe<Seo>;
  short_description?: Maybe<Scalars['String']['output']>;
  subtext?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type FeriaPageContentConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type FeriaPageContentConnection = {
  __typename?: 'FeriaPageContentConnection';
  edges?: Maybe<Array<Maybe<FeriaPageContentEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FeriaPageContentEdge = {
  __typename?: 'FeriaPageContentEdge';
  node?: Maybe<FeriaPageContentNode>;
};

export type FeriaPageContentNode = FeriaContentSection | FeriaPage | FeriaTextImageSection;

export type FeriaPageContentWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  feria_content_section?: InputMaybe<FeriaContentSectionWhere>;
  feria_page?: InputMaybe<FeriaPageWhere>;
  feria_text_image_section?: InputMaybe<FeriaTextImageSectionWhere>;
};

export enum FeriaPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type FeriaPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<FeriaPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<FeriaPageWhere>>>;
  content?: InputMaybe<FeriaPageContentWhere>;
  content_count?: InputMaybe<Scalars['Int']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hero_banner_image?: InputMaybe<ImageWhere>;
  hero_banner_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  seo?: InputMaybe<SeoWhere>;
  seo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  short_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  short_description_ne?: InputMaybe<Scalars['String']['input']>;
  short_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtext?: InputMaybe<Scalars['String']['input']>;
  subtext_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtext_ne?: InputMaybe<Scalars['String']['input']>;
  subtext_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type FeriaTextImageSection = {
  __typename?: 'FeriaTextImageSection';
  body_text?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  layout?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum FeriaTextImageSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type FeriaTextImageSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<FeriaTextImageSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<FeriaTextImageSectionWhere>>>;
  body_text?: InputMaybe<Scalars['String']['input']>;
  body_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  body_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  body_text_ne?: InputMaybe<Scalars['String']['input']>;
  body_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<ImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  layout_exists?: InputMaybe<Scalars['Boolean']['input']>;
  layout_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  layout_ne?: InputMaybe<Scalars['String']['input']>;
  layout_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type FeriaTranslationGroup = {
  __typename?: 'FeriaTranslationGroup';
  code_reference?: Maybe<Scalars['String']['output']>;
  strings?: Maybe<Array<Maybe<FeriaTranslationGroupStrings>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum FeriaTranslationGroupOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type FeriaTranslationGroupStrings = FeriaTranslationGroupStringsTranslation;

export type FeriaTranslationGroupStringsTranslation = {
  __typename?: 'FeriaTranslationGroupStringsTranslation';
  translation?: Maybe<FeriaTranslationGroupStringsTranslationBlock>;
};

export type FeriaTranslationGroupStringsTranslationBlock = {
  __typename?: 'FeriaTranslationGroupStringsTranslationBlock';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type FeriaTranslationGroupStringsTranslationBlockWhere = {
  key?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_ne?: InputMaybe<Scalars['String']['input']>;
  key_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_ne?: InputMaybe<Scalars['String']['input']>;
  value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type FeriaTranslationGroupStringsWhere = {
  translation?: InputMaybe<FeriaTranslationGroupStringsTranslationBlockWhere>;
  translation_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FeriaTranslationGroupWhere = {
  AND?: InputMaybe<Array<InputMaybe<FeriaTranslationGroupWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<FeriaTranslationGroupWhere>>>;
  code_reference?: InputMaybe<Scalars['String']['input']>;
  code_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code_reference_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_reference_ne?: InputMaybe<Scalars['String']['input']>;
  code_reference_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  strings?: InputMaybe<FeriaTranslationGroupStringsWhere>;
  strings_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupAnnualreport = {
  __typename?: 'GroupAnnualreport';
  cover_imageConnection?: Maybe<SysAssetConnection>;
  link?: Maybe<Scalars['String']['output']>;
  pdfConnection?: Maybe<SysAssetConnection>;
  report_type?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupAnnualreportOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupAnnualreportWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupAnnualreportWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupAnnualreportWhere>>>;
  cover_image?: InputMaybe<SysAssetWhere>;
  cover_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link?: InputMaybe<Scalars['String']['input']>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_ne?: InputMaybe<Scalars['String']['input']>;
  link_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pdf?: InputMaybe<SysAssetWhere>;
  pdf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  report_type?: InputMaybe<Scalars['String']['input']>;
  report_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  report_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  report_type_ne?: InputMaybe<Scalars['String']['input']>;
  report_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupCalendarentries = {
  __typename?: 'GroupCalendarentries';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum GroupCalendarentriesOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupCalendarentriesWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupCalendarentriesWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupCalendarentriesWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupFinancialcalendar = {
  __typename?: 'GroupFinancialcalendar';
  date?: Maybe<Scalars['DateTime']['output']>;
  display_as_week?: Maybe<Scalars['Boolean']['output']>;
  display_title?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupFinancialcalendarOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupFinancialcalendarWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupFinancialcalendarWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupFinancialcalendarWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  date_exists?: InputMaybe<Scalars['Boolean']['input']>;
  date_gt?: InputMaybe<Scalars['DateTime']['input']>;
  date_gte?: InputMaybe<Scalars['DateTime']['input']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']['input']>;
  date_lte?: InputMaybe<Scalars['DateTime']['input']>;
  date_ne?: InputMaybe<Scalars['DateTime']['input']>;
  date_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  display_as_week?: InputMaybe<Scalars['Boolean']['input']>;
  display_as_week_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_as_week_ne?: InputMaybe<Scalars['Boolean']['input']>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_ne?: InputMaybe<Scalars['String']['input']>;
  subtitle_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupFooter = {
  __typename?: 'GroupFooter';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupFooterOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupFooterWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupFooterWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupFooterWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupFrontpage = {
  __typename?: 'GroupFrontpage';
  content_sectionsConnection?: Maybe<GroupFrontpageContentSectionsConnection>;
  hero_banner_content?: Maybe<GroupFrontpageHeroBannerContent>;
  hero_banner_image?: Maybe<GroupImage>;
  metadata?: Maybe<GroupMetadata>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type GroupFrontpageContent_SectionsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupFrontpageContentSectionsConnection = {
  __typename?: 'GroupFrontpageContentSectionsConnection';
  edges?: Maybe<Array<Maybe<GroupFrontpageContentSectionsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupFrontpageContentSectionsEdge = {
  __typename?: 'GroupFrontpageContentSectionsEdge';
  node?: Maybe<GroupFrontpageContentSectionsNode>;
};

export type GroupFrontpageContentSectionsNode = GroupGenericContent;

export type GroupFrontpageContentSectionsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_generic_content?: InputMaybe<GroupGenericContentWhere>;
};

export type GroupFrontpageHeroBannerContent = {
  __typename?: 'GroupFrontpageHeroBannerContent';
  button_text?: Maybe<Scalars['String']['output']>;
  external_link?: Maybe<Scalars['String']['output']>;
  referenceConnection?: Maybe<GroupFrontpageHeroBannerContentReferenceConnection>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupFrontpageHeroBannerContentReferenceConnection = {
  __typename?: 'GroupFrontpageHeroBannerContentReferenceConnection';
  edges?: Maybe<Array<Maybe<GroupFrontpageHeroBannerContentReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupFrontpageHeroBannerContentReferenceEdge = {
  __typename?: 'GroupFrontpageHeroBannerContentReferenceEdge';
  node?: Maybe<GroupFrontpageHeroBannerContentReferenceNode>;
};

export type GroupFrontpageHeroBannerContentReferenceNode = GroupPage;

export type GroupFrontpageHeroBannerContentReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_page?: InputMaybe<GroupPageWhere>;
};

export type GroupFrontpageHeroBannerContentWhere = {
  button_text?: InputMaybe<Scalars['String']['input']>;
  button_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_text_ne?: InputMaybe<Scalars['String']['input']>;
  button_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link?: InputMaybe<Scalars['String']['input']>;
  external_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  external_link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link_ne?: InputMaybe<Scalars['String']['input']>;
  external_link_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reference?: InputMaybe<GroupFrontpageHeroBannerContentReferenceWhere>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum GroupFrontpageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupFrontpageWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupFrontpageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupFrontpageWhere>>>;
  content_sections?: InputMaybe<GroupFrontpageContentSectionsWhere>;
  content_sections_count?: InputMaybe<Scalars['Int']['input']>;
  content_sections_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hero_banner_content?: InputMaybe<GroupFrontpageHeroBannerContentWhere>;
  hero_banner_content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hero_banner_image?: InputMaybe<GroupImageWhere>;
  hero_banner_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<GroupMetadataWhere>;
  metadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupGenericContent = {
  __typename?: 'GroupGenericContent';
  anchor_menu_title?: Maybe<Scalars['String']['output']>;
  background_color_templates?: Maybe<Scalars['String']['output']>;
  collapsiblesection?: Maybe<GroupGenericContentCollapsiblesection>;
  display_title?: Maybe<Scalars['String']['output']>;
  filesfollowingtext?: Maybe<Array<Maybe<GroupGenericContentFilesfollowingtext>>>;
  formstack_form_url_parameter?: Maybe<Scalars['String']['output']>;
  full_width?: Maybe<Scalars['String']['output']>;
  icons_header?: Maybe<Scalars['String']['output']>;
  iframe_componentConnection?: Maybe<GroupGenericContentIframeComponentConnection>;
  image?: Maybe<GroupGenericContentImage>;
  include_in_anchor_menu?: Maybe<Scalars['Boolean']['output']>;
  keldan?: Maybe<Scalars['String']['output']>;
  key_figures?: Maybe<GroupGenericContentKeyFigures>;
  list_of_annual_reportsConnection?: Maybe<GroupGenericContentListOfAnnualReportsConnection>;
  list_of_financial_calendar_itemsConnection?: Maybe<GroupGenericContentListOfFinancialCalendarItemsConnection>;
  list_of_iconsConnection?: Maybe<GroupGenericContentListOfIconsConnection>;
  list_of_quarterly_resultsConnection?: Maybe<GroupGenericContentListOfQuarterlyResultsConnection>;
  list_of_tab_content?: Maybe<Array<Maybe<GroupGenericContentListOfTabContent>>>;
  referenceConnection?: Maybe<GroupGenericContentReferenceConnection>;
  ribbon?: Maybe<GroupGenericContentRibbon>;
  system?: Maybe<EntrySystemField>;
  text_video?: Maybe<GroupGenericContentTextVideo>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};


export type GroupGenericContentList_Of_Annual_ReportsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type GroupGenericContentList_Of_Financial_Calendar_ItemsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type GroupGenericContentList_Of_IconsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type GroupGenericContentList_Of_Quarterly_ResultsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type GroupGenericContentReferenceConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupGenericContentCollapsiblesection = {
  __typename?: 'GroupGenericContentCollapsiblesection';
  entries?: Maybe<Array<Maybe<GroupGenericContentCollapsiblesectionEntries>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupGenericContentCollapsiblesectionEntries = {
  __typename?: 'GroupGenericContentCollapsiblesectionEntries';
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupGenericContentCollapsiblesectionEntriesWhere = {
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupGenericContentCollapsiblesectionWhere = {
  entries?: InputMaybe<GroupGenericContentCollapsiblesectionEntriesWhere>;
  entries_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_ne?: InputMaybe<Scalars['String']['input']>;
  subtitle_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupGenericContentFilesfollowingtext = {
  __typename?: 'GroupGenericContentFilesfollowingtext';
  description?: Maybe<Scalars['String']['output']>;
  pdfConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupGenericContentFilesfollowingtextWhere = {
  description?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_ne?: InputMaybe<Scalars['String']['input']>;
  description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pdf?: InputMaybe<SysAssetWhere>;
  pdf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupGenericContentIframeComponentConnection = {
  __typename?: 'GroupGenericContentIframeComponentConnection';
  edges?: Maybe<Array<Maybe<GroupGenericContentIframeComponentEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupGenericContentIframeComponentEdge = {
  __typename?: 'GroupGenericContentIframeComponentEdge';
  node?: Maybe<GroupGenericContentIframeComponentNode>;
};

export type GroupGenericContentIframeComponentNode = GroupIframe;

export type GroupGenericContentIframeComponentWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_iframe?: InputMaybe<GroupIframeWhere>;
};

export type GroupGenericContentImage = {
  __typename?: 'GroupGenericContentImage';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  image_alignment?: Maybe<Scalars['String']['output']>;
  image_crop?: Maybe<Scalars['String']['output']>;
  mobile_image?: Maybe<GroupGenericContentImageMobileImage>;
};

export type GroupGenericContentImageMobileImage = {
  __typename?: 'GroupGenericContentImageMobileImage';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
};

export type GroupGenericContentImageMobileImageWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupGenericContentImageWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_alignment?: InputMaybe<Scalars['String']['input']>;
  image_alignment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_alignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_alignment_ne?: InputMaybe<Scalars['String']['input']>;
  image_alignment_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_image?: InputMaybe<GroupGenericContentImageMobileImageWhere>;
  mobile_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupGenericContentKeyFigures = {
  __typename?: 'GroupGenericContentKeyFigures';
  cash_and_mark_securities?: Maybe<Scalars['String']['output']>;
  equity_ratio?: Maybe<Scalars['String']['output']>;
  financial_liabilities?: Maybe<Scalars['String']['output']>;
  market_cap?: Maybe<Scalars['String']['output']>;
  total_assets?: Maybe<Scalars['String']['output']>;
  total_income?: Maybe<Scalars['String']['output']>;
};

export type GroupGenericContentKeyFiguresWhere = {
  cash_and_mark_securities?: InputMaybe<Scalars['String']['input']>;
  cash_and_mark_securities_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cash_and_mark_securities_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cash_and_mark_securities_ne?: InputMaybe<Scalars['String']['input']>;
  cash_and_mark_securities_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  equity_ratio?: InputMaybe<Scalars['String']['input']>;
  equity_ratio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  equity_ratio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  equity_ratio_ne?: InputMaybe<Scalars['String']['input']>;
  equity_ratio_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  financial_liabilities?: InputMaybe<Scalars['String']['input']>;
  financial_liabilities_exists?: InputMaybe<Scalars['Boolean']['input']>;
  financial_liabilities_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  financial_liabilities_ne?: InputMaybe<Scalars['String']['input']>;
  financial_liabilities_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  market_cap?: InputMaybe<Scalars['String']['input']>;
  market_cap_exists?: InputMaybe<Scalars['Boolean']['input']>;
  market_cap_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  market_cap_ne?: InputMaybe<Scalars['String']['input']>;
  market_cap_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  total_assets?: InputMaybe<Scalars['String']['input']>;
  total_assets_exists?: InputMaybe<Scalars['Boolean']['input']>;
  total_assets_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  total_assets_ne?: InputMaybe<Scalars['String']['input']>;
  total_assets_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  total_income?: InputMaybe<Scalars['String']['input']>;
  total_income_exists?: InputMaybe<Scalars['Boolean']['input']>;
  total_income_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  total_income_ne?: InputMaybe<Scalars['String']['input']>;
  total_income_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupGenericContentListOfAnnualReportsConnection = {
  __typename?: 'GroupGenericContentListOfAnnualReportsConnection';
  edges?: Maybe<Array<Maybe<GroupGenericContentListOfAnnualReportsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupGenericContentListOfAnnualReportsEdge = {
  __typename?: 'GroupGenericContentListOfAnnualReportsEdge';
  node?: Maybe<GroupGenericContentListOfAnnualReportsNode>;
};

export type GroupGenericContentListOfAnnualReportsNode = GroupAnnualreport;

export type GroupGenericContentListOfAnnualReportsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_annualreport?: InputMaybe<GroupAnnualreportWhere>;
};

export type GroupGenericContentListOfFinancialCalendarItemsConnection = {
  __typename?: 'GroupGenericContentListOfFinancialCalendarItemsConnection';
  edges?: Maybe<Array<Maybe<GroupGenericContentListOfFinancialCalendarItemsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupGenericContentListOfFinancialCalendarItemsEdge = {
  __typename?: 'GroupGenericContentListOfFinancialCalendarItemsEdge';
  node?: Maybe<GroupGenericContentListOfFinancialCalendarItemsNode>;
};

export type GroupGenericContentListOfFinancialCalendarItemsNode = GroupFinancialcalendar;

export type GroupGenericContentListOfFinancialCalendarItemsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_financialcalendar?: InputMaybe<GroupFinancialcalendarWhere>;
};

export type GroupGenericContentListOfIconsConnection = {
  __typename?: 'GroupGenericContentListOfIconsConnection';
  edges?: Maybe<Array<Maybe<GroupGenericContentListOfIconsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupGenericContentListOfIconsEdge = {
  __typename?: 'GroupGenericContentListOfIconsEdge';
  node?: Maybe<GroupGenericContentListOfIconsNode>;
};

export type GroupGenericContentListOfIconsNode = GroupIcons;

export type GroupGenericContentListOfIconsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_icons?: InputMaybe<GroupIconsWhere>;
};

export type GroupGenericContentListOfQuarterlyResultsConnection = {
  __typename?: 'GroupGenericContentListOfQuarterlyResultsConnection';
  edges?: Maybe<Array<Maybe<GroupGenericContentListOfQuarterlyResultsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupGenericContentListOfQuarterlyResultsEdge = {
  __typename?: 'GroupGenericContentListOfQuarterlyResultsEdge';
  node?: Maybe<GroupGenericContentListOfQuarterlyResultsNode>;
};

export type GroupGenericContentListOfQuarterlyResultsNode = GroupQuarterlyresults;

export type GroupGenericContentListOfQuarterlyResultsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_quarterlyresults?: InputMaybe<GroupQuarterlyresultsWhere>;
};

export type GroupGenericContentListOfTabContent = {
  __typename?: 'GroupGenericContentListOfTabContent';
  data_from_keldan?: Maybe<Scalars['String']['output']>;
  display_title?: Maybe<Scalars['String']['output']>;
  tab_content_blocksConnection?: Maybe<GroupGenericContentListOfTabContentTabContentBlocksConnection>;
};


export type GroupGenericContentListOfTabContentTab_Content_BlocksConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupGenericContentListOfTabContentTabContentBlocksConnection = {
  __typename?: 'GroupGenericContentListOfTabContentTabContentBlocksConnection';
  edges?: Maybe<Array<Maybe<GroupGenericContentListOfTabContentTabContentBlocksEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupGenericContentListOfTabContentTabContentBlocksEdge = {
  __typename?: 'GroupGenericContentListOfTabContentTabContentBlocksEdge';
  node?: Maybe<GroupGenericContentListOfTabContentTabContentBlocksNode>;
};

export type GroupGenericContentListOfTabContentTabContentBlocksNode = GroupTabcontent;

export type GroupGenericContentListOfTabContentTabContentBlocksWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_tabcontent?: InputMaybe<GroupTabcontentWhere>;
};

export type GroupGenericContentListOfTabContentWhere = {
  data_from_keldan?: InputMaybe<Scalars['String']['input']>;
  data_from_keldan_exists?: InputMaybe<Scalars['Boolean']['input']>;
  data_from_keldan_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  data_from_keldan_ne?: InputMaybe<Scalars['String']['input']>;
  data_from_keldan_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tab_content_blocks?: InputMaybe<GroupGenericContentListOfTabContentTabContentBlocksWhere>;
  tab_content_blocks_count?: InputMaybe<Scalars['Int']['input']>;
  tab_content_blocks_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupGenericContentOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupGenericContentReferenceConnection = {
  __typename?: 'GroupGenericContentReferenceConnection';
  edges?: Maybe<Array<Maybe<GroupGenericContentReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupGenericContentReferenceEdge = {
  __typename?: 'GroupGenericContentReferenceEdge';
  node?: Maybe<GroupGenericContentReferenceNode>;
};

export type GroupGenericContentReferenceNode = GroupManagement;

export type GroupGenericContentReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_management?: InputMaybe<GroupManagementWhere>;
};

export type GroupGenericContentRibbon = {
  __typename?: 'GroupGenericContentRibbon';
  ribbon_color?: Maybe<Scalars['String']['output']>;
  ribbon_text?: Maybe<Scalars['String']['output']>;
  ribbon_text_alignment?: Maybe<Scalars['String']['output']>;
  ribbon_text_underline?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupGenericContentRibbonWhere = {
  ribbon_color?: InputMaybe<Scalars['String']['input']>;
  ribbon_color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ribbon_color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ribbon_color_ne?: InputMaybe<Scalars['String']['input']>;
  ribbon_color_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ribbon_text?: InputMaybe<Scalars['String']['input']>;
  ribbon_text_alignment?: InputMaybe<Scalars['String']['input']>;
  ribbon_text_alignment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ribbon_text_alignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ribbon_text_alignment_ne?: InputMaybe<Scalars['String']['input']>;
  ribbon_text_alignment_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ribbon_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ribbon_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ribbon_text_ne?: InputMaybe<Scalars['String']['input']>;
  ribbon_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ribbon_text_underline?: InputMaybe<Scalars['Boolean']['input']>;
  ribbon_text_underline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ribbon_text_underline_ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupGenericContentTextVideo = {
  __typename?: 'GroupGenericContentTextVideo';
  hide_title_from_webpage?: Maybe<Scalars['Boolean']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  title_color?: Maybe<Scalars['String']['output']>;
  underline_for_title?: Maybe<Scalars['Boolean']['output']>;
  video_id?: Maybe<Scalars['String']['output']>;
};

export type GroupGenericContentTextVideoWhere = {
  hide_title_from_webpage?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_ne?: InputMaybe<Scalars['Boolean']['input']>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_color?: InputMaybe<Scalars['String']['input']>;
  title_color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_color_ne?: InputMaybe<Scalars['String']['input']>;
  title_color_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  underline_for_title?: InputMaybe<Scalars['Boolean']['input']>;
  underline_for_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  underline_for_title_ne?: InputMaybe<Scalars['Boolean']['input']>;
  video_id?: InputMaybe<Scalars['String']['input']>;
  video_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_id_ne?: InputMaybe<Scalars['String']['input']>;
  video_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupGenericContentWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupGenericContentWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupGenericContentWhere>>>;
  anchor_menu_title?: InputMaybe<Scalars['String']['input']>;
  anchor_menu_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  anchor_menu_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  anchor_menu_title_ne?: InputMaybe<Scalars['String']['input']>;
  anchor_menu_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_color_templates?: InputMaybe<Scalars['String']['input']>;
  background_color_templates_exists?: InputMaybe<Scalars['Boolean']['input']>;
  background_color_templates_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_color_templates_ne?: InputMaybe<Scalars['String']['input']>;
  background_color_templates_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  collapsiblesection?: InputMaybe<GroupGenericContentCollapsiblesectionWhere>;
  collapsiblesection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filesfollowingtext?: InputMaybe<GroupGenericContentFilesfollowingtextWhere>;
  filesfollowingtext_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formstack_form_url_parameter?: InputMaybe<Scalars['String']['input']>;
  formstack_form_url_parameter_exists?: InputMaybe<Scalars['Boolean']['input']>;
  formstack_form_url_parameter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  formstack_form_url_parameter_ne?: InputMaybe<Scalars['String']['input']>;
  formstack_form_url_parameter_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  full_width?: InputMaybe<Scalars['String']['input']>;
  full_width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  full_width_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  full_width_ne?: InputMaybe<Scalars['String']['input']>;
  full_width_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  icons_header?: InputMaybe<Scalars['String']['input']>;
  icons_header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  icons_header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  icons_header_ne?: InputMaybe<Scalars['String']['input']>;
  icons_header_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iframe_component?: InputMaybe<GroupGenericContentIframeComponentWhere>;
  iframe_component_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<GroupGenericContentImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  include_in_anchor_menu?: InputMaybe<Scalars['Boolean']['input']>;
  include_in_anchor_menu_exists?: InputMaybe<Scalars['Boolean']['input']>;
  include_in_anchor_menu_ne?: InputMaybe<Scalars['Boolean']['input']>;
  keldan?: InputMaybe<Scalars['String']['input']>;
  keldan_exists?: InputMaybe<Scalars['Boolean']['input']>;
  keldan_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keldan_ne?: InputMaybe<Scalars['String']['input']>;
  keldan_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_figures?: InputMaybe<GroupGenericContentKeyFiguresWhere>;
  key_figures_exists?: InputMaybe<Scalars['Boolean']['input']>;
  list_of_annual_reports?: InputMaybe<GroupGenericContentListOfAnnualReportsWhere>;
  list_of_annual_reports_count?: InputMaybe<Scalars['Int']['input']>;
  list_of_annual_reports_exists?: InputMaybe<Scalars['Boolean']['input']>;
  list_of_financial_calendar_items?: InputMaybe<GroupGenericContentListOfFinancialCalendarItemsWhere>;
  list_of_financial_calendar_items_count?: InputMaybe<Scalars['Int']['input']>;
  list_of_financial_calendar_items_exists?: InputMaybe<Scalars['Boolean']['input']>;
  list_of_icons?: InputMaybe<GroupGenericContentListOfIconsWhere>;
  list_of_icons_count?: InputMaybe<Scalars['Int']['input']>;
  list_of_icons_exists?: InputMaybe<Scalars['Boolean']['input']>;
  list_of_quarterly_results?: InputMaybe<GroupGenericContentListOfQuarterlyResultsWhere>;
  list_of_quarterly_results_count?: InputMaybe<Scalars['Int']['input']>;
  list_of_quarterly_results_exists?: InputMaybe<Scalars['Boolean']['input']>;
  list_of_tab_content?: InputMaybe<GroupGenericContentListOfTabContentWhere>;
  list_of_tab_content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  reference?: InputMaybe<GroupGenericContentReferenceWhere>;
  reference_count?: InputMaybe<Scalars['Int']['input']>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  ribbon?: InputMaybe<GroupGenericContentRibbonWhere>;
  ribbon_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_video?: InputMaybe<GroupGenericContentTextVideoWhere>;
  text_video_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupIcons = {
  __typename?: 'GroupIcons';
  imageConnection?: Maybe<SysAssetConnection>;
  link?: Maybe<Link>;
  respect_size?: Maybe<Scalars['Boolean']['output']>;
  system?: Maybe<EntrySystemField>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupIconsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupIconsWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupIconsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupIconsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<LinkWhere>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  respect_size?: InputMaybe<Scalars['Boolean']['input']>;
  respect_size_exists?: InputMaybe<Scalars['Boolean']['input']>;
  respect_size_ne?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupIframe = {
  __typename?: 'GroupIframe';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url_for_iframe?: Maybe<Scalars['String']['output']>;
};

export enum GroupIframeOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupIframeWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupIframeWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupIframeWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_for_iframe?: InputMaybe<Scalars['String']['input']>;
  url_for_iframe_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_for_iframe_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_for_iframe_ne?: InputMaybe<Scalars['String']['input']>;
  url_for_iframe_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupImage = {
  __typename?: 'GroupImage';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
};

export type GroupImageWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupLogos = {
  __typename?: 'GroupLogos';
  logoConnection?: Maybe<SysAssetConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupLogosOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupLogosWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupLogosWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupLogosWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo?: InputMaybe<SysAssetWhere>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupManagement = {
  __typename?: 'GroupManagement';
  bio?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  job_title?: Maybe<Scalars['String']['output']>;
  membership?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupManagementOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupManagementWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupManagementWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupManagementWhere>>>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bio_ne?: InputMaybe<Scalars['String']['input']>;
  bio_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  job_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  job_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  job_title_ne?: InputMaybe<Scalars['String']['input']>;
  job_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  membership?: InputMaybe<Scalars['String']['input']>;
  membership_exists?: InputMaybe<Scalars['Boolean']['input']>;
  membership_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  membership_ne?: InputMaybe<Scalars['String']['input']>;
  membership_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupMenu = {
  __typename?: 'GroupMenu';
  navigation_menuConnection?: Maybe<GroupMenuNavigationMenuConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type GroupMenuNavigation_MenuConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupMenuNavigationMenuConnection = {
  __typename?: 'GroupMenuNavigationMenuConnection';
  edges?: Maybe<Array<Maybe<GroupMenuNavigationMenuEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupMenuNavigationMenuEdge = {
  __typename?: 'GroupMenuNavigationMenuEdge';
  node?: Maybe<GroupMenuNavigationMenuNode>;
};

export type GroupMenuNavigationMenuNode = GroupNewsroom | GroupPage;

export type GroupMenuNavigationMenuWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_newsroom?: InputMaybe<GroupNewsroomWhere>;
  group_page?: InputMaybe<GroupPageWhere>;
};

export enum GroupMenuOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupMenuWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupMenuWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupMenuWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigation_menu?: InputMaybe<GroupMenuNavigationMenuWhere>;
  navigation_menu_count?: InputMaybe<Scalars['Int']['input']>;
  navigation_menu_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupMetadata = {
  __typename?: 'GroupMetadata';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type GroupMetadataWhere = {
  meta_description?: InputMaybe<Scalars['String']['input']>;
  meta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_description_ne?: InputMaybe<Scalars['String']['input']>;
  meta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title?: InputMaybe<Scalars['String']['input']>;
  meta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title_ne?: InputMaybe<Scalars['String']['input']>;
  meta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupNews = {
  __typename?: 'GroupNews';
  author?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  content_blocks?: Maybe<Array<Maybe<GroupNewsContentBlocks>>>;
  image?: Maybe<GroupNewsImage>;
  metadata?: Maybe<GroupMetadata>;
  publish_date?: Maybe<Scalars['DateTime']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type GroupNewsContentBlocks = {
  __typename?: 'GroupNewsContentBlocks';
  display_as_collapsible?: Maybe<Scalars['Boolean']['output']>;
  hide_title_from_webpage?: Maybe<Scalars['Boolean']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  video_id?: Maybe<Scalars['String']['output']>;
};

export type GroupNewsContentBlocksWhere = {
  display_as_collapsible?: InputMaybe<Scalars['Boolean']['input']>;
  display_as_collapsible_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_as_collapsible_ne?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_ne?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_id?: InputMaybe<Scalars['String']['input']>;
  video_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_id_ne?: InputMaybe<Scalars['String']['input']>;
  video_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupNewsImage = {
  __typename?: 'GroupNewsImage';
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
  mobile_image?: Maybe<GroupNewsImageMobileImage>;
};

export type GroupNewsImageMobileImage = {
  __typename?: 'GroupNewsImageMobileImage';
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
};

export type GroupNewsImageMobileImageWhere = {
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupNewsImageWhere = {
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_image?: InputMaybe<GroupNewsImageMobileImageWhere>;
  mobile_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupNewsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupNewsWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupNewsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupNewsWhere>>>;
  author?: InputMaybe<Scalars['String']['input']>;
  author_exists?: InputMaybe<Scalars['Boolean']['input']>;
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  author_ne?: InputMaybe<Scalars['String']['input']>;
  author_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  category_exists?: InputMaybe<Scalars['Boolean']['input']>;
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category_ne?: InputMaybe<Scalars['String']['input']>;
  category_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_blocks?: InputMaybe<GroupNewsContentBlocksWhere>;
  content_blocks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<GroupNewsImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<GroupMetadataWhere>;
  metadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_date?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_date_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publish_date_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_ne?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  short_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  short_description_ne?: InputMaybe<Scalars['String']['input']>;
  short_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupNewsroom = {
  __typename?: 'GroupNewsroom';
  hero_banner?: Maybe<Scalars['String']['output']>;
  hero_banner_content?: Maybe<GroupNewsroomHeroBannerContent>;
  hero_banner_image?: Maybe<GroupNewsroomHeroBannerImage>;
  metadata?: Maybe<GroupMetadata>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type GroupNewsroomHeroBannerContent = {
  __typename?: 'GroupNewsroomHeroBannerContent';
  button_text?: Maybe<Scalars['String']['output']>;
  referenceConnection?: Maybe<GroupNewsroomHeroBannerContentReferenceConnection>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupNewsroomHeroBannerContentReferenceConnection = {
  __typename?: 'GroupNewsroomHeroBannerContentReferenceConnection';
  edges?: Maybe<Array<Maybe<GroupNewsroomHeroBannerContentReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupNewsroomHeroBannerContentReferenceEdge = {
  __typename?: 'GroupNewsroomHeroBannerContentReferenceEdge';
  node?: Maybe<GroupNewsroomHeroBannerContentReferenceNode>;
};

export type GroupNewsroomHeroBannerContentReferenceNode = GroupNews;

export type GroupNewsroomHeroBannerContentReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_news?: InputMaybe<GroupNewsWhere>;
};

export type GroupNewsroomHeroBannerContentWhere = {
  button_text?: InputMaybe<Scalars['String']['input']>;
  button_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_text_ne?: InputMaybe<Scalars['String']['input']>;
  button_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reference?: InputMaybe<GroupNewsroomHeroBannerContentReferenceWhere>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupNewsroomHeroBannerImage = {
  __typename?: 'GroupNewsroomHeroBannerImage';
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
  mobile_image?: Maybe<GroupNewsroomHeroBannerImageMobileImage>;
  use_image_from_the_referenced_news_item?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupNewsroomHeroBannerImageMobileImage = {
  __typename?: 'GroupNewsroomHeroBannerImageMobileImage';
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
};

export type GroupNewsroomHeroBannerImageMobileImageWhere = {
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupNewsroomHeroBannerImageWhere = {
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_image?: InputMaybe<GroupNewsroomHeroBannerImageMobileImageWhere>;
  mobile_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  use_image_from_the_referenced_news_item?: InputMaybe<Scalars['Boolean']['input']>;
  use_image_from_the_referenced_news_item_exists?: InputMaybe<Scalars['Boolean']['input']>;
  use_image_from_the_referenced_news_item_ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupNewsroomOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupNewsroomWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupNewsroomWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupNewsroomWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hero_banner?: InputMaybe<Scalars['String']['input']>;
  hero_banner_content?: InputMaybe<GroupNewsroomHeroBannerContentWhere>;
  hero_banner_content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hero_banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hero_banner_image?: InputMaybe<GroupNewsroomHeroBannerImageWhere>;
  hero_banner_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hero_banner_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hero_banner_ne?: InputMaybe<Scalars['String']['input']>;
  hero_banner_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<GroupMetadataWhere>;
  metadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupPage = {
  __typename?: 'GroupPage';
  content_sectionsConnection?: Maybe<GroupPageContentSectionsConnection>;
  hero_banner_content?: Maybe<GroupPageHeroBannerContent>;
  image?: Maybe<GroupImage>;
  metadata?: Maybe<GroupMetadata>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type GroupPageContent_SectionsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupPageContentSectionsConnection = {
  __typename?: 'GroupPageContentSectionsConnection';
  edges?: Maybe<Array<Maybe<GroupPageContentSectionsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupPageContentSectionsEdge = {
  __typename?: 'GroupPageContentSectionsEdge';
  node?: Maybe<GroupPageContentSectionsNode>;
};

export type GroupPageContentSectionsNode = GroupGenericContent;

export type GroupPageContentSectionsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_generic_content?: InputMaybe<GroupGenericContentWhere>;
};

export type GroupPageHeroBannerContent = {
  __typename?: 'GroupPageHeroBannerContent';
  button_text?: Maybe<Scalars['String']['output']>;
  external_link?: Maybe<Scalars['String']['output']>;
  referenceConnection?: Maybe<GroupPageHeroBannerContentReferenceConnection>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupPageHeroBannerContentReferenceConnection = {
  __typename?: 'GroupPageHeroBannerContentReferenceConnection';
  edges?: Maybe<Array<Maybe<GroupPageHeroBannerContentReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupPageHeroBannerContentReferenceEdge = {
  __typename?: 'GroupPageHeroBannerContentReferenceEdge';
  node?: Maybe<GroupPageHeroBannerContentReferenceNode>;
};

export type GroupPageHeroBannerContentReferenceNode = GroupPage;

export type GroupPageHeroBannerContentReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_page?: InputMaybe<GroupPageWhere>;
};

export type GroupPageHeroBannerContentWhere = {
  button_text?: InputMaybe<Scalars['String']['input']>;
  button_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_text_ne?: InputMaybe<Scalars['String']['input']>;
  button_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link?: InputMaybe<Scalars['String']['input']>;
  external_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  external_link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link_ne?: InputMaybe<Scalars['String']['input']>;
  external_link_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reference?: InputMaybe<GroupPageHeroBannerContentReferenceWhere>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum GroupPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupPageWhere>>>;
  content_sections?: InputMaybe<GroupPageContentSectionsWhere>;
  content_sections_count?: InputMaybe<Scalars['Int']['input']>;
  content_sections_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hero_banner_content?: InputMaybe<GroupPageHeroBannerContentWhere>;
  hero_banner_content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<GroupImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<GroupMetadataWhere>;
  metadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupQuarterlyresults = {
  __typename?: 'GroupQuarterlyresults';
  q1?: Maybe<GroupQuarterlyresultsQ1>;
  q2?: Maybe<GroupQuarterlyresultsQ2>;
  q3?: Maybe<GroupQuarterlyresultsQ3>;
  q4?: Maybe<GroupQuarterlyresultsQ4>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupQuarterlyresultsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupQuarterlyresultsQ1 = {
  __typename?: 'GroupQuarterlyresultsQ1';
  fallback_text?: Maybe<Scalars['String']['output']>;
  image?: Maybe<GroupImage>;
  links?: Maybe<Array<Maybe<Link>>>;
  pdfs?: Maybe<Array<Maybe<GroupQuarterlyresultsQ1Pdfs>>>;
};

export type GroupQuarterlyresultsQ1Pdfs = {
  __typename?: 'GroupQuarterlyresultsQ1Pdfs';
  pdfConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupQuarterlyresultsQ1PdfsWhere = {
  pdf?: InputMaybe<SysAssetWhere>;
  pdf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupQuarterlyresultsQ1Where = {
  fallback_text?: InputMaybe<Scalars['String']['input']>;
  fallback_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fallback_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fallback_text_ne?: InputMaybe<Scalars['String']['input']>;
  fallback_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<GroupImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<LinkWhere>;
  links_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pdfs?: InputMaybe<GroupQuarterlyresultsQ1PdfsWhere>;
  pdfs_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupQuarterlyresultsQ2 = {
  __typename?: 'GroupQuarterlyresultsQ2';
  fallback_text?: Maybe<Scalars['String']['output']>;
  image?: Maybe<GroupImage>;
  links?: Maybe<Array<Maybe<Link>>>;
  pdfs?: Maybe<Array<Maybe<GroupQuarterlyresultsQ2Pdfs>>>;
};

export type GroupQuarterlyresultsQ2Pdfs = {
  __typename?: 'GroupQuarterlyresultsQ2Pdfs';
  pdfConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupQuarterlyresultsQ2PdfsWhere = {
  pdf?: InputMaybe<SysAssetWhere>;
  pdf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupQuarterlyresultsQ2Where = {
  fallback_text?: InputMaybe<Scalars['String']['input']>;
  fallback_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fallback_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fallback_text_ne?: InputMaybe<Scalars['String']['input']>;
  fallback_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<GroupImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<LinkWhere>;
  links_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pdfs?: InputMaybe<GroupQuarterlyresultsQ2PdfsWhere>;
  pdfs_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupQuarterlyresultsQ3 = {
  __typename?: 'GroupQuarterlyresultsQ3';
  fallback_text?: Maybe<Scalars['String']['output']>;
  image?: Maybe<GroupImage>;
  links?: Maybe<Array<Maybe<Link>>>;
  pdfs?: Maybe<Array<Maybe<GroupQuarterlyresultsQ3Pdfs>>>;
};

export type GroupQuarterlyresultsQ3Pdfs = {
  __typename?: 'GroupQuarterlyresultsQ3Pdfs';
  pdfConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupQuarterlyresultsQ3PdfsWhere = {
  pdf?: InputMaybe<SysAssetWhere>;
  pdf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupQuarterlyresultsQ3Where = {
  fallback_text?: InputMaybe<Scalars['String']['input']>;
  fallback_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fallback_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fallback_text_ne?: InputMaybe<Scalars['String']['input']>;
  fallback_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<GroupImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<LinkWhere>;
  links_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pdfs?: InputMaybe<GroupQuarterlyresultsQ3PdfsWhere>;
  pdfs_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupQuarterlyresultsQ4 = {
  __typename?: 'GroupQuarterlyresultsQ4';
  fallback_text?: Maybe<Scalars['String']['output']>;
  image?: Maybe<GroupImage>;
  links?: Maybe<Array<Maybe<Link>>>;
  pdfs?: Maybe<Array<Maybe<GroupQuarterlyresultsQ4Pdfs>>>;
};

export type GroupQuarterlyresultsQ4Pdfs = {
  __typename?: 'GroupQuarterlyresultsQ4Pdfs';
  pdfConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupQuarterlyresultsQ4PdfsWhere = {
  pdf?: InputMaybe<SysAssetWhere>;
  pdf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupQuarterlyresultsQ4Where = {
  fallback_text?: InputMaybe<Scalars['String']['input']>;
  fallback_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fallback_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fallback_text_ne?: InputMaybe<Scalars['String']['input']>;
  fallback_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<GroupImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<LinkWhere>;
  links_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pdfs?: InputMaybe<GroupQuarterlyresultsQ4PdfsWhere>;
  pdfs_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupQuarterlyresultsWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupQuarterlyresultsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupQuarterlyresultsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  q1?: InputMaybe<GroupQuarterlyresultsQ1Where>;
  q1_exists?: InputMaybe<Scalars['Boolean']['input']>;
  q2?: InputMaybe<GroupQuarterlyresultsQ2Where>;
  q2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  q3?: InputMaybe<GroupQuarterlyresultsQ3Where>;
  q3_exists?: InputMaybe<Scalars['Boolean']['input']>;
  q4?: InputMaybe<GroupQuarterlyresultsQ4Where>;
  q4_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupStreamPage = {
  __typename?: 'GroupStreamPage';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  video_title?: Maybe<Scalars['String']['output']>;
  video_url?: Maybe<Scalars['String']['output']>;
};

export enum GroupStreamPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupStreamPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupStreamPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupStreamPageWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  video_title?: InputMaybe<Scalars['String']['input']>;
  video_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_title_ne?: InputMaybe<Scalars['String']['input']>;
  video_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_url?: InputMaybe<Scalars['String']['input']>;
  video_url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video_url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_url_ne?: InputMaybe<Scalars['String']['input']>;
  video_url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupTabcontent = {
  __typename?: 'GroupTabcontent';
  content_from_keldan?: Maybe<Scalars['String']['output']>;
  hide_title_from_webpage?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<GroupImage>;
  image_alignment?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  mobile_image?: Maybe<GroupImage>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupTabcontentOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupTabcontentWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupTabcontentWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupTabcontentWhere>>>;
  content_from_keldan?: InputMaybe<Scalars['String']['input']>;
  content_from_keldan_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_from_keldan_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_from_keldan_ne?: InputMaybe<Scalars['String']['input']>;
  content_from_keldan_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hide_title_from_webpage?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_ne?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<GroupImageWhere>;
  image_alignment?: InputMaybe<Scalars['String']['input']>;
  image_alignment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_alignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_alignment_ne?: InputMaybe<Scalars['String']['input']>;
  image_alignment_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobile_image?: InputMaybe<GroupImageWhere>;
  mobile_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupTable = {
  __typename?: 'GroupTable';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum GroupTableOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupTableWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupTableWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupTableWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type GroupTranslationStrings = {
  __typename?: 'GroupTranslationStrings';
  string_value?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GroupTranslationStringsAll = {
  __typename?: 'GroupTranslationStringsAll';
  group_translation_stringsConnection?: Maybe<GroupTranslationStringsAllGroupTranslationStringsConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type GroupTranslationStringsAllGroup_Translation_StringsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupTranslationStringsAllGroupTranslationStringsConnection = {
  __typename?: 'GroupTranslationStringsAllGroupTranslationStringsConnection';
  edges?: Maybe<Array<Maybe<GroupTranslationStringsAllGroupTranslationStringsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupTranslationStringsAllGroupTranslationStringsEdge = {
  __typename?: 'GroupTranslationStringsAllGroupTranslationStringsEdge';
  node?: Maybe<GroupTranslationStringsAllGroupTranslationStringsNode>;
};

export type GroupTranslationStringsAllGroupTranslationStringsNode = GroupTranslationStrings;

export type GroupTranslationStringsAllGroupTranslationStringsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  group_translation_strings?: InputMaybe<GroupTranslationStringsWhere>;
};

export enum GroupTranslationStringsAllOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupTranslationStringsAllWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupTranslationStringsAllWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupTranslationStringsAllWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  group_translation_strings?: InputMaybe<GroupTranslationStringsAllGroupTranslationStringsWhere>;
  group_translation_strings_count?: InputMaybe<Scalars['Int']['input']>;
  group_translation_strings_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum GroupTranslationStringsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type GroupTranslationStringsWhere = {
  AND?: InputMaybe<Array<InputMaybe<GroupTranslationStringsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<GroupTranslationStringsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  string_value?: InputMaybe<Scalars['String']['input']>;
  string_value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  string_value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_value_ne?: InputMaybe<Scalars['String']['input']>;
  string_value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type Icon = {
  __typename?: 'Icon';
  imageConnection?: Maybe<SysAssetConnection>;
  svg_code?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum IconOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type IconWhere = {
  AND?: InputMaybe<Array<InputMaybe<IconWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<IconWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  svg_code?: InputMaybe<Scalars['String']['input']>;
  svg_code_exists?: InputMaybe<Scalars['Boolean']['input']>;
  svg_code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  svg_code_ne?: InputMaybe<Scalars['String']['input']>;
  svg_code_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IjcAirports = {
  __typename?: 'IjcAirports';
  images?: Maybe<Array<Maybe<IjcImage>>>;
  link_to_airport_fees?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  opening_hours?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum IjcAirportsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type IjcAirportsWhere = {
  AND?: InputMaybe<Array<InputMaybe<IjcAirportsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<IjcAirportsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<IjcImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_to_airport_fees?: InputMaybe<Scalars['String']['input']>;
  link_to_airport_fees_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_to_airport_fees_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_to_airport_fees_ne?: InputMaybe<Scalars['String']['input']>;
  link_to_airport_fees_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  opening_hours?: InputMaybe<Scalars['String']['input']>;
  opening_hours_exists?: InputMaybe<Scalars['Boolean']['input']>;
  opening_hours_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  opening_hours_ne?: InputMaybe<Scalars['String']['input']>;
  opening_hours_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IjcFrontpage = {
  __typename?: 'IjcFrontpage';
  images?: Maybe<Array<Maybe<IjcImage>>>;
  metadata?: Maybe<IjcMetadata>;
  pages_in_caroselConnection?: Maybe<IjcFrontpagePagesInCaroselConnection>;
  referenceConnection?: Maybe<IjcFrontpageReferenceConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  top_banner_link_referenceConnection?: Maybe<IjcFrontpageTopBannerLinkReferenceConnection>;
  top_banner_link_text?: Maybe<Scalars['String']['output']>;
  top_banner_subtitle?: Maybe<Scalars['String']['output']>;
  top_banner_text?: Maybe<Scalars['String']['output']>;
  top_banner_title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type IjcFrontpagePages_In_CaroselConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type IjcFrontpageReferenceConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum IjcFrontpageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type IjcFrontpagePagesInCaroselConnection = {
  __typename?: 'IjcFrontpagePagesInCaroselConnection';
  edges?: Maybe<Array<Maybe<IjcFrontpagePagesInCaroselEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type IjcFrontpagePagesInCaroselEdge = {
  __typename?: 'IjcFrontpagePagesInCaroselEdge';
  node?: Maybe<IjcFrontpagePagesInCaroselNode>;
};

export type IjcFrontpagePagesInCaroselNode = LjcPage;

export type IjcFrontpagePagesInCaroselWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  ljc_page?: InputMaybe<LjcPageWhere>;
};

export type IjcFrontpageReferenceConnection = {
  __typename?: 'IjcFrontpageReferenceConnection';
  edges?: Maybe<Array<Maybe<IjcFrontpageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type IjcFrontpageReferenceEdge = {
  __typename?: 'IjcFrontpageReferenceEdge';
  node?: Maybe<IjcFrontpageReferenceNode>;
};

export type IjcFrontpageReferenceNode = LjcPage;

export type IjcFrontpageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  ljc_page?: InputMaybe<LjcPageWhere>;
};

export type IjcFrontpageTopBannerLinkReferenceConnection = {
  __typename?: 'IjcFrontpageTopBannerLinkReferenceConnection';
  edges?: Maybe<Array<Maybe<IjcFrontpageTopBannerLinkReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type IjcFrontpageTopBannerLinkReferenceEdge = {
  __typename?: 'IjcFrontpageTopBannerLinkReferenceEdge';
  node?: Maybe<IjcFrontpageTopBannerLinkReferenceNode>;
};

export type IjcFrontpageTopBannerLinkReferenceNode = LjcPage;

export type IjcFrontpageTopBannerLinkReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  ljc_page?: InputMaybe<LjcPageWhere>;
};

export type IjcFrontpageWhere = {
  AND?: InputMaybe<Array<InputMaybe<IjcFrontpageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<IjcFrontpageWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<IjcImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<IjcMetadataWhere>;
  metadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pages_in_carosel?: InputMaybe<IjcFrontpagePagesInCaroselWhere>;
  pages_in_carosel_count?: InputMaybe<Scalars['Int']['input']>;
  pages_in_carosel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  reference?: InputMaybe<IjcFrontpageReferenceWhere>;
  reference_count?: InputMaybe<Scalars['Int']['input']>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_link_reference?: InputMaybe<IjcFrontpageTopBannerLinkReferenceWhere>;
  top_banner_link_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  top_banner_link_text?: InputMaybe<Scalars['String']['input']>;
  top_banner_link_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  top_banner_link_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_link_text_ne?: InputMaybe<Scalars['String']['input']>;
  top_banner_link_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_subtitle?: InputMaybe<Scalars['String']['input']>;
  top_banner_subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  top_banner_subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_subtitle_ne?: InputMaybe<Scalars['String']['input']>;
  top_banner_subtitle_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_text?: InputMaybe<Scalars['String']['input']>;
  top_banner_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  top_banner_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_text_ne?: InputMaybe<Scalars['String']['input']>;
  top_banner_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_title?: InputMaybe<Scalars['String']['input']>;
  top_banner_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  top_banner_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  top_banner_title_ne?: InputMaybe<Scalars['String']['input']>;
  top_banner_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IjcGenericcontent = {
  __typename?: 'IjcGenericcontent';
  imageConnection?: Maybe<SysAssetConnection>;
  image_alignment?: Maybe<Scalars['String']['output']>;
  image_crop?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum IjcGenericcontentOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type IjcGenericcontentWhere = {
  AND?: InputMaybe<Array<InputMaybe<IjcGenericcontentWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<IjcGenericcontentWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_alignment?: InputMaybe<Scalars['String']['input']>;
  image_alignment_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_alignment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_alignment_ne?: InputMaybe<Scalars['String']['input']>;
  image_alignment_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IjcImage = {
  __typename?: 'IjcImage';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
};

export type IjcImageWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IjcKitchen = {
  __typename?: 'IjcKitchen';
  images?: Maybe<Array<Maybe<IjcImage>>>;
  main_text?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum IjcKitchenOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type IjcKitchenWhere = {
  AND?: InputMaybe<Array<InputMaybe<IjcKitchenWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<IjcKitchenWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<IjcImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IjcMenu = {
  __typename?: 'IjcMenu';
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum IjcMenuOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type IjcMenuWhere = {
  AND?: InputMaybe<Array<InputMaybe<IjcMenuWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<IjcMenuWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IjcMetadata = {
  __typename?: 'IjcMetadata';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type IjcMetadataWhere = {
  meta_description?: InputMaybe<Scalars['String']['input']>;
  meta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_description_ne?: InputMaybe<Scalars['String']['input']>;
  meta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title?: InputMaybe<Scalars['String']['input']>;
  meta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title_ne?: InputMaybe<Scalars['String']['input']>;
  meta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IjcStaff = {
  __typename?: 'IjcStaff';
  imageConnection?: Maybe<SysAssetConnection>;
  images?: Maybe<Array<Maybe<IjcImage>>>;
  main_text?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum IjcStaffOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type IjcStaffWhere = {
  AND?: InputMaybe<Array<InputMaybe<IjcStaffWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<IjcStaffWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  images?: InputMaybe<IjcImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type Image = {
  __typename?: 'Image';
  alt_text?: Maybe<Scalars['String']['output']>;
  assetConnection?: Maybe<SysAssetConnection>;
  crop_resize?: Maybe<Scalars['String']['output']>;
  gravity?: Maybe<Scalars['String']['output']>;
};

export type ImageWhere = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  alt_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  alt_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  alt_text_ne?: InputMaybe<Scalars['String']['input']>;
  alt_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  asset?: InputMaybe<SysAssetWhere>;
  asset_exists?: InputMaybe<Scalars['Boolean']['input']>;
  crop_resize?: InputMaybe<Scalars['String']['input']>;
  crop_resize_exists?: InputMaybe<Scalars['Boolean']['input']>;
  crop_resize_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crop_resize_ne?: InputMaybe<Scalars['String']['input']>;
  crop_resize_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity?: InputMaybe<Scalars['String']['input']>;
  gravity_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gravity_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity_ne?: InputMaybe<Scalars['String']['input']>;
  gravity_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Images = {
  __typename?: 'Images';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  image_crop?: Maybe<Scalars['String']['output']>;
  photo_credit?: Maybe<Scalars['String']['output']>;
};

export type ImagesWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_crop?: InputMaybe<Scalars['String']['input']>;
  image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  photo_credit?: InputMaybe<Scalars['String']['input']>;
  photo_credit_exists?: InputMaybe<Scalars['Boolean']['input']>;
  photo_credit_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  photo_credit_ne?: InputMaybe<Scalars['String']['input']>;
  photo_credit_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Link = {
  __typename?: 'Link';
  href?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LinkWhere = {
  href?: InputMaybe<Scalars['String']['input']>;
  href_exists?: InputMaybe<Scalars['Boolean']['input']>;
  href_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  href_ne?: InputMaybe<Scalars['String']['input']>;
  href_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LjcPage = {
  __typename?: 'LjcPage';
  category_header?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<IjcImage>>>;
  main_text?: Maybe<Scalars['String']['output']>;
  main_text_header?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<IjcMetadata>;
  referenceConnection?: Maybe<LjcPageReferenceConnection>;
  short_description?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type LjcPageReferenceConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LjcPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LjcPageReferenceConnection = {
  __typename?: 'LjcPageReferenceConnection';
  edges?: Maybe<Array<Maybe<LjcPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LjcPageReferenceEdge = {
  __typename?: 'LjcPageReferenceEdge';
  node?: Maybe<LjcPageReferenceNode>;
};

export type LjcPageReferenceNode = IjcAirports | IjcFrontpage | IjcGenericcontent | IjcKitchen | IjcMenu | IjcStaff | LjcPage;

export type LjcPageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  ijc_airports?: InputMaybe<IjcAirportsWhere>;
  ijc_frontpage?: InputMaybe<IjcFrontpageWhere>;
  ijc_genericcontent?: InputMaybe<IjcGenericcontentWhere>;
  ijc_kitchen?: InputMaybe<IjcKitchenWhere>;
  ijc_menu?: InputMaybe<IjcMenuWhere>;
  ijc_staff?: InputMaybe<IjcStaffWhere>;
  ljc_page?: InputMaybe<LjcPageWhere>;
};

export type LjcPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<LjcPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LjcPageWhere>>>;
  category_header?: InputMaybe<Scalars['String']['input']>;
  category_header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  category_header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category_header_ne?: InputMaybe<Scalars['String']['input']>;
  category_header_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<IjcImageWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_header?: InputMaybe<Scalars['String']['input']>;
  main_text_header_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_header_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_header_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_header_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<IjcMetadataWhere>;
  metadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  reference?: InputMaybe<LjcPageReferenceWhere>;
  reference_count?: InputMaybe<Scalars['Int']['input']>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  short_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  short_description_ne?: InputMaybe<Scalars['String']['input']>;
  short_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_ne?: InputMaybe<Scalars['String']['input']>;
  subtitle_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftAircraftData = {
  __typename?: 'LoftAircraftData';
  aircraft_image?: Maybe<LoftImage>;
  configurations?: Maybe<Array<Maybe<LoftAircraftDataConfigurations>>>;
  contentbeltsConnection?: Maybe<LoftAircraftDataContentbeltsConnection>;
  general_information_box?: Maybe<LoftAircraftDataGeneralInformationBox>;
  intro_text?: Maybe<Scalars['String']['output']>;
  specs?: Maybe<LoftAircraftDataSpecs>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type LoftAircraftDataContentbeltsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftAircraftDataConfigurations = {
  __typename?: 'LoftAircraftDataConfigurations';
  collapsible_section?: Maybe<LoftAircraftDataConfigurationsCollapsibleSection>;
  configuration_imageConnection?: Maybe<SysAssetConnection>;
  configuration_view?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftAircraftDataConfigurationsCollapsibleSection = {
  __typename?: 'LoftAircraftDataConfigurationsCollapsibleSection';
  entries?: Maybe<Array<Maybe<LoftAircraftDataConfigurationsCollapsibleSectionEntries>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftAircraftDataConfigurationsCollapsibleSectionEntries = {
  __typename?: 'LoftAircraftDataConfigurationsCollapsibleSectionEntries';
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftAircraftDataConfigurationsCollapsibleSectionEntriesWhere = {
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftAircraftDataConfigurationsCollapsibleSectionWhere = {
  entries?: InputMaybe<LoftAircraftDataConfigurationsCollapsibleSectionEntriesWhere>;
  entries_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_ne?: InputMaybe<Scalars['String']['input']>;
  subtitle_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftAircraftDataConfigurationsWhere = {
  collapsible_section?: InputMaybe<LoftAircraftDataConfigurationsCollapsibleSectionWhere>;
  collapsible_section_exists?: InputMaybe<Scalars['Boolean']['input']>;
  configuration_image?: InputMaybe<SysAssetWhere>;
  configuration_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  configuration_view?: InputMaybe<Scalars['String']['input']>;
  configuration_view_exists?: InputMaybe<Scalars['Boolean']['input']>;
  configuration_view_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  configuration_view_ne?: InputMaybe<Scalars['String']['input']>;
  configuration_view_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftAircraftDataContentbeltsConnection = {
  __typename?: 'LoftAircraftDataContentbeltsConnection';
  edges?: Maybe<Array<Maybe<LoftAircraftDataContentbeltsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftAircraftDataContentbeltsEdge = {
  __typename?: 'LoftAircraftDataContentbeltsEdge';
  node?: Maybe<LoftAircraftDataContentbeltsNode>;
};

export type LoftAircraftDataContentbeltsNode = LoftContentbelts;

export type LoftAircraftDataContentbeltsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_contentbelts?: InputMaybe<LoftContentbeltsWhere>;
};

export type LoftAircraftDataGeneralInformationBox = {
  __typename?: 'LoftAircraftDataGeneralInformationBox';
  entries?: Maybe<Array<Maybe<LoftAircraftDataGeneralInformationBoxEntries>>>;
};

export type LoftAircraftDataGeneralInformationBoxEntries = {
  __typename?: 'LoftAircraftDataGeneralInformationBoxEntries';
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type LoftAircraftDataGeneralInformationBoxEntriesWhere = {
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_ne?: InputMaybe<Scalars['String']['input']>;
  value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftAircraftDataGeneralInformationBoxWhere = {
  entries?: InputMaybe<LoftAircraftDataGeneralInformationBoxEntriesWhere>;
  entries_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LoftAircraftDataOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftAircraftDataSpecs = {
  __typename?: 'LoftAircraftDataSpecs';
  entries?: Maybe<Array<Maybe<LoftAircraftDataSpecsEntries>>>;
};

export type LoftAircraftDataSpecsEntries = {
  __typename?: 'LoftAircraftDataSpecsEntries';
  pdfConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftAircraftDataSpecsEntriesWhere = {
  pdf?: InputMaybe<SysAssetWhere>;
  pdf_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftAircraftDataSpecsWhere = {
  entries?: InputMaybe<LoftAircraftDataSpecsEntriesWhere>;
  entries_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftAircraftDataWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftAircraftDataWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftAircraftDataWhere>>>;
  aircraft_image?: InputMaybe<LoftImageWhere>;
  aircraft_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  configurations?: InputMaybe<LoftAircraftDataConfigurationsWhere>;
  configurations_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentbelts?: InputMaybe<LoftAircraftDataContentbeltsWhere>;
  contentbelts_count?: InputMaybe<Scalars['Int']['input']>;
  contentbelts_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  general_information_box?: InputMaybe<LoftAircraftDataGeneralInformationBoxWhere>;
  general_information_box_exists?: InputMaybe<Scalars['Boolean']['input']>;
  intro_text?: InputMaybe<Scalars['String']['input']>;
  intro_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  intro_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  intro_text_ne?: InputMaybe<Scalars['String']['input']>;
  intro_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  specs?: InputMaybe<LoftAircraftDataSpecsWhere>;
  specs_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftContentbelts = {
  __typename?: 'LoftContentbelts';
  background_color_templates?: Maybe<Scalars['String']['output']>;
  background_effect?: Maybe<Scalars['String']['output']>;
  certificate?: Maybe<Array<Maybe<LoftContentbeltsCertificate>>>;
  collapsiblesection?: Maybe<LoftContentbeltsCollapsiblesection>;
  destinations_globeConnection?: Maybe<LoftContentbeltsDestinationsGlobeConnection>;
  display_title?: Maybe<Scalars['String']['output']>;
  fleet_display?: Maybe<LoftContentbeltsFleetDisplay>;
  full_width?: Maybe<Scalars['String']['output']>;
  hide_title_from_webpage?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<LoftImage>;
  image_carousel?: Maybe<LoftContentbeltsImageCarousel>;
  quote_request_banner?: Maybe<LoftContentbeltsQuoteRequestBanner>;
  salesforce_form?: Maybe<LoftContentbeltsSalesforceForm>;
  system?: Maybe<EntrySystemField>;
  text_video_image?: Maybe<LoftContentbeltsTextVideoImage>;
  tiles?: Maybe<LoftContentbeltsTiles>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type LoftContentbeltsCertificate = {
  __typename?: 'LoftContentbeltsCertificate';
  certificate_documentConnection?: Maybe<SysAssetConnection>;
  certificate_type?: Maybe<Scalars['String']['output']>;
  reference_number?: Maybe<Scalars['String']['output']>;
};

export type LoftContentbeltsCertificateWhere = {
  certificate_document?: InputMaybe<SysAssetWhere>;
  certificate_document_exists?: InputMaybe<Scalars['Boolean']['input']>;
  certificate_type?: InputMaybe<Scalars['String']['input']>;
  certificate_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  certificate_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  certificate_type_ne?: InputMaybe<Scalars['String']['input']>;
  certificate_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reference_number?: InputMaybe<Scalars['String']['input']>;
  reference_number_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reference_number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reference_number_ne?: InputMaybe<Scalars['String']['input']>;
  reference_number_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftContentbeltsCollapsiblesection = {
  __typename?: 'LoftContentbeltsCollapsiblesection';
  entries?: Maybe<Array<Maybe<LoftContentbeltsCollapsiblesectionEntries>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftContentbeltsCollapsiblesectionEntries = {
  __typename?: 'LoftContentbeltsCollapsiblesectionEntries';
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftContentbeltsCollapsiblesectionEntriesWhere = {
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftContentbeltsCollapsiblesectionWhere = {
  entries?: InputMaybe<LoftContentbeltsCollapsiblesectionEntriesWhere>;
  entries_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  subtitle_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subtitle_ne?: InputMaybe<Scalars['String']['input']>;
  subtitle_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftContentbeltsDestinationsGlobeConnection = {
  __typename?: 'LoftContentbeltsDestinationsGlobeConnection';
  edges?: Maybe<Array<Maybe<LoftContentbeltsDestinationsGlobeEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftContentbeltsDestinationsGlobeEdge = {
  __typename?: 'LoftContentbeltsDestinationsGlobeEdge';
  node?: Maybe<LoftContentbeltsDestinationsGlobeNode>;
};

export type LoftContentbeltsDestinationsGlobeNode = LoftProjectsGlobe;

export type LoftContentbeltsDestinationsGlobeWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_projects_globe?: InputMaybe<LoftProjectsGlobeWhere>;
};

export type LoftContentbeltsFleetDisplay = {
  __typename?: 'LoftContentbeltsFleetDisplay';
  aircraftConnection?: Maybe<LoftContentbeltsFleetDisplayAircraftConnection>;
  display_type?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<LoftContentbeltsFleetDisplayFilter>;
};


export type LoftContentbeltsFleetDisplayAircraftConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftContentbeltsFleetDisplayAircraftConnection = {
  __typename?: 'LoftContentbeltsFleetDisplayAircraftConnection';
  edges?: Maybe<Array<Maybe<LoftContentbeltsFleetDisplayAircraftEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftContentbeltsFleetDisplayAircraftEdge = {
  __typename?: 'LoftContentbeltsFleetDisplayAircraftEdge';
  node?: Maybe<LoftContentbeltsFleetDisplayAircraftNode>;
};

export type LoftContentbeltsFleetDisplayAircraftNode = LoftAircraftData;

export type LoftContentbeltsFleetDisplayAircraftWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_aircraft_data?: InputMaybe<LoftAircraftDataWhere>;
};

export type LoftContentbeltsFleetDisplayFilter = {
  __typename?: 'LoftContentbeltsFleetDisplayFilter';
  filter_type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type LoftContentbeltsFleetDisplayFilterWhere = {
  filter_type?: InputMaybe<Scalars['String']['input']>;
  filter_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  filter_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filter_type_ne?: InputMaybe<Scalars['String']['input']>;
  filter_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftContentbeltsFleetDisplayWhere = {
  aircraft?: InputMaybe<LoftContentbeltsFleetDisplayAircraftWhere>;
  aircraft_count?: InputMaybe<Scalars['Int']['input']>;
  aircraft_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_type?: InputMaybe<Scalars['String']['input']>;
  display_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_type_ne?: InputMaybe<Scalars['String']['input']>;
  display_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filter?: InputMaybe<LoftContentbeltsFleetDisplayFilterWhere>;
  filter_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftContentbeltsImageCarousel = {
  __typename?: 'LoftContentbeltsImageCarousel';
  group?: Maybe<Array<Maybe<LoftContentbeltsImageCarouselGroup>>>;
};

export type LoftContentbeltsImageCarouselGroup = {
  __typename?: 'LoftContentbeltsImageCarouselGroup';
  alt_text?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  fileConnection?: Maybe<SysAssetConnection>;
};

export type LoftContentbeltsImageCarouselGroupWhere = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  alt_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  alt_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  alt_text_ne?: InputMaybe<Scalars['String']['input']>;
  alt_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  file?: InputMaybe<SysAssetWhere>;
  file_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftContentbeltsImageCarouselWhere = {
  group?: InputMaybe<LoftContentbeltsImageCarouselGroupWhere>;
  group_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LoftContentbeltsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftContentbeltsQuoteRequestBanner = {
  __typename?: 'LoftContentbeltsQuoteRequestBanner';
  button_text?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  request_quote_linkConnection?: Maybe<LoftContentbeltsQuoteRequestBannerRequestQuoteLinkConnection>;
};

export type LoftContentbeltsQuoteRequestBannerRequestQuoteLinkConnection = {
  __typename?: 'LoftContentbeltsQuoteRequestBannerRequestQuoteLinkConnection';
  edges?: Maybe<Array<Maybe<LoftContentbeltsQuoteRequestBannerRequestQuoteLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftContentbeltsQuoteRequestBannerRequestQuoteLinkEdge = {
  __typename?: 'LoftContentbeltsQuoteRequestBannerRequestQuoteLinkEdge';
  node?: Maybe<LoftContentbeltsQuoteRequestBannerRequestQuoteLinkNode>;
};

export type LoftContentbeltsQuoteRequestBannerRequestQuoteLinkNode = LoftPage;

export type LoftContentbeltsQuoteRequestBannerRequestQuoteLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export type LoftContentbeltsQuoteRequestBannerWhere = {
  button_text?: InputMaybe<Scalars['String']['input']>;
  button_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_text_ne?: InputMaybe<Scalars['String']['input']>;
  button_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  request_quote_link?: InputMaybe<LoftContentbeltsQuoteRequestBannerRequestQuoteLinkWhere>;
  request_quote_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftContentbeltsSalesforceForm = {
  __typename?: 'LoftContentbeltsSalesforceForm';
  headline?: Maybe<Scalars['String']['output']>;
  link_to_salesforce_formConnection?: Maybe<LoftContentbeltsSalesforceFormLinkToSalesforceFormConnection>;
  main_text?: Maybe<Scalars['String']['output']>;
};

export type LoftContentbeltsSalesforceFormLinkToSalesforceFormConnection = {
  __typename?: 'LoftContentbeltsSalesforceFormLinkToSalesforceFormConnection';
  edges?: Maybe<Array<Maybe<LoftContentbeltsSalesforceFormLinkToSalesforceFormEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftContentbeltsSalesforceFormLinkToSalesforceFormEdge = {
  __typename?: 'LoftContentbeltsSalesforceFormLinkToSalesforceFormEdge';
  node?: Maybe<LoftContentbeltsSalesforceFormLinkToSalesforceFormNode>;
};

export type LoftContentbeltsSalesforceFormLinkToSalesforceFormNode = LoftQuoteSalesforce;

export type LoftContentbeltsSalesforceFormLinkToSalesforceFormWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_quote_salesforce?: InputMaybe<LoftQuoteSalesforceWhere>;
};

export type LoftContentbeltsSalesforceFormWhere = {
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_to_salesforce_form?: InputMaybe<LoftContentbeltsSalesforceFormLinkToSalesforceFormWhere>;
  link_to_salesforce_form_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftContentbeltsTextVideoImage = {
  __typename?: 'LoftContentbeltsTextVideoImage';
  link_button_text?: Maybe<Scalars['String']['output']>;
  link_to_another_pageConnection?: Maybe<LoftContentbeltsTextVideoImageLinkToAnotherPageConnection>;
  main_text?: Maybe<Scalars['String']['output']>;
  title_color?: Maybe<Scalars['String']['output']>;
  video_id?: Maybe<Scalars['String']['output']>;
};

export type LoftContentbeltsTextVideoImageLinkToAnotherPageConnection = {
  __typename?: 'LoftContentbeltsTextVideoImageLinkToAnotherPageConnection';
  edges?: Maybe<Array<Maybe<LoftContentbeltsTextVideoImageLinkToAnotherPageEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftContentbeltsTextVideoImageLinkToAnotherPageEdge = {
  __typename?: 'LoftContentbeltsTextVideoImageLinkToAnotherPageEdge';
  node?: Maybe<LoftContentbeltsTextVideoImageLinkToAnotherPageNode>;
};

export type LoftContentbeltsTextVideoImageLinkToAnotherPageNode = LoftPage;

export type LoftContentbeltsTextVideoImageLinkToAnotherPageWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export type LoftContentbeltsTextVideoImageWhere = {
  link_button_text?: InputMaybe<Scalars['String']['input']>;
  link_button_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_button_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_button_text_ne?: InputMaybe<Scalars['String']['input']>;
  link_button_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_to_another_page?: InputMaybe<LoftContentbeltsTextVideoImageLinkToAnotherPageWhere>;
  link_to_another_page_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_color?: InputMaybe<Scalars['String']['input']>;
  title_color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_color_ne?: InputMaybe<Scalars['String']['input']>;
  title_color_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_id?: InputMaybe<Scalars['String']['input']>;
  video_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  video_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video_id_ne?: InputMaybe<Scalars['String']['input']>;
  video_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftContentbeltsTiles = {
  __typename?: 'LoftContentbeltsTiles';
  filter?: Maybe<Scalars['Boolean']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  see_all_link?: Maybe<Link>;
  tiles_display?: Maybe<Scalars['String']['output']>;
  tiles_selectionConnection?: Maybe<LoftContentbeltsTilesTilesSelectionConnection>;
  type_of_tiles?: Maybe<Scalars['String']['output']>;
};


export type LoftContentbeltsTilesTiles_SelectionConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftContentbeltsTilesTilesSelectionConnection = {
  __typename?: 'LoftContentbeltsTilesTilesSelectionConnection';
  edges?: Maybe<Array<Maybe<LoftContentbeltsTilesTilesSelectionEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftContentbeltsTilesTilesSelectionEdge = {
  __typename?: 'LoftContentbeltsTilesTilesSelectionEdge';
  node?: Maybe<LoftContentbeltsTilesTilesSelectionNode>;
};

export type LoftContentbeltsTilesTilesSelectionNode = LoftTiles;

export type LoftContentbeltsTilesTilesSelectionWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_tiles?: InputMaybe<LoftTilesWhere>;
};

export type LoftContentbeltsTilesWhere = {
  filter?: InputMaybe<Scalars['Boolean']['input']>;
  filter_exists?: InputMaybe<Scalars['Boolean']['input']>;
  filter_ne?: InputMaybe<Scalars['Boolean']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  see_all_link?: InputMaybe<LinkWhere>;
  see_all_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tiles_display?: InputMaybe<Scalars['String']['input']>;
  tiles_display_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tiles_display_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tiles_display_ne?: InputMaybe<Scalars['String']['input']>;
  tiles_display_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tiles_selection?: InputMaybe<LoftContentbeltsTilesTilesSelectionWhere>;
  tiles_selection_count?: InputMaybe<Scalars['Int']['input']>;
  tiles_selection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_of_tiles?: InputMaybe<Scalars['String']['input']>;
  type_of_tiles_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_of_tiles_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_of_tiles_ne?: InputMaybe<Scalars['String']['input']>;
  type_of_tiles_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftContentbeltsWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftContentbeltsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftContentbeltsWhere>>>;
  background_color_templates?: InputMaybe<Scalars['String']['input']>;
  background_color_templates_exists?: InputMaybe<Scalars['Boolean']['input']>;
  background_color_templates_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_color_templates_ne?: InputMaybe<Scalars['String']['input']>;
  background_color_templates_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_effect?: InputMaybe<Scalars['String']['input']>;
  background_effect_exists?: InputMaybe<Scalars['Boolean']['input']>;
  background_effect_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_effect_ne?: InputMaybe<Scalars['String']['input']>;
  background_effect_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  certificate?: InputMaybe<LoftContentbeltsCertificateWhere>;
  certificate_exists?: InputMaybe<Scalars['Boolean']['input']>;
  collapsiblesection?: InputMaybe<LoftContentbeltsCollapsiblesectionWhere>;
  collapsiblesection_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  destinations_globe?: InputMaybe<LoftContentbeltsDestinationsGlobeWhere>;
  destinations_globe_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fleet_display?: InputMaybe<LoftContentbeltsFleetDisplayWhere>;
  fleet_display_exists?: InputMaybe<Scalars['Boolean']['input']>;
  full_width?: InputMaybe<Scalars['String']['input']>;
  full_width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  full_width_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  full_width_ne?: InputMaybe<Scalars['String']['input']>;
  full_width_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hide_title_from_webpage?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hide_title_from_webpage_ne?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<LoftImageWhere>;
  image_carousel?: InputMaybe<LoftContentbeltsImageCarouselWhere>;
  image_carousel_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  quote_request_banner?: InputMaybe<LoftContentbeltsQuoteRequestBannerWhere>;
  quote_request_banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  salesforce_form?: InputMaybe<LoftContentbeltsSalesforceFormWhere>;
  salesforce_form_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_video_image?: InputMaybe<LoftContentbeltsTextVideoImageWhere>;
  text_video_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tiles?: InputMaybe<LoftContentbeltsTilesWhere>;
  tiles_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftFooter = {
  __typename?: 'LoftFooter';
  contact_us_area?: Maybe<LoftFooterContactUsArea>;
  cta_area?: Maybe<LoftFooterCtaArea>;
  footer_copyright_info?: Maybe<LoftFooterFooterCopyrightInfo>;
  logo_fieldConnection?: Maybe<SysAssetConnection>;
  navigationConnection?: Maybe<LoftFooterNavigationConnection>;
  social_media_links?: Maybe<Array<Maybe<LoftFooterSocialMediaLinks>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type LoftFooterNavigationConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftFooterContactUsArea = {
  __typename?: 'LoftFooterContactUsArea';
  details?: Maybe<Array<Maybe<LoftFooterContactUsAreaDetails>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftFooterContactUsAreaDetails = {
  __typename?: 'LoftFooterContactUsAreaDetails';
  phone_number?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
};

export type LoftFooterContactUsAreaDetailsWhere = {
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_number_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_number_ne?: InputMaybe<Scalars['String']['input']>;
  phone_number_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  service?: InputMaybe<Scalars['String']['input']>;
  service_exists?: InputMaybe<Scalars['Boolean']['input']>;
  service_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  service_ne?: InputMaybe<Scalars['String']['input']>;
  service_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftFooterContactUsAreaWhere = {
  details?: InputMaybe<LoftFooterContactUsAreaDetailsWhere>;
  details_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftFooterCtaArea = {
  __typename?: 'LoftFooterCtaArea';
  cta_description?: Maybe<Scalars['String']['output']>;
  cta_link?: Maybe<Link>;
  cta_title?: Maybe<Scalars['String']['output']>;
};

export type LoftFooterCtaAreaWhere = {
  cta_description?: InputMaybe<Scalars['String']['input']>;
  cta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_description_ne?: InputMaybe<Scalars['String']['input']>;
  cta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_link?: InputMaybe<LinkWhere>;
  cta_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_title?: InputMaybe<Scalars['String']['input']>;
  cta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_title_ne?: InputMaybe<Scalars['String']['input']>;
  cta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftFooterFooterCopyrightInfo = {
  __typename?: 'LoftFooterFooterCopyrightInfo';
  text_line?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type LoftFooterFooterCopyrightInfoWhere = {
  text_line?: InputMaybe<Scalars['String']['input']>;
  text_line_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_line_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_line_ne?: InputMaybe<Scalars['String']['input']>;
  text_line_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftFooterNavigationConnection = {
  __typename?: 'LoftFooterNavigationConnection';
  edges?: Maybe<Array<Maybe<LoftFooterNavigationEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftFooterNavigationEdge = {
  __typename?: 'LoftFooterNavigationEdge';
  node?: Maybe<LoftFooterNavigationNode>;
};

export type LoftFooterNavigationNode = LoftPage;

export type LoftFooterNavigationWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export enum LoftFooterOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftFooterSocialMediaLinks = LoftFooterSocialMediaLinksSocialMediaItem;

export type LoftFooterSocialMediaLinksSocialMediaItem = {
  __typename?: 'LoftFooterSocialMediaLinksSocialMediaItem';
  social_media_item?: Maybe<LoftFooterSocialMediaLinksSocialMediaItemBlock>;
};

export type LoftFooterSocialMediaLinksSocialMediaItemBlock = {
  __typename?: 'LoftFooterSocialMediaLinksSocialMediaItemBlock';
  link?: Maybe<Link>;
  social_media_iconConnection?: Maybe<SysAssetConnection>;
};

export type LoftFooterSocialMediaLinksSocialMediaItemBlockWhere = {
  link?: InputMaybe<LinkWhere>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  social_media_icon?: InputMaybe<SysAssetWhere>;
  social_media_icon_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftFooterSocialMediaLinksWhere = {
  social_media_item?: InputMaybe<LoftFooterSocialMediaLinksSocialMediaItemBlockWhere>;
  social_media_item_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftFooterWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftFooterWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftFooterWhere>>>;
  contact_us_area?: InputMaybe<LoftFooterContactUsAreaWhere>;
  contact_us_area_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_area?: InputMaybe<LoftFooterCtaAreaWhere>;
  cta_area_exists?: InputMaybe<Scalars['Boolean']['input']>;
  footer_copyright_info?: InputMaybe<LoftFooterFooterCopyrightInfoWhere>;
  footer_copyright_info_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo_field?: InputMaybe<SysAssetWhere>;
  logo_field_exists?: InputMaybe<Scalars['Boolean']['input']>;
  navigation?: InputMaybe<LoftFooterNavigationWhere>;
  navigation_count?: InputMaybe<Scalars['Int']['input']>;
  navigation_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  social_media_links?: InputMaybe<LoftFooterSocialMediaLinksWhere>;
  social_media_links_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftHubandspoke = {
  __typename?: 'LoftHubandspoke';
  destination?: Maybe<Array<Maybe<LoftHubandspokeDestination>>>;
  gateway?: Maybe<LoftHubandspokeGateway>;
};

export type LoftHubandspokeDestination = {
  __typename?: 'LoftHubandspokeDestination';
  coordinates?: Maybe<LoftHubandspokeDestinationCoordinates>;
  location?: Maybe<Scalars['String']['output']>;
};

export type LoftHubandspokeDestinationCoordinates = {
  __typename?: 'LoftHubandspokeDestinationCoordinates';
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
};

export type LoftHubandspokeDestinationCoordinatesWhere = {
  latitude?: InputMaybe<Scalars['String']['input']>;
  latitude_exists?: InputMaybe<Scalars['Boolean']['input']>;
  latitude_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude_ne?: InputMaybe<Scalars['String']['input']>;
  latitude_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  longitude_exists?: InputMaybe<Scalars['Boolean']['input']>;
  longitude_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longitude_ne?: InputMaybe<Scalars['String']['input']>;
  longitude_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftHubandspokeDestinationWhere = {
  coordinates?: InputMaybe<LoftHubandspokeDestinationCoordinatesWhere>;
  coordinates_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_ne?: InputMaybe<Scalars['String']['input']>;
  location_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftHubandspokeGateway = {
  __typename?: 'LoftHubandspokeGateway';
  coordinates?: Maybe<LoftHubandspokeGatewayCoordinates>;
  location?: Maybe<Scalars['String']['output']>;
};

export type LoftHubandspokeGatewayCoordinates = {
  __typename?: 'LoftHubandspokeGatewayCoordinates';
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
};

export type LoftHubandspokeGatewayCoordinatesWhere = {
  latitude?: InputMaybe<Scalars['String']['input']>;
  latitude_exists?: InputMaybe<Scalars['Boolean']['input']>;
  latitude_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude_ne?: InputMaybe<Scalars['String']['input']>;
  latitude_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  longitude_exists?: InputMaybe<Scalars['Boolean']['input']>;
  longitude_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longitude_ne?: InputMaybe<Scalars['String']['input']>;
  longitude_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftHubandspokeGatewayWhere = {
  coordinates?: InputMaybe<LoftHubandspokeGatewayCoordinatesWhere>;
  coordinates_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_ne?: InputMaybe<Scalars['String']['input']>;
  location_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftHubandspokeWhere = {
  destination?: InputMaybe<LoftHubandspokeDestinationWhere>;
  destination_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gateway?: InputMaybe<LoftHubandspokeGatewayWhere>;
  gateway_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftImage = {
  __typename?: 'LoftImage';
  alt_text_web?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  image_crop_mobile?: Maybe<Scalars['String']['output']>;
  mobile_imageConnection?: Maybe<SysAssetConnection>;
  web_imageConnection?: Maybe<SysAssetConnection>;
  web_image_crop?: Maybe<Scalars['String']['output']>;
};

export type LoftImageWhere = {
  alt_text_web?: InputMaybe<Scalars['String']['input']>;
  alt_text_web_exists?: InputMaybe<Scalars['Boolean']['input']>;
  alt_text_web_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  alt_text_web_ne?: InputMaybe<Scalars['String']['input']>;
  alt_text_web_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_mobile?: InputMaybe<Scalars['String']['input']>;
  image_crop_mobile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  image_crop_mobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image_crop_mobile_ne?: InputMaybe<Scalars['String']['input']>;
  image_crop_mobile_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobile_image?: InputMaybe<SysAssetWhere>;
  mobile_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  web_image?: InputMaybe<SysAssetWhere>;
  web_image_crop?: InputMaybe<Scalars['String']['input']>;
  web_image_crop_exists?: InputMaybe<Scalars['Boolean']['input']>;
  web_image_crop_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  web_image_crop_ne?: InputMaybe<Scalars['String']['input']>;
  web_image_crop_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  web_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftLinearRoute = {
  __typename?: 'LoftLinearRoute';
  coordinates?: Maybe<LoftLinearRouteCoordinates>;
  location?: Maybe<Scalars['String']['output']>;
};

export type LoftLinearRouteCoordinates = {
  __typename?: 'LoftLinearRouteCoordinates';
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
};

export type LoftLinearRouteCoordinatesWhere = {
  latitude?: InputMaybe<Scalars['String']['input']>;
  latitude_exists?: InputMaybe<Scalars['Boolean']['input']>;
  latitude_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  latitude_ne?: InputMaybe<Scalars['String']['input']>;
  latitude_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  longitude_exists?: InputMaybe<Scalars['Boolean']['input']>;
  longitude_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  longitude_ne?: InputMaybe<Scalars['String']['input']>;
  longitude_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftLinearRouteWhere = {
  coordinates?: InputMaybe<LoftLinearRouteCoordinatesWhere>;
  coordinates_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_ne?: InputMaybe<Scalars['String']['input']>;
  location_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftMenu = {
  __typename?: 'LoftMenu';
  cta_button?: Maybe<LoftMenuCtaButton>;
  navigation_menuConnection?: Maybe<LoftMenuNavigationMenuConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type LoftMenuNavigation_MenuConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftMenuCtaButton = {
  __typename?: 'LoftMenuCtaButton';
  button_label?: Maybe<Scalars['String']['output']>;
  page_referenceConnection?: Maybe<LoftMenuCtaButtonPageReferenceConnection>;
};

export type LoftMenuCtaButtonPageReferenceConnection = {
  __typename?: 'LoftMenuCtaButtonPageReferenceConnection';
  edges?: Maybe<Array<Maybe<LoftMenuCtaButtonPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftMenuCtaButtonPageReferenceEdge = {
  __typename?: 'LoftMenuCtaButtonPageReferenceEdge';
  node?: Maybe<LoftMenuCtaButtonPageReferenceNode>;
};

export type LoftMenuCtaButtonPageReferenceNode = LoftPage;

export type LoftMenuCtaButtonPageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export type LoftMenuCtaButtonWhere = {
  button_label?: InputMaybe<Scalars['String']['input']>;
  button_label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_label_ne?: InputMaybe<Scalars['String']['input']>;
  button_label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_reference?: InputMaybe<LoftMenuCtaButtonPageReferenceWhere>;
  page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftMenuNavigationMenuConnection = {
  __typename?: 'LoftMenuNavigationMenuConnection';
  edges?: Maybe<Array<Maybe<LoftMenuNavigationMenuEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftMenuNavigationMenuEdge = {
  __typename?: 'LoftMenuNavigationMenuEdge';
  node?: Maybe<LoftMenuNavigationMenuNode>;
};

export type LoftMenuNavigationMenuNode = LoftNavigationMenuItem;

export type LoftMenuNavigationMenuWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_navigation_menu_item?: InputMaybe<LoftNavigationMenuItemWhere>;
};

export enum LoftMenuOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftMenuWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftMenuWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftMenuWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_button?: InputMaybe<LoftMenuCtaButtonWhere>;
  cta_button_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigation_menu?: InputMaybe<LoftMenuNavigationMenuWhere>;
  navigation_menu_count?: InputMaybe<Scalars['Int']['input']>;
  navigation_menu_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftMetadata = {
  __typename?: 'LoftMetadata';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type LoftMetadataWhere = {
  meta_description?: InputMaybe<Scalars['String']['input']>;
  meta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_description_ne?: InputMaybe<Scalars['String']['input']>;
  meta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title?: InputMaybe<Scalars['String']['input']>;
  meta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title_ne?: InputMaybe<Scalars['String']['input']>;
  meta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftNavigationMenuItem = {
  __typename?: 'LoftNavigationMenuItem';
  description?: Maybe<LoftNavigationMenuItemDescription>;
  display_title?: Maybe<Scalars['String']['output']>;
  page_referenceConnection?: Maybe<LoftNavigationMenuItemPageReferenceConnection>;
  sub_menu_navigation_itemsConnection?: Maybe<LoftNavigationMenuItemSubMenuNavigationItemsConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type LoftNavigationMenuItemSub_Menu_Navigation_ItemsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftNavigationMenuItemDescription = {
  __typename?: 'LoftNavigationMenuItemDescription';
  contact_lines?: Maybe<Array<Maybe<LoftNavigationMenuItemDescriptionContactLines>>>;
  cta_button_label?: Maybe<Scalars['String']['output']>;
  cta_title?: Maybe<Scalars['String']['output']>;
  referenceConnection?: Maybe<LoftNavigationMenuItemDescriptionReferenceConnection>;
};

export type LoftNavigationMenuItemDescriptionContactLines = {
  __typename?: 'LoftNavigationMenuItemDescriptionContactLines';
  contact_value?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftNavigationMenuItemDescriptionContactLinesWhere = {
  contact_value?: InputMaybe<Scalars['String']['input']>;
  contact_value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contact_value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contact_value_ne?: InputMaybe<Scalars['String']['input']>;
  contact_value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftNavigationMenuItemDescriptionReferenceConnection = {
  __typename?: 'LoftNavigationMenuItemDescriptionReferenceConnection';
  edges?: Maybe<Array<Maybe<LoftNavigationMenuItemDescriptionReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftNavigationMenuItemDescriptionReferenceEdge = {
  __typename?: 'LoftNavigationMenuItemDescriptionReferenceEdge';
  node?: Maybe<LoftNavigationMenuItemDescriptionReferenceNode>;
};

export type LoftNavigationMenuItemDescriptionReferenceNode = LoftPage;

export type LoftNavigationMenuItemDescriptionReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export type LoftNavigationMenuItemDescriptionWhere = {
  contact_lines?: InputMaybe<LoftNavigationMenuItemDescriptionContactLinesWhere>;
  contact_lines_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_button_label?: InputMaybe<Scalars['String']['input']>;
  cta_button_label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_button_label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_button_label_ne?: InputMaybe<Scalars['String']['input']>;
  cta_button_label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_title?: InputMaybe<Scalars['String']['input']>;
  cta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  cta_title_ne?: InputMaybe<Scalars['String']['input']>;
  cta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reference?: InputMaybe<LoftNavigationMenuItemDescriptionReferenceWhere>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LoftNavigationMenuItemOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftNavigationMenuItemPageReferenceConnection = {
  __typename?: 'LoftNavigationMenuItemPageReferenceConnection';
  edges?: Maybe<Array<Maybe<LoftNavigationMenuItemPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftNavigationMenuItemPageReferenceEdge = {
  __typename?: 'LoftNavigationMenuItemPageReferenceEdge';
  node?: Maybe<LoftNavigationMenuItemPageReferenceNode>;
};

export type LoftNavigationMenuItemPageReferenceNode = LoftPage;

export type LoftNavigationMenuItemPageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export type LoftNavigationMenuItemSubMenuNavigationItemsConnection = {
  __typename?: 'LoftNavigationMenuItemSubMenuNavigationItemsConnection';
  edges?: Maybe<Array<Maybe<LoftNavigationMenuItemSubMenuNavigationItemsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftNavigationMenuItemSubMenuNavigationItemsEdge = {
  __typename?: 'LoftNavigationMenuItemSubMenuNavigationItemsEdge';
  node?: Maybe<LoftNavigationMenuItemSubMenuNavigationItemsNode>;
};

export type LoftNavigationMenuItemSubMenuNavigationItemsNode = LoftAircraftData | LoftPage;

export type LoftNavigationMenuItemSubMenuNavigationItemsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_aircraft_data?: InputMaybe<LoftAircraftDataWhere>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export type LoftNavigationMenuItemWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftNavigationMenuItemWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftNavigationMenuItemWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<LoftNavigationMenuItemDescriptionWhere>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_reference?: InputMaybe<LoftNavigationMenuItemPageReferenceWhere>;
  page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  sub_menu_navigation_items?: InputMaybe<LoftNavigationMenuItemSubMenuNavigationItemsWhere>;
  sub_menu_navigation_items_count?: InputMaybe<Scalars['Int']['input']>;
  sub_menu_navigation_items_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftPage = {
  __typename?: 'LoftPage';
  aircraft_specific_informationConnection?: Maybe<LoftPageAircraftSpecificInformationConnection>;
  banner?: Maybe<LoftPageBanner>;
  content_beltsConnection?: Maybe<LoftPageContentBeltsConnection>;
  metadata?: Maybe<LoftMetadata>;
  page_type?: Maybe<Scalars['String']['output']>;
  quote_or_contact_referenceConnection?: Maybe<DeletedReferencesConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type LoftPageAircraft_Specific_InformationConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type LoftPageContent_BeltsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftPageAircraftSpecificInformationConnection = {
  __typename?: 'LoftPageAircraftSpecificInformationConnection';
  edges?: Maybe<Array<Maybe<LoftPageAircraftSpecificInformationEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftPageAircraftSpecificInformationEdge = {
  __typename?: 'LoftPageAircraftSpecificInformationEdge';
  node?: Maybe<LoftPageAircraftSpecificInformationNode>;
};

export type LoftPageAircraftSpecificInformationNode = LoftAircraftData;

export type LoftPageAircraftSpecificInformationWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_aircraft_data?: InputMaybe<LoftAircraftDataWhere>;
};

export type LoftPageBanner = {
  __typename?: 'LoftPageBanner';
  background?: Maybe<LoftPageBannerBackground>;
  banner_image?: Maybe<LoftImage>;
  page_headline?: Maybe<Scalars['String']['output']>;
  page_intro?: Maybe<Scalars['String']['output']>;
  page_title?: Maybe<Scalars['String']['output']>;
};

export type LoftPageBannerBackground = {
  __typename?: 'LoftPageBannerBackground';
  page_background_color?: Maybe<Scalars['String']['output']>;
  page_background_effect?: Maybe<Scalars['String']['output']>;
};

export type LoftPageBannerBackgroundWhere = {
  page_background_color?: InputMaybe<Scalars['String']['input']>;
  page_background_color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_background_color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_background_color_ne?: InputMaybe<Scalars['String']['input']>;
  page_background_color_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_background_effect?: InputMaybe<Scalars['String']['input']>;
  page_background_effect_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_background_effect_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_background_effect_ne?: InputMaybe<Scalars['String']['input']>;
  page_background_effect_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftPageBannerWhere = {
  background?: InputMaybe<LoftPageBannerBackgroundWhere>;
  background_exists?: InputMaybe<Scalars['Boolean']['input']>;
  banner_image?: InputMaybe<LoftImageWhere>;
  banner_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_headline?: InputMaybe<Scalars['String']['input']>;
  page_headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_headline_ne?: InputMaybe<Scalars['String']['input']>;
  page_headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_intro?: InputMaybe<Scalars['String']['input']>;
  page_intro_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_intro_ne?: InputMaybe<Scalars['String']['input']>;
  page_intro_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_title?: InputMaybe<Scalars['String']['input']>;
  page_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_title_ne?: InputMaybe<Scalars['String']['input']>;
  page_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LoftPageContentBeltsConnection = {
  __typename?: 'LoftPageContentBeltsConnection';
  edges?: Maybe<Array<Maybe<LoftPageContentBeltsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftPageContentBeltsEdge = {
  __typename?: 'LoftPageContentBeltsEdge';
  node?: Maybe<LoftPageContentBeltsNode>;
};

export type LoftPageContentBeltsNode = LoftContentbelts;

export type LoftPageContentBeltsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_contentbelts?: InputMaybe<LoftContentbeltsWhere>;
};

export enum LoftPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftPageWhere>>>;
  aircraft_specific_information?: InputMaybe<LoftPageAircraftSpecificInformationWhere>;
  aircraft_specific_information_count?: InputMaybe<Scalars['Int']['input']>;
  aircraft_specific_information_exists?: InputMaybe<Scalars['Boolean']['input']>;
  banner?: InputMaybe<LoftPageBannerWhere>;
  banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_belts?: InputMaybe<LoftPageContentBeltsWhere>;
  content_belts_count?: InputMaybe<Scalars['Int']['input']>;
  content_belts_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  metadata?: InputMaybe<LoftMetadataWhere>;
  metadata_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_type?: InputMaybe<Scalars['String']['input']>;
  page_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  page_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page_type_ne?: InputMaybe<Scalars['String']['input']>;
  page_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  quote_or_contact_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftPopupPage = {
  __typename?: 'LoftPopupPage';
  destination?: Maybe<Scalars['String']['output']>;
  introduction_text?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  referenceConnection?: Maybe<LoftPopupPageReferenceConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type LoftPopupPageReferenceConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LoftPopupPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftPopupPageReferenceConnection = {
  __typename?: 'LoftPopupPageReferenceConnection';
  edges?: Maybe<Array<Maybe<LoftPopupPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftPopupPageReferenceEdge = {
  __typename?: 'LoftPopupPageReferenceEdge';
  node?: Maybe<LoftPopupPageReferenceNode>;
};

export type LoftPopupPageReferenceNode = LoftContentbelts;

export type LoftPopupPageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_contentbelts?: InputMaybe<LoftContentbeltsWhere>;
};

export type LoftPopupPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftPopupPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftPopupPageWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  destination?: InputMaybe<Scalars['String']['input']>;
  destination_exists?: InputMaybe<Scalars['Boolean']['input']>;
  destination_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  destination_ne?: InputMaybe<Scalars['String']['input']>;
  destination_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introduction_text?: InputMaybe<Scalars['String']['input']>;
  introduction_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  introduction_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  introduction_text_ne?: InputMaybe<Scalars['String']['input']>;
  introduction_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  origin?: InputMaybe<Scalars['String']['input']>;
  origin_exists?: InputMaybe<Scalars['Boolean']['input']>;
  origin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  origin_ne?: InputMaybe<Scalars['String']['input']>;
  origin_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  reference?: InputMaybe<LoftPopupPageReferenceWhere>;
  reference_count?: InputMaybe<Scalars['Int']['input']>;
  reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftProjects = {
  __typename?: 'LoftProjects';
  description?: Maybe<Scalars['String']['output']>;
  display_title?: Maybe<Scalars['Boolean']['output']>;
  hub_and_spoke_model?: Maybe<Array<Maybe<LoftHubandspoke>>>;
  linear_route_model?: Maybe<Array<Maybe<LoftLinearRoute>>>;
  link_text?: Maybe<Scalars['String']['output']>;
  pop_up_page_referenceConnection?: Maybe<LoftProjectsPopUpPageReferenceConnection>;
  project_type?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LoftProjectsGlobe = {
  __typename?: 'LoftProjectsGlobe';
  description?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  projectsConnection?: Maybe<LoftProjectsGlobeProjectsConnection>;
  projects_display?: Maybe<Scalars['String']['output']>;
  show_filter?: Maybe<Scalars['Boolean']['output']>;
  show_globe?: Maybe<Scalars['Boolean']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type LoftProjectsGlobeProjectsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum LoftProjectsGlobeOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftProjectsGlobeProjectsConnection = {
  __typename?: 'LoftProjectsGlobeProjectsConnection';
  edges?: Maybe<Array<Maybe<LoftProjectsGlobeProjectsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftProjectsGlobeProjectsEdge = {
  __typename?: 'LoftProjectsGlobeProjectsEdge';
  node?: Maybe<LoftProjectsGlobeProjectsNode>;
};

export type LoftProjectsGlobeProjectsNode = LoftProjects;

export type LoftProjectsGlobeProjectsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_projects?: InputMaybe<LoftProjectsWhere>;
};

export type LoftProjectsGlobeWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftProjectsGlobeWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftProjectsGlobeWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_ne?: InputMaybe<Scalars['String']['input']>;
  description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects?: InputMaybe<LoftProjectsGlobeProjectsWhere>;
  projects_count?: InputMaybe<Scalars['Int']['input']>;
  projects_display?: InputMaybe<Scalars['String']['input']>;
  projects_display_exists?: InputMaybe<Scalars['Boolean']['input']>;
  projects_display_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects_display_ne?: InputMaybe<Scalars['String']['input']>;
  projects_display_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projects_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  show_filter?: InputMaybe<Scalars['Boolean']['input']>;
  show_filter_exists?: InputMaybe<Scalars['Boolean']['input']>;
  show_filter_ne?: InputMaybe<Scalars['Boolean']['input']>;
  show_globe?: InputMaybe<Scalars['Boolean']['input']>;
  show_globe_exists?: InputMaybe<Scalars['Boolean']['input']>;
  show_globe_ne?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum LoftProjectsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftProjectsPopUpPageReferenceConnection = {
  __typename?: 'LoftProjectsPopUpPageReferenceConnection';
  edges?: Maybe<Array<Maybe<LoftProjectsPopUpPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftProjectsPopUpPageReferenceEdge = {
  __typename?: 'LoftProjectsPopUpPageReferenceEdge';
  node?: Maybe<LoftProjectsPopUpPageReferenceNode>;
};

export type LoftProjectsPopUpPageReferenceNode = LoftPopupPage;

export type LoftProjectsPopUpPageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_popup_page?: InputMaybe<LoftPopupPageWhere>;
};

export type LoftProjectsWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftProjectsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftProjectsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_ne?: InputMaybe<Scalars['String']['input']>;
  description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_ne?: InputMaybe<Scalars['Boolean']['input']>;
  hub_and_spoke_model?: InputMaybe<LoftHubandspokeWhere>;
  hub_and_spoke_model_exists?: InputMaybe<Scalars['Boolean']['input']>;
  linear_route_model?: InputMaybe<LoftLinearRouteWhere>;
  linear_route_model_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_text?: InputMaybe<Scalars['String']['input']>;
  link_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_text_ne?: InputMaybe<Scalars['String']['input']>;
  link_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pop_up_page_reference?: InputMaybe<LoftProjectsPopUpPageReferenceWhere>;
  pop_up_page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  project_type?: InputMaybe<Scalars['String']['input']>;
  project_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  project_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  project_type_ne?: InputMaybe<Scalars['String']['input']>;
  project_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftQuoteSalesforce = {
  __typename?: 'LoftQuoteSalesforce';
  form_id?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum LoftQuoteSalesforceOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftQuoteSalesforceWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftQuoteSalesforceWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftQuoteSalesforceWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  form_id?: InputMaybe<Scalars['String']['input']>;
  form_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  form_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  form_id_ne?: InputMaybe<Scalars['String']['input']>;
  form_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftTeamMember = {
  __typename?: 'LoftTeamMember';
  bio?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_and_last_name?: Maybe<Scalars['String']['output']>;
  job_title?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  portrait_imageConnection?: Maybe<SysAssetConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum LoftTeamMemberOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftTeamMemberWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftTeamMemberWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftTeamMemberWhere>>>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bio_exists?: InputMaybe<Scalars['Boolean']['input']>;
  bio_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bio_ne?: InputMaybe<Scalars['String']['input']>;
  bio_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  department?: InputMaybe<Scalars['String']['input']>;
  department_exists?: InputMaybe<Scalars['Boolean']['input']>;
  department_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  department_ne?: InputMaybe<Scalars['String']['input']>;
  department_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_exists?: InputMaybe<Scalars['Boolean']['input']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_ne?: InputMaybe<Scalars['String']['input']>;
  email_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first_and_last_name?: InputMaybe<Scalars['String']['input']>;
  first_and_last_name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  first_and_last_name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first_and_last_name_ne?: InputMaybe<Scalars['String']['input']>;
  first_and_last_name_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  job_title?: InputMaybe<Scalars['String']['input']>;
  job_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  job_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  job_title_ne?: InputMaybe<Scalars['String']['input']>;
  job_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_number_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_number_ne?: InputMaybe<Scalars['String']['input']>;
  phone_number_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  portrait_image?: InputMaybe<SysAssetWhere>;
  portrait_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftTiles = {
  __typename?: 'LoftTiles';
  display_title?: Maybe<Scalars['String']['output']>;
  fleet_tile_elementsConnection?: Maybe<LoftTilesFleetTileElementsConnection>;
  general_tile_elements?: Maybe<LoftTilesGeneralTileElements>;
  system?: Maybe<EntrySystemField>;
  team_tile_listConnection?: Maybe<LoftTilesTeamTileListConnection>;
  tile_type?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};


export type LoftTilesFleet_Tile_ElementsConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type LoftTilesTeam_Tile_ListConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type LoftTilesFleetTileElementsConnection = {
  __typename?: 'LoftTilesFleetTileElementsConnection';
  edges?: Maybe<Array<Maybe<LoftTilesFleetTileElementsEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftTilesFleetTileElementsEdge = {
  __typename?: 'LoftTilesFleetTileElementsEdge';
  node?: Maybe<LoftTilesFleetTileElementsNode>;
};

export type LoftTilesFleetTileElementsNode = LoftAircraftData;

export type LoftTilesFleetTileElementsWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_aircraft_data?: InputMaybe<LoftAircraftDataWhere>;
};

export type LoftTilesGeneralTileElements = {
  __typename?: 'LoftTilesGeneralTileElements';
  link?: Maybe<LoftTilesGeneralTileElementsLink>;
  main_text?: Maybe<Scalars['String']['output']>;
  tile_image?: Maybe<LoftImage>;
};

export type LoftTilesGeneralTileElementsLink = {
  __typename?: 'LoftTilesGeneralTileElementsLink';
  link_name?: Maybe<Scalars['String']['output']>;
  link_to_pageConnection?: Maybe<LoftTilesGeneralTileElementsLinkLinkToPageConnection>;
};

export type LoftTilesGeneralTileElementsLinkLinkToPageConnection = {
  __typename?: 'LoftTilesGeneralTileElementsLinkLinkToPageConnection';
  edges?: Maybe<Array<Maybe<LoftTilesGeneralTileElementsLinkLinkToPageEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftTilesGeneralTileElementsLinkLinkToPageEdge = {
  __typename?: 'LoftTilesGeneralTileElementsLinkLinkToPageEdge';
  node?: Maybe<LoftTilesGeneralTileElementsLinkLinkToPageNode>;
};

export type LoftTilesGeneralTileElementsLinkLinkToPageNode = LoftPage;

export type LoftTilesGeneralTileElementsLinkLinkToPageWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_page?: InputMaybe<LoftPageWhere>;
};

export type LoftTilesGeneralTileElementsLinkWhere = {
  link_name?: InputMaybe<Scalars['String']['input']>;
  link_name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_name_ne?: InputMaybe<Scalars['String']['input']>;
  link_name_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_to_page?: InputMaybe<LoftTilesGeneralTileElementsLinkLinkToPageWhere>;
  link_to_page_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoftTilesGeneralTileElementsWhere = {
  link?: InputMaybe<LoftTilesGeneralTileElementsLinkWhere>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tile_image?: InputMaybe<LoftImageWhere>;
  tile_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LoftTilesOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftTilesTeamTileListConnection = {
  __typename?: 'LoftTilesTeamTileListConnection';
  edges?: Maybe<Array<Maybe<LoftTilesTeamTileListEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LoftTilesTeamTileListEdge = {
  __typename?: 'LoftTilesTeamTileListEdge';
  node?: Maybe<LoftTilesTeamTileListNode>;
};

export type LoftTilesTeamTileListNode = LoftTeamMember;

export type LoftTilesTeamTileListWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  loft_team_member?: InputMaybe<LoftTeamMemberWhere>;
};

export type LoftTilesWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftTilesWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftTilesWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fleet_tile_elements?: InputMaybe<LoftTilesFleetTileElementsWhere>;
  fleet_tile_elements_count?: InputMaybe<Scalars['Int']['input']>;
  fleet_tile_elements_exists?: InputMaybe<Scalars['Boolean']['input']>;
  general_tile_elements?: InputMaybe<LoftTilesGeneralTileElementsWhere>;
  general_tile_elements_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  team_tile_list?: InputMaybe<LoftTilesTeamTileListWhere>;
  team_tile_list_count?: InputMaybe<Scalars['Int']['input']>;
  team_tile_list_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tile_type?: InputMaybe<Scalars['String']['input']>;
  tile_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tile_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tile_type_ne?: InputMaybe<Scalars['String']['input']>;
  tile_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LoftTranslationStrings = {
  __typename?: 'LoftTranslationStrings';
  string_value?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum LoftTranslationStringsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type LoftTranslationStringsWhere = {
  AND?: InputMaybe<Array<InputMaybe<LoftTranslationStringsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<LoftTranslationStringsWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  string_value?: InputMaybe<Scalars['String']['input']>;
  string_value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  string_value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_value_ne?: InputMaybe<Scalars['String']['input']>;
  string_value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type News = {
  __typename?: 'News';
  author?: Maybe<Scalars['String']['output']>;
  author_referenceConnection?: Maybe<NewsAuthorReferenceConnection>;
  category?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  image?: Maybe<Images>;
  images_for_contentbelt?: Maybe<Images>;
  main_text?: Maybe<Scalars['String']['output']>;
  meta_tags?: Maybe<NewsMetaTags>;
  modular_blocks?: Maybe<Array<Maybe<NewsModularBlocks>>>;
  no_index?: Maybe<Scalars['String']['output']>;
  publish_date?: Maybe<Scalars['DateTime']['output']>;
  reading_time?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  tagplay?: Maybe<NewsTagplay>;
  title?: Maybe<Scalars['String']['output']>;
  translation_status?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type NewsAuthor_ReferenceConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsAuthorReferenceConnection = {
  __typename?: 'NewsAuthorReferenceConnection';
  edges?: Maybe<Array<Maybe<NewsAuthorReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type NewsAuthorReferenceEdge = {
  __typename?: 'NewsAuthorReferenceEdge';
  node?: Maybe<NewsAuthorReferenceNode>;
};

export type NewsAuthorReferenceNode = Author;

export type NewsAuthorReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  author?: InputMaybe<AuthorWhere>;
};

export type NewsMetaTags = {
  __typename?: 'NewsMetaTags';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type NewsMetaTagsWhere = {
  meta_description?: InputMaybe<Scalars['String']['input']>;
  meta_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_description_ne?: InputMaybe<Scalars['String']['input']>;
  meta_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title?: InputMaybe<Scalars['String']['input']>;
  meta_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  meta_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_title_ne?: InputMaybe<Scalars['String']['input']>;
  meta_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NewsModularBlocks = NewsModularBlocksChapter | NewsModularBlocksPhotoEssay;

export type NewsModularBlocksChapter = {
  __typename?: 'NewsModularBlocksChapter';
  chapter?: Maybe<NewsModularBlocksChapterBlock>;
};

export type NewsModularBlocksChapterBlock = {
  __typename?: 'NewsModularBlocksChapterBlock';
  image?: Maybe<NewsModularBlocksChapterBlockImage>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NewsModularBlocksChapterBlockImage = {
  __typename?: 'NewsModularBlocksChapterBlockImage';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  photographerConnection?: Maybe<NewsModularBlocksChapterBlockImagePhotographerConnection>;
};


export type NewsModularBlocksChapterBlockImagePhotographerConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsModularBlocksChapterBlockImagePhotographerConnection = {
  __typename?: 'NewsModularBlocksChapterBlockImagePhotographerConnection';
  edges?: Maybe<Array<Maybe<NewsModularBlocksChapterBlockImagePhotographerEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type NewsModularBlocksChapterBlockImagePhotographerEdge = {
  __typename?: 'NewsModularBlocksChapterBlockImagePhotographerEdge';
  node?: Maybe<NewsModularBlocksChapterBlockImagePhotographerNode>;
};

export type NewsModularBlocksChapterBlockImagePhotographerNode = Photographer;

export type NewsModularBlocksChapterBlockImagePhotographerWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  photographer?: InputMaybe<PhotographerWhere>;
};

export type NewsModularBlocksChapterBlockImageWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  photographer?: InputMaybe<NewsModularBlocksChapterBlockImagePhotographerWhere>;
  photographer_count?: InputMaybe<Scalars['Int']['input']>;
  photographer_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsModularBlocksChapterBlockWhere = {
  image?: InputMaybe<NewsModularBlocksChapterBlockImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NewsModularBlocksPhotoEssay = {
  __typename?: 'NewsModularBlocksPhotoEssay';
  photo_essay?: Maybe<NewsModularBlocksPhotoEssayBlock>;
};

export type NewsModularBlocksPhotoEssayBlock = {
  __typename?: 'NewsModularBlocksPhotoEssayBlock';
  caption?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Maybe<NewsModularBlocksPhotoEssayBlockImages>>>;
  photographerConnection?: Maybe<NewsModularBlocksPhotoEssayBlockPhotographerConnection>;
};


export type NewsModularBlocksPhotoEssayBlockPhotographerConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsModularBlocksPhotoEssayBlockImages = {
  __typename?: 'NewsModularBlocksPhotoEssayBlockImages';
  caption?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
};

export type NewsModularBlocksPhotoEssayBlockImagesWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsModularBlocksPhotoEssayBlockPhotographerConnection = {
  __typename?: 'NewsModularBlocksPhotoEssayBlockPhotographerConnection';
  edges?: Maybe<Array<Maybe<NewsModularBlocksPhotoEssayBlockPhotographerEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type NewsModularBlocksPhotoEssayBlockPhotographerEdge = {
  __typename?: 'NewsModularBlocksPhotoEssayBlockPhotographerEdge';
  node?: Maybe<NewsModularBlocksPhotoEssayBlockPhotographerNode>;
};

export type NewsModularBlocksPhotoEssayBlockPhotographerNode = Photographer;

export type NewsModularBlocksPhotoEssayBlockPhotographerWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  photographer?: InputMaybe<PhotographerWhere>;
};

export type NewsModularBlocksPhotoEssayBlockWhere = {
  caption?: InputMaybe<Scalars['String']['input']>;
  caption_exists?: InputMaybe<Scalars['Boolean']['input']>;
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  caption_ne?: InputMaybe<Scalars['String']['input']>;
  caption_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  images?: InputMaybe<NewsModularBlocksPhotoEssayBlockImagesWhere>;
  images_exists?: InputMaybe<Scalars['Boolean']['input']>;
  photographer?: InputMaybe<NewsModularBlocksPhotoEssayBlockPhotographerWhere>;
  photographer_count?: InputMaybe<Scalars['Int']['input']>;
  photographer_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NewsModularBlocksWhere = {
  chapter?: InputMaybe<NewsModularBlocksChapterBlockWhere>;
  chapter_exists?: InputMaybe<Scalars['Boolean']['input']>;
  photo_essay?: InputMaybe<NewsModularBlocksPhotoEssayBlockWhere>;
  photo_essay_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum NewsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type NewsTagplay = {
  __typename?: 'NewsTagplay';
  feed_id?: Maybe<Scalars['String']['output']>;
  hashtag?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
};

export type NewsTagplayWhere = {
  feed_id?: InputMaybe<Scalars['String']['input']>;
  feed_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  feed_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feed_id_ne?: InputMaybe<Scalars['String']['input']>;
  feed_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hashtag?: InputMaybe<Scalars['String']['input']>;
  hashtag_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hashtag_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hashtag_ne?: InputMaybe<Scalars['String']['input']>;
  hashtag_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  project_id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  project_id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  project_id_ne?: InputMaybe<Scalars['String']['input']>;
  project_id_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type NewsWhere = {
  AND?: InputMaybe<Array<InputMaybe<NewsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<NewsWhere>>>;
  author?: InputMaybe<Scalars['String']['input']>;
  author_exists?: InputMaybe<Scalars['Boolean']['input']>;
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  author_ne?: InputMaybe<Scalars['String']['input']>;
  author_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  author_reference?: InputMaybe<NewsAuthorReferenceWhere>;
  author_reference_count?: InputMaybe<Scalars['Int']['input']>;
  author_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  category_exists?: InputMaybe<Scalars['Boolean']['input']>;
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category_ne?: InputMaybe<Scalars['String']['input']>;
  category_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hidden_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hidden_ne?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<ImagesWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  images_for_contentbelt?: InputMaybe<ImagesWhere>;
  images_for_contentbelt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  main_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_text_ne?: InputMaybe<Scalars['String']['input']>;
  main_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  meta_tags?: InputMaybe<NewsMetaTagsWhere>;
  meta_tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
  modular_blocks?: InputMaybe<NewsModularBlocksWhere>;
  modular_blocks_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_index?: InputMaybe<Scalars['String']['input']>;
  no_index_exists?: InputMaybe<Scalars['Boolean']['input']>;
  no_index_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  no_index_ne?: InputMaybe<Scalars['String']['input']>;
  no_index_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_date?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_date_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publish_date_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_ne?: InputMaybe<Scalars['DateTime']['input']>;
  publish_date_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  reading_time?: InputMaybe<Scalars['String']['input']>;
  reading_time_exists?: InputMaybe<Scalars['Boolean']['input']>;
  reading_time_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reading_time_ne?: InputMaybe<Scalars['String']['input']>;
  reading_time_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  short_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  short_description_ne?: InputMaybe<Scalars['String']['input']>;
  short_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_exists?: InputMaybe<Scalars['Boolean']['input']>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_ne?: InputMaybe<Scalars['String']['input']>;
  slug_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tagplay?: InputMaybe<NewsTagplayWhere>;
  tagplay_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translation_status?: InputMaybe<Scalars['String']['input']>;
  translation_status_exists?: InputMaybe<Scalars['Boolean']['input']>;
  translation_status_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translation_status_ne?: InputMaybe<Scalars['String']['input']>;
  translation_status_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type Photographer = {
  __typename?: 'Photographer';
  biography?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Images>;
  instagram?: Maybe<Scalars['String']['output']>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum PhotographerOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type PhotographerWhere = {
  AND?: InputMaybe<Array<InputMaybe<PhotographerWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<PhotographerWhere>>>;
  biography?: InputMaybe<Scalars['String']['input']>;
  biography_exists?: InputMaybe<Scalars['Boolean']['input']>;
  biography_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  biography_ne?: InputMaybe<Scalars['String']['input']>;
  biography_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<ImagesWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  instagram_exists?: InputMaybe<Scalars['Boolean']['input']>;
  instagram_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  instagram_ne?: InputMaybe<Scalars['String']['input']>;
  instagram_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type Query = {
  __typename?: 'Query';
  CARGO_Salesforce_form?: Maybe<CargoSalesforceForm>;
  CARGO_frontpage?: Maybe<CargoFrontpage>;
  CARGO_main_page?: Maybe<CargoMainPage>;
  CARGO_text_image_section?: Maybe<CargoTextImageSection>;
  CARGO_tile?: Maybe<CargoTile>;
  CARGO_tile_section?: Maybe<CargoTileSection>;
  a_sample_5?: Maybe<ASample5>;
  air_artists?: Maybe<AirArtists>;
  air_frontpage?: Maybe<AirFrontpage>;
  air_genericcontent?: Maybe<AirGenericcontent>;
  air_line_up?: Maybe<AirLineUp>;
  air_translation_string?: Maybe<AirTranslationString>;
  air_video_item?: Maybe<AirVideoItem>;
  all_CARGO_Salesforce_form?: Maybe<AllCargoSalesforceForm>;
  all_CARGO_frontpage?: Maybe<AllCargoFrontpage>;
  all_CARGO_main_page?: Maybe<AllCargoMainPage>;
  all_CARGO_text_image_section?: Maybe<AllCargoTextImageSection>;
  all_CARGO_tile?: Maybe<AllCargoTile>;
  all_CARGO_tile_section?: Maybe<AllCargoTileSection>;
  all_a_sample_5?: Maybe<AllASample5>;
  all_air_artists?: Maybe<AllAirArtists>;
  all_air_frontpage?: Maybe<AllAirFrontpage>;
  all_air_genericcontent?: Maybe<AllAirGenericcontent>;
  all_air_line_up?: Maybe<AllAirLineUp>;
  all_air_translation_string?: Maybe<AllAirTranslationString>;
  all_air_video_item?: Maybe<AllAirVideoItem>;
  all_assets?: Maybe<AllSysAsset>;
  all_author?: Maybe<AllAuthor>;
  all_boiler_frontpage?: Maybe<AllBoilerFrontpage>;
  all_boiler_translation_string?: Maybe<AllBoilerTranslationString>;
  all_cargo_aircraft_specifications?: Maybe<AllCargoAircraftSpecifications>;
  all_cargo_airport_network?: Maybe<AllCargoAirportNetwork>;
  all_cargo_collapsible_section?: Maybe<AllCargoCollapsibleSection>;
  all_cargo_flight_schedule?: Maybe<AllCargoFlightSchedule>;
  all_cargo_flight_status?: Maybe<AllCargoFlightStatus>;
  all_cargo_footer?: Maybe<AllCargoFooter>;
  all_cargo_image_section?: Maybe<AllCargoImageSection>;
  all_cargo_image_section_item?: Maybe<AllCargoImageSectionItem>;
  all_cargo_kal_test?: Maybe<AllCargoKalTest>;
  all_cargo_login_screen?: Maybe<AllCargoLoginScreen>;
  all_cargo_navigation_menu_item?: Maybe<AllCargoNavigationMenuItem>;
  all_cargo_pet_calculator?: Maybe<AllCargoPetCalculator>;
  all_cargo_quick_search?: Maybe<AllCargoQuickSearch>;
  all_cargo_redirects?: Maybe<AllCargoRedirects>;
  all_cargo_rich_text?: Maybe<AllCargoRichText>;
  all_cargo_search_screen?: Maybe<AllCargoSearchScreen>;
  all_cargo_size_limitations?: Maybe<AllCargoSizeLimitations>;
  all_cargo_staff_item?: Maybe<AllCargoStaffItem>;
  all_cargo_staff_section?: Maybe<AllCargoStaffSection>;
  all_cargo_test_type?: Maybe<AllCargoTestType>;
  all_cargo_top_navigation?: Maybe<AllCargoTopNavigation>;
  all_cargo_track_trace?: Maybe<AllCargoTrackTrace>;
  all_cargo_translation_string?: Maybe<AllCargoTranslationString>;
  all_cargo_translation_string_groups?: Maybe<AllCargoTranslationStringGroups>;
  all_feria_content_section?: Maybe<AllFeriaContentSection>;
  all_feria_footer?: Maybe<AllFeriaFooter>;
  all_feria_main_navigation?: Maybe<AllFeriaMainNavigation>;
  all_feria_page?: Maybe<AllFeriaPage>;
  all_feria_text_image_section?: Maybe<AllFeriaTextImageSection>;
  all_feria_translation_group?: Maybe<AllFeriaTranslationGroup>;
  all_group_annualreport?: Maybe<AllGroupAnnualreport>;
  all_group_calendarentries?: Maybe<AllGroupCalendarentries>;
  all_group_financialcalendar?: Maybe<AllGroupFinancialcalendar>;
  all_group_footer?: Maybe<AllGroupFooter>;
  all_group_frontpage?: Maybe<AllGroupFrontpage>;
  all_group_generic_content?: Maybe<AllGroupGenericContent>;
  all_group_icons?: Maybe<AllGroupIcons>;
  all_group_iframe?: Maybe<AllGroupIframe>;
  all_group_logos?: Maybe<AllGroupLogos>;
  all_group_management?: Maybe<AllGroupManagement>;
  all_group_menu?: Maybe<AllGroupMenu>;
  all_group_news?: Maybe<AllGroupNews>;
  all_group_newsroom?: Maybe<AllGroupNewsroom>;
  all_group_page?: Maybe<AllGroupPage>;
  all_group_quarterlyresults?: Maybe<AllGroupQuarterlyresults>;
  all_group_stream_page?: Maybe<AllGroupStreamPage>;
  all_group_tabcontent?: Maybe<AllGroupTabcontent>;
  all_group_table?: Maybe<AllGroupTable>;
  all_group_translation_strings?: Maybe<AllGroupTranslationStrings>;
  all_group_translation_strings_all?: Maybe<AllGroupTranslationStringsAll>;
  all_icon?: Maybe<AllIcon>;
  all_ijc_airports?: Maybe<AllIjcAirports>;
  all_ijc_frontpage?: Maybe<AllIjcFrontpage>;
  all_ijc_genericcontent?: Maybe<AllIjcGenericcontent>;
  all_ijc_kitchen?: Maybe<AllIjcKitchen>;
  all_ijc_menu?: Maybe<AllIjcMenu>;
  all_ijc_staff?: Maybe<AllIjcStaff>;
  all_ljc_page?: Maybe<AllLjcPage>;
  all_loft_aircraft_data?: Maybe<AllLoftAircraftData>;
  all_loft_contentbelts?: Maybe<AllLoftContentbelts>;
  all_loft_footer?: Maybe<AllLoftFooter>;
  all_loft_menu?: Maybe<AllLoftMenu>;
  all_loft_navigation_menu_item?: Maybe<AllLoftNavigationMenuItem>;
  all_loft_page?: Maybe<AllLoftPage>;
  all_loft_popup_page?: Maybe<AllLoftPopupPage>;
  all_loft_projects?: Maybe<AllLoftProjects>;
  all_loft_projects_globe?: Maybe<AllLoftProjectsGlobe>;
  all_loft_quote_salesforce?: Maybe<AllLoftQuoteSalesforce>;
  all_loft_team_member?: Maybe<AllLoftTeamMember>;
  all_loft_tiles?: Maybe<AllLoftTiles>;
  all_loft_translation_strings?: Maybe<AllLoftTranslationStrings>;
  all_news?: Maybe<AllNews>;
  all_photographer?: Maybe<AllPhotographer>;
  all_testing_page?: Maybe<AllTestingPage>;
  all_vildar_card_section?: Maybe<AllVildarCardSection>;
  all_vildar_content_posts?: Maybe<AllVildarContentPosts>;
  all_vildar_navigation?: Maybe<AllVildarNavigation>;
  all_vildar_page?: Maybe<AllVildarPage>;
  all_vildar_translation_group?: Maybe<AllVildarTranslationGroup>;
  all_win_gridsection?: Maybe<AllWinGridsection>;
  all_win_page?: Maybe<AllWinPage>;
  all_win_translation_group?: Maybe<AllWinTranslationGroup>;
  assets?: Maybe<SysAsset>;
  author?: Maybe<Author>;
  boiler_frontpage?: Maybe<BoilerFrontpage>;
  boiler_translation_string?: Maybe<BoilerTranslationString>;
  cargo_aircraft_specifications?: Maybe<CargoAircraftSpecifications>;
  cargo_airport_network?: Maybe<CargoAirportNetwork>;
  cargo_collapsible_section?: Maybe<CargoCollapsibleSection>;
  cargo_flight_schedule?: Maybe<CargoFlightSchedule>;
  cargo_flight_status?: Maybe<CargoFlightStatus>;
  cargo_footer?: Maybe<CargoFooter>;
  cargo_image_section?: Maybe<CargoImageSection>;
  cargo_image_section_item?: Maybe<CargoImageSectionItem>;
  cargo_kal_test?: Maybe<CargoKalTest>;
  cargo_login_screen?: Maybe<CargoLoginScreen>;
  cargo_navigation_menu_item?: Maybe<CargoNavigationMenuItem>;
  cargo_pet_calculator?: Maybe<CargoPetCalculator>;
  cargo_quick_search?: Maybe<CargoQuickSearch>;
  cargo_redirects?: Maybe<CargoRedirects>;
  cargo_rich_text?: Maybe<CargoRichText>;
  cargo_search_screen?: Maybe<CargoSearchScreen>;
  cargo_size_limitations?: Maybe<CargoSizeLimitations>;
  cargo_staff_item?: Maybe<CargoStaffItem>;
  cargo_staff_section?: Maybe<CargoStaffSection>;
  cargo_test_type?: Maybe<CargoTestType>;
  cargo_top_navigation?: Maybe<CargoTopNavigation>;
  cargo_track_trace?: Maybe<CargoTrackTrace>;
  cargo_translation_string?: Maybe<CargoTranslationString>;
  cargo_translation_string_groups?: Maybe<CargoTranslationStringGroups>;
  feria_content_section?: Maybe<FeriaContentSection>;
  feria_footer?: Maybe<FeriaFooter>;
  feria_main_navigation?: Maybe<FeriaMainNavigation>;
  feria_page?: Maybe<FeriaPage>;
  feria_text_image_section?: Maybe<FeriaTextImageSection>;
  feria_translation_group?: Maybe<FeriaTranslationGroup>;
  group_annualreport?: Maybe<GroupAnnualreport>;
  group_calendarentries?: Maybe<GroupCalendarentries>;
  group_financialcalendar?: Maybe<GroupFinancialcalendar>;
  group_footer?: Maybe<GroupFooter>;
  group_frontpage?: Maybe<GroupFrontpage>;
  group_generic_content?: Maybe<GroupGenericContent>;
  group_icons?: Maybe<GroupIcons>;
  group_iframe?: Maybe<GroupIframe>;
  group_logos?: Maybe<GroupLogos>;
  group_management?: Maybe<GroupManagement>;
  group_menu?: Maybe<GroupMenu>;
  group_news?: Maybe<GroupNews>;
  group_newsroom?: Maybe<GroupNewsroom>;
  group_page?: Maybe<GroupPage>;
  group_quarterlyresults?: Maybe<GroupQuarterlyresults>;
  group_stream_page?: Maybe<GroupStreamPage>;
  group_tabcontent?: Maybe<GroupTabcontent>;
  group_table?: Maybe<GroupTable>;
  group_translation_strings?: Maybe<GroupTranslationStrings>;
  group_translation_strings_all?: Maybe<GroupTranslationStringsAll>;
  icon?: Maybe<Icon>;
  ijc_airports?: Maybe<IjcAirports>;
  ijc_frontpage?: Maybe<IjcFrontpage>;
  ijc_genericcontent?: Maybe<IjcGenericcontent>;
  ijc_kitchen?: Maybe<IjcKitchen>;
  ijc_menu?: Maybe<IjcMenu>;
  ijc_staff?: Maybe<IjcStaff>;
  ljc_page?: Maybe<LjcPage>;
  loft_aircraft_data?: Maybe<LoftAircraftData>;
  loft_contentbelts?: Maybe<LoftContentbelts>;
  loft_footer?: Maybe<LoftFooter>;
  loft_menu?: Maybe<LoftMenu>;
  loft_navigation_menu_item?: Maybe<LoftNavigationMenuItem>;
  loft_page?: Maybe<LoftPage>;
  loft_popup_page?: Maybe<LoftPopupPage>;
  loft_projects?: Maybe<LoftProjects>;
  loft_projects_globe?: Maybe<LoftProjectsGlobe>;
  loft_quote_salesforce?: Maybe<LoftQuoteSalesforce>;
  loft_team_member?: Maybe<LoftTeamMember>;
  loft_tiles?: Maybe<LoftTiles>;
  loft_translation_strings?: Maybe<LoftTranslationStrings>;
  news?: Maybe<News>;
  photographer?: Maybe<Photographer>;
  testing_page?: Maybe<TestingPage>;
  vildar_card_section?: Maybe<VildarCardSection>;
  vildar_content_posts?: Maybe<VildarContentPosts>;
  vildar_navigation?: Maybe<VildarNavigation>;
  vildar_page?: Maybe<VildarPage>;
  vildar_translation_group?: Maybe<VildarTranslationGroup>;
  win_gridsection?: Maybe<WinGridsection>;
  win_page?: Maybe<WinPage>;
  win_translation_group?: Maybe<WinTranslationGroup>;
};


export type QueryCargo_Salesforce_FormArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Main_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Text_Image_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_TileArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Tile_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryA_Sample_5Args = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryAir_ArtistsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryAir_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryAir_GenericcontentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryAir_Line_UpArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryAir_Translation_StringArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryAir_Video_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryAll_Cargo_Salesforce_FormArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoSalesforceFormOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoSalesforceFormWhere>;
};


export type QueryAll_Cargo_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoFrontpageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoFrontpageWhere>;
};


export type QueryAll_Cargo_Main_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoMainPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoMainPageWhere>;
};


export type QueryAll_Cargo_Text_Image_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTextImageSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTextImageSectionWhere>;
};


export type QueryAll_Cargo_TileArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTileOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTileWhere>;
};


export type QueryAll_Cargo_Tile_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTileSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTileSectionWhere>;
};


export type QueryAll_A_Sample_5Args = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<ASample5OrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ASample5Where>;
};


export type QueryAll_Air_ArtistsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<AirArtistsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirArtistsWhere>;
};


export type QueryAll_Air_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<AirFrontpageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirFrontpageWhere>;
};


export type QueryAll_Air_GenericcontentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<AirGenericcontentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirGenericcontentWhere>;
};


export type QueryAll_Air_Line_UpArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<AirLineUpOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirLineUpWhere>;
};


export type QueryAll_Air_Translation_StringArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<AirTranslationStringOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirTranslationStringWhere>;
};


export type QueryAll_Air_Video_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<AirVideoItemOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirVideoItemWhere>;
};


export type QueryAll_AssetsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order_by?: InputMaybe<Array<InputMaybe<SysAssetOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SysAssetWhere>;
};


export type QueryAll_AuthorArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<AuthorOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AuthorWhere>;
};


export type QueryAll_Boiler_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<BoilerFrontpageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoilerFrontpageWhere>;
};


export type QueryAll_Boiler_Translation_StringArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<BoilerTranslationStringOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoilerTranslationStringWhere>;
};


export type QueryAll_Cargo_Aircraft_SpecificationsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoAircraftSpecificationsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoAircraftSpecificationsWhere>;
};


export type QueryAll_Cargo_Airport_NetworkArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoAirportNetworkOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoAirportNetworkWhere>;
};


export type QueryAll_Cargo_Collapsible_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoCollapsibleSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoCollapsibleSectionWhere>;
};


export type QueryAll_Cargo_Flight_ScheduleArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoFlightScheduleOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoFlightScheduleWhere>;
};


export type QueryAll_Cargo_Flight_StatusArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoFlightStatusOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoFlightStatusWhere>;
};


export type QueryAll_Cargo_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoFooterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoFooterWhere>;
};


export type QueryAll_Cargo_Image_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoImageSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoImageSectionWhere>;
};


export type QueryAll_Cargo_Image_Section_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoImageSectionItemOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoImageSectionItemWhere>;
};


export type QueryAll_Cargo_Kal_TestArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoKalTestOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoKalTestWhere>;
};


export type QueryAll_Cargo_Login_ScreenArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoLoginScreenOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoLoginScreenWhere>;
};


export type QueryAll_Cargo_Navigation_Menu_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoNavigationMenuItemOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoNavigationMenuItemWhere>;
};


export type QueryAll_Cargo_Pet_CalculatorArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoPetCalculatorOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoPetCalculatorWhere>;
};


export type QueryAll_Cargo_Quick_SearchArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoQuickSearchOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoQuickSearchWhere>;
};


export type QueryAll_Cargo_RedirectsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoRedirectsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoRedirectsWhere>;
};


export type QueryAll_Cargo_Rich_TextArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoRichTextOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoRichTextWhere>;
};


export type QueryAll_Cargo_Search_ScreenArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoSearchScreenOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoSearchScreenWhere>;
};


export type QueryAll_Cargo_Size_LimitationsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoSizeLimitationsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoSizeLimitationsWhere>;
};


export type QueryAll_Cargo_Staff_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoStaffItemOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoStaffItemWhere>;
};


export type QueryAll_Cargo_Staff_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoStaffSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoStaffSectionWhere>;
};


export type QueryAll_Cargo_Test_TypeArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTestTypeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTestTypeWhere>;
};


export type QueryAll_Cargo_Top_NavigationArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTopNavigationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTopNavigationWhere>;
};


export type QueryAll_Cargo_Track_TraceArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTrackTraceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTrackTraceWhere>;
};


export type QueryAll_Cargo_Translation_StringArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTranslationStringOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTranslationStringWhere>;
};


export type QueryAll_Cargo_Translation_String_GroupsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<CargoTranslationStringGroupsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CargoTranslationStringGroupsWhere>;
};


export type QueryAll_Feria_Content_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<FeriaContentSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeriaContentSectionWhere>;
};


export type QueryAll_Feria_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<FeriaFooterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeriaFooterWhere>;
};


export type QueryAll_Feria_Main_NavigationArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<FeriaMainNavigationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeriaMainNavigationWhere>;
};


export type QueryAll_Feria_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<FeriaPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeriaPageWhere>;
};


export type QueryAll_Feria_Text_Image_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<FeriaTextImageSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeriaTextImageSectionWhere>;
};


export type QueryAll_Feria_Translation_GroupArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<FeriaTranslationGroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeriaTranslationGroupWhere>;
};


export type QueryAll_Group_AnnualreportArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupAnnualreportOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupAnnualreportWhere>;
};


export type QueryAll_Group_CalendarentriesArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupCalendarentriesOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupCalendarentriesWhere>;
};


export type QueryAll_Group_FinancialcalendarArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupFinancialcalendarOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupFinancialcalendarWhere>;
};


export type QueryAll_Group_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupFooterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupFooterWhere>;
};


export type QueryAll_Group_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupFrontpageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupFrontpageWhere>;
};


export type QueryAll_Group_Generic_ContentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupGenericContentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupGenericContentWhere>;
};


export type QueryAll_Group_IconsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupIconsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupIconsWhere>;
};


export type QueryAll_Group_IframeArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupIframeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupIframeWhere>;
};


export type QueryAll_Group_LogosArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupLogosOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupLogosWhere>;
};


export type QueryAll_Group_ManagementArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupManagementOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupManagementWhere>;
};


export type QueryAll_Group_MenuArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupMenuOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupMenuWhere>;
};


export type QueryAll_Group_NewsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupNewsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupNewsWhere>;
};


export type QueryAll_Group_NewsroomArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupNewsroomOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupNewsroomWhere>;
};


export type QueryAll_Group_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupPageWhere>;
};


export type QueryAll_Group_QuarterlyresultsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupQuarterlyresultsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupQuarterlyresultsWhere>;
};


export type QueryAll_Group_Stream_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupStreamPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupStreamPageWhere>;
};


export type QueryAll_Group_TabcontentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupTabcontentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupTabcontentWhere>;
};


export type QueryAll_Group_TableArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupTableOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupTableWhere>;
};


export type QueryAll_Group_Translation_StringsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupTranslationStringsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupTranslationStringsWhere>;
};


export type QueryAll_Group_Translation_Strings_AllArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<GroupTranslationStringsAllOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GroupTranslationStringsAllWhere>;
};


export type QueryAll_IconArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<IconOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IconWhere>;
};


export type QueryAll_Ijc_AirportsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<IjcAirportsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IjcAirportsWhere>;
};


export type QueryAll_Ijc_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<IjcFrontpageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IjcFrontpageWhere>;
};


export type QueryAll_Ijc_GenericcontentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<IjcGenericcontentOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IjcGenericcontentWhere>;
};


export type QueryAll_Ijc_KitchenArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<IjcKitchenOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IjcKitchenWhere>;
};


export type QueryAll_Ijc_MenuArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<IjcMenuOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IjcMenuWhere>;
};


export type QueryAll_Ijc_StaffArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<IjcStaffOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IjcStaffWhere>;
};


export type QueryAll_Ljc_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LjcPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LjcPageWhere>;
};


export type QueryAll_Loft_Aircraft_DataArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftAircraftDataOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftAircraftDataWhere>;
};


export type QueryAll_Loft_ContentbeltsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftContentbeltsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftContentbeltsWhere>;
};


export type QueryAll_Loft_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftFooterOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftFooterWhere>;
};


export type QueryAll_Loft_MenuArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftMenuOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftMenuWhere>;
};


export type QueryAll_Loft_Navigation_Menu_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftNavigationMenuItemOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftNavigationMenuItemWhere>;
};


export type QueryAll_Loft_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftPageWhere>;
};


export type QueryAll_Loft_Popup_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftPopupPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftPopupPageWhere>;
};


export type QueryAll_Loft_ProjectsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftProjectsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftProjectsWhere>;
};


export type QueryAll_Loft_Projects_GlobeArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftProjectsGlobeOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftProjectsGlobeWhere>;
};


export type QueryAll_Loft_Quote_SalesforceArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftQuoteSalesforceOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftQuoteSalesforceWhere>;
};


export type QueryAll_Loft_Team_MemberArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftTeamMemberOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftTeamMemberWhere>;
};


export type QueryAll_Loft_TilesArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftTilesOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftTilesWhere>;
};


export type QueryAll_Loft_Translation_StringsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<LoftTranslationStringsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LoftTranslationStringsWhere>;
};


export type QueryAll_NewsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<NewsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NewsWhere>;
};


export type QueryAll_PhotographerArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<PhotographerOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotographerWhere>;
};


export type QueryAll_Testing_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<TestingPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TestingPageWhere>;
};


export type QueryAll_Vildar_Card_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<VildarCardSectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VildarCardSectionWhere>;
};


export type QueryAll_Vildar_Content_PostsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<VildarContentPostsOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VildarContentPostsWhere>;
};


export type QueryAll_Vildar_NavigationArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<VildarNavigationOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VildarNavigationWhere>;
};


export type QueryAll_Vildar_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<VildarPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VildarPageWhere>;
};


export type QueryAll_Vildar_Translation_GroupArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<VildarTranslationGroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VildarTranslationGroupWhere>;
};


export type QueryAll_Win_GridsectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<WinGridsectionOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WinGridsectionWhere>;
};


export type QueryAll_Win_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<WinPageOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WinPageWhere>;
};


export type QueryAll_Win_Translation_GroupArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: Scalars['String']['input'];
  order_by?: InputMaybe<Array<InputMaybe<WinTranslationGroupOrderBy>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WinTranslationGroupWhere>;
};


export type QueryAssetsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  uid: Scalars['String']['input'];
};


export type QueryAuthorArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryBoiler_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryBoiler_Translation_StringArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Aircraft_SpecificationsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Airport_NetworkArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Collapsible_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Flight_ScheduleArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Flight_StatusArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Image_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Image_Section_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Kal_TestArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Login_ScreenArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Navigation_Menu_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Pet_CalculatorArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Quick_SearchArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_RedirectsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Rich_TextArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Search_ScreenArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Size_LimitationsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Staff_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Staff_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Test_TypeArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Top_NavigationArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Track_TraceArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Translation_StringArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryCargo_Translation_String_GroupsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryFeria_Content_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryFeria_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryFeria_Main_NavigationArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryFeria_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryFeria_Text_Image_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryFeria_Translation_GroupArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_AnnualreportArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_CalendarentriesArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_FinancialcalendarArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_Generic_ContentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_IconsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_IframeArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_LogosArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_ManagementArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_MenuArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_NewsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_NewsroomArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_QuarterlyresultsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_Stream_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_TabcontentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_TableArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_Translation_StringsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryGroup_Translation_Strings_AllArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryIconArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryIjc_AirportsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryIjc_FrontpageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryIjc_GenericcontentArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryIjc_KitchenArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryIjc_MenuArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryIjc_StaffArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLjc_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_Aircraft_DataArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_ContentbeltsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_FooterArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_MenuArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_Navigation_Menu_ItemArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_Popup_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_ProjectsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_Projects_GlobeArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_Quote_SalesforceArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_Team_MemberArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_TilesArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryLoft_Translation_StringsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryNewsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryPhotographerArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryTesting_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryVildar_Card_SectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryVildar_Content_PostsArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryVildar_NavigationArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryVildar_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryVildar_Translation_GroupArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryWin_GridsectionArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryWin_PageArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};


export type QueryWin_Translation_GroupArgs = {
  fallback_locale?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};

export type Seo = {
  __typename?: 'Seo';
  description?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SeoWhere = {
  description?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_ne?: InputMaybe<Scalars['String']['input']>;
  description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SysAsset = {
  __typename?: 'SysAsset';
  content_type?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dimension?: Maybe<SysAssetDimension>;
  file_size?: Maybe<Scalars['Int']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  permanent_url?: Maybe<Scalars['String']['output']>;
  system?: Maybe<SysAssetSystemField>;
  title?: Maybe<Scalars['String']['output']>;
  unique_identifier?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type SysAssetUrlArgs = {
  transform?: InputMaybe<SysAssetTransformUrl>;
};

/** WEBP images are usually lower in size and have good quality. */
export enum SysAssetAutoValues {
  Webp = 'WEBP'
}

export type SysAssetConnection = {
  __typename?: 'SysAssetConnection';
  edges?: Maybe<Array<Maybe<SysAssetEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SysAssetDimension = {
  __typename?: 'SysAssetDimension';
  height?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type SysAssetDimensionWhere = {
  height?: InputMaybe<Scalars['Int']['input']>;
  height_exists?: InputMaybe<Scalars['Boolean']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_ne?: InputMaybe<Scalars['Int']['input']>;
  height_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_ne?: InputMaybe<Scalars['Int']['input']>;
  width_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum SysAssetDisableValues {
  /** UPSCALE is always enabled, in which the image is upscaled if the output image (by specifying the width or height) is bigger than the source image */
  Upscale = 'UPSCALE'
}

/** This parameter allows an image to be downloaded or rendered on page */
export enum SysAssetDispositionValues {
  /** Allows to download an image */
  Attachment = 'ATTACHMENT',
  /** Allows an image to be rendered on page */
  Inline = 'INLINE'
}

export type SysAssetEdge = {
  __typename?: 'SysAssetEdge';
  node?: Maybe<SysAsset>;
};

export enum SysAssetFitValues {
  Bounds = 'BOUNDS',
  Crop = 'CROP'
}

export enum SysAssetImageFormats {
  /** Convert an image to GIF format */
  Gif = 'GIF',
  /** Convert an image to JPEG format */
  Jpg = 'JPG',
  /** A Progressive JPEG is an image file created using a compression method that displays higher detail in progression */
  Pjpg = 'PJPG',
  /** Convert an image to PNG format */
  Png = 'PNG',
  /** WEBP images are usually lower in size and have good quality */
  Webp = 'WEBP',
  /** WEBP Lossless format */
  Webpll = 'WEBPLL',
  /** WEBP Lossy format */
  Webply = 'WEBPLY'
}

export enum SysAssetOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export enum SysAssetOrientValues {
  /** Flip image horizontally and vertically */
  Both = 'BOTH',
  /** Set image to default */
  Default = 'DEFAULT',
  /** Flip image horizontally */
  Horizontally = 'HORIZONTALLY',
  /** Flip image horizontally and then rotate 90 degrees towards left */
  Rotate90Left = 'ROTATE90LEFT',
  /** Rotate image 90 degrees towards right */
  Rotate90Right = 'ROTATE90RIGHT',
  /** Flip image vertically */
  Vertically = 'VERTICALLY'
}

/** The overlay_align parameter allows you to put one image on top of another */
export enum SysAssetOverlayAlignValues {
  /** Align the overlay image to the bottom of the actual image */
  Bottom = 'BOTTOM',
  /** Align the overlay image to the center (horizontally) of the actual image */
  Center = 'CENTER',
  /** Align the overlay image to the left of the actual image */
  Left = 'LEFT',
  /** Align the overlay image to the middle (vertically) of the actual image */
  Middle = 'MIDDLE',
  /** Align the overlay image to the right of the actual image */
  Right = 'RIGHT',
  /** Align the overlay image to the top of the actual image */
  Top = 'TOP'
}

export enum SysAssetOverlayRepeatValues {
  /** Horizontal and vertical repetition */
  Both = 'BOTH',
  /** Horizontal repetition */
  X = 'X',
  /** Vertical repetition */
  Y = 'Y'
}

export type SysAssetSystemField = {
  __typename?: 'SysAssetSystemField';
  branch?: Maybe<Scalars['String']['output']>;
  content_type_uid?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  extensionConnection?: Maybe<SysExtensionConnection>;
  publish_details?: Maybe<SystemPublishDetails>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type SysAssetSystemFieldExtensionConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SysAssetTransformUrl = {
  /** When the auto parameter is set to webp, it enables WebP image support. WebP images have higher compression rate with minimum loss of quality. */
  auto?: InputMaybe<SysAssetAutoValues>;
  /** The bg-color parameter lets you set a backgroud color for the given image. This is useful when applying padding or for replacing the transparent pixels of an image */
  bg_color?: InputMaybe<Scalars['String']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
  /** The disable parameter disables the functionality that is enabled by default */
  disable?: InputMaybe<SysAssetDisableValues>;
  /** The disposition parameter lets you allow image to download or render.  */
  disposition?: InputMaybe<SysAssetDispositionValues>;
  /** The dpr parameter lets you deliver images with appropriate size to devices that come with a defined device pixel ratio. The device pixel ratio of any device determines the screen resolution that its CSS would interpret */
  dpr?: InputMaybe<Scalars['String']['input']>;
  /** Fit parameter enables you to fit the given image properly within the specified height and width */
  fit?: InputMaybe<SysAssetFitValues>;
  /** Format parameter lets you converts a given image from one format to another */
  format?: InputMaybe<SysAssetImageFormats>;
  height?: InputMaybe<Scalars['String']['input']>;
  /** The orient parameter lets you control the cardinal orientation of the given image */
  orient?: InputMaybe<SysAssetOrientValues>;
  overlay?: InputMaybe<Scalars['String']['input']>;
  overlay_align?: InputMaybe<SysAssetOverlayAlignValues>;
  /** The value for this parameter can be set in pixels or percentage. For pixel value, use any whole number between 1 and 8192. For percentage value, use any decimal number between 0.0 and 0.99. When height is defined in percentage, it relative to the output image */
  overlay_height?: InputMaybe<Scalars['String']['input']>;
  /** The overlay_repeat parameter lets you define how the overlay image will be repeated on the given image */
  overlay_repeat?: InputMaybe<SysAssetOverlayRepeatValues>;
  /** The value for this parameter can be set in pixels or percentage. For pixel value, use any whole number between 1 and 8192. For percentage value, use any decimal number between 0.0 and 0.99. When width is defined in percentage, it is relative to the output image */
  overlay_width?: InputMaybe<Scalars['String']['input']>;
  /** This parameter lets you add extra pixels to the edges of an image. You can specify values for top, right, bottom, and left padding for an image */
  pad?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  trim?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['String']['input']>;
};

export type SysAssetWhere = {
  AND?: InputMaybe<Array<InputMaybe<SysAssetWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<SysAssetWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_ne?: InputMaybe<Scalars['String']['input']>;
  description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dimension?: InputMaybe<SysAssetDimensionWhere>;
  dimension_exists?: InputMaybe<Scalars['Boolean']['input']>;
  file_size?: InputMaybe<Scalars['Int']['input']>;
  file_size_exists?: InputMaybe<Scalars['Boolean']['input']>;
  file_size_gt?: InputMaybe<Scalars['Int']['input']>;
  file_size_gte?: InputMaybe<Scalars['Int']['input']>;
  file_size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  file_size_lt?: InputMaybe<Scalars['Int']['input']>;
  file_size_lte?: InputMaybe<Scalars['Int']['input']>;
  file_size_ne?: InputMaybe<Scalars['Int']['input']>;
  file_size_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filename_exists?: InputMaybe<Scalars['Boolean']['input']>;
  filename_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename_ne?: InputMaybe<Scalars['String']['input']>;
  filename_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Scalars['String']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SysExtensionConnection = {
  __typename?: 'SysExtensionConnection';
  edges?: Maybe<Array<Maybe<SysExtensionEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SysExtensionEdge = {
  __typename?: 'SysExtensionEdge';
  node?: Maybe<SysMetadata>;
};

export type SysMetadata = {
  __typename?: 'SysMetadata';
  extension_uid?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
};

export type SystemPublishDetails = {
  __typename?: 'SystemPublishDetails';
  environment?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

export type SystemPublishDetailsWhere = {
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  time_gt?: InputMaybe<Scalars['DateTime']['input']>;
  time_gte?: InputMaybe<Scalars['DateTime']['input']>;
  time_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  time_lt?: InputMaybe<Scalars['DateTime']['input']>;
  time_lte?: InputMaybe<Scalars['DateTime']['input']>;
  time_ne?: InputMaybe<Scalars['DateTime']['input']>;
  time_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  user?: InputMaybe<Scalars['String']['input']>;
  user_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  user_ne?: InputMaybe<Scalars['String']['input']>;
  user_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TestingPage = {
  __typename?: 'TestingPage';
  sub_routes?: Maybe<TestingPageSubRoutes>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum TestingPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type TestingPageSubRoutes = {
  __typename?: 'TestingPageSubRoutes';
  level_1?: Maybe<Scalars['String']['output']>;
  level_2?: Maybe<Scalars['String']['output']>;
};

export type TestingPageSubRoutesWhere = {
  level_1?: InputMaybe<Scalars['String']['input']>;
  level_1_exists?: InputMaybe<Scalars['Boolean']['input']>;
  level_1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level_1_ne?: InputMaybe<Scalars['String']['input']>;
  level_1_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level_2?: InputMaybe<Scalars['String']['input']>;
  level_2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  level_2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level_2_ne?: InputMaybe<Scalars['String']['input']>;
  level_2_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TestingPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<TestingPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<TestingPageWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  sub_routes?: InputMaybe<TestingPageSubRoutesWhere>;
  sub_routes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type VildarCardSection = {
  __typename?: 'VildarCardSection';
  card_template?: Maybe<Scalars['String']['output']>;
  display_title?: Maybe<Scalars['String']['output']>;
  link?: Maybe<VildarCardSectionLink>;
  pagination?: Maybe<Scalars['Boolean']['output']>;
  paragraph?: Maybe<Scalars['String']['output']>;
  persons?: Maybe<Array<Maybe<VildarCardSectionPersons>>>;
  post_limit?: Maybe<Scalars['Float']['output']>;
  sidebar?: Maybe<Scalars['Boolean']['output']>;
  simple_boxes?: Maybe<Array<Maybe<VildarCardSectionSimpleBoxes>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type VildarCardSectionLink = {
  __typename?: 'VildarCardSectionLink';
  external_url?: Maybe<Link>;
  internal_urlConnection?: Maybe<VildarCardSectionLinkInternalUrlConnection>;
  label?: Maybe<Scalars['String']['output']>;
};

export type VildarCardSectionLinkInternalUrlConnection = {
  __typename?: 'VildarCardSectionLinkInternalUrlConnection';
  edges?: Maybe<Array<Maybe<VildarCardSectionLinkInternalUrlEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarCardSectionLinkInternalUrlEdge = {
  __typename?: 'VildarCardSectionLinkInternalUrlEdge';
  node?: Maybe<VildarCardSectionLinkInternalUrlNode>;
};

export type VildarCardSectionLinkInternalUrlNode = VildarPage;

export type VildarCardSectionLinkInternalUrlWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_page?: InputMaybe<VildarPageWhere>;
};

export type VildarCardSectionLinkWhere = {
  external_url?: InputMaybe<LinkWhere>;
  external_url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_url?: InputMaybe<VildarCardSectionLinkInternalUrlWhere>;
  internal_url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum VildarCardSectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type VildarCardSectionPersons = VildarCardSectionPersonsPerson;

export type VildarCardSectionPersonsPerson = {
  __typename?: 'VildarCardSectionPersonsPerson';
  person?: Maybe<VildarCardSectionPersonsPersonBlock>;
};

export type VildarCardSectionPersonsPersonBlock = {
  __typename?: 'VildarCardSectionPersonsPersonBlock';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<VildarCardSectionPersonsPersonBlockImage>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VildarCardSectionPersonsPersonBlockImage = {
  __typename?: 'VildarCardSectionPersonsPersonBlockImage';
  alt_text?: Maybe<Scalars['String']['output']>;
  assetConnection?: Maybe<SysAssetConnection>;
  crop_resize?: Maybe<Scalars['String']['output']>;
  gravity?: Maybe<Scalars['String']['output']>;
};

export type VildarCardSectionPersonsPersonBlockImageWhere = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  alt_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  alt_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  alt_text_ne?: InputMaybe<Scalars['String']['input']>;
  alt_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  asset?: InputMaybe<SysAssetWhere>;
  asset_exists?: InputMaybe<Scalars['Boolean']['input']>;
  crop_resize?: InputMaybe<Scalars['String']['input']>;
  crop_resize_exists?: InputMaybe<Scalars['Boolean']['input']>;
  crop_resize_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crop_resize_ne?: InputMaybe<Scalars['String']['input']>;
  crop_resize_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity?: InputMaybe<Scalars['String']['input']>;
  gravity_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gravity_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity_ne?: InputMaybe<Scalars['String']['input']>;
  gravity_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarCardSectionPersonsPersonBlockWhere = {
  description?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_ne?: InputMaybe<Scalars['String']['input']>;
  description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<VildarCardSectionPersonsPersonBlockImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_exists?: InputMaybe<Scalars['Boolean']['input']>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name_ne?: InputMaybe<Scalars['String']['input']>;
  name_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarCardSectionPersonsWhere = {
  person?: InputMaybe<VildarCardSectionPersonsPersonBlockWhere>;
  person_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarCardSectionSimpleBoxes = VildarCardSectionSimpleBoxesBox;

export type VildarCardSectionSimpleBoxesBox = {
  __typename?: 'VildarCardSectionSimpleBoxesBox';
  box?: Maybe<VildarCardSectionSimpleBoxesBoxBlock>;
};

export type VildarCardSectionSimpleBoxesBoxBlock = {
  __typename?: 'VildarCardSectionSimpleBoxesBoxBlock';
  background_color?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type VildarCardSectionSimpleBoxesBoxBlockWhere = {
  background_color?: InputMaybe<Scalars['String']['input']>;
  background_color_exists?: InputMaybe<Scalars['Boolean']['input']>;
  background_color_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  background_color_ne?: InputMaybe<Scalars['String']['input']>;
  background_color_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarCardSectionSimpleBoxesWhere = {
  box?: InputMaybe<VildarCardSectionSimpleBoxesBoxBlockWhere>;
  box_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarCardSectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<VildarCardSectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<VildarCardSectionWhere>>>;
  card_template?: InputMaybe<Scalars['String']['input']>;
  card_template_exists?: InputMaybe<Scalars['Boolean']['input']>;
  card_template_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  card_template_ne?: InputMaybe<Scalars['String']['input']>;
  card_template_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link?: InputMaybe<VildarCardSectionLinkWhere>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pagination?: InputMaybe<Scalars['Boolean']['input']>;
  pagination_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pagination_ne?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraph_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph_ne?: InputMaybe<Scalars['String']['input']>;
  paragraph_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  persons?: InputMaybe<VildarCardSectionPersonsWhere>;
  persons_exists?: InputMaybe<Scalars['Boolean']['input']>;
  post_limit?: InputMaybe<Scalars['Float']['input']>;
  post_limit_exists?: InputMaybe<Scalars['Boolean']['input']>;
  post_limit_gt?: InputMaybe<Scalars['Float']['input']>;
  post_limit_gte?: InputMaybe<Scalars['Float']['input']>;
  post_limit_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  post_limit_lt?: InputMaybe<Scalars['Float']['input']>;
  post_limit_lte?: InputMaybe<Scalars['Float']['input']>;
  post_limit_ne?: InputMaybe<Scalars['Float']['input']>;
  post_limit_nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  sidebar?: InputMaybe<Scalars['Boolean']['input']>;
  sidebar_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sidebar_ne?: InputMaybe<Scalars['Boolean']['input']>;
  simple_boxes?: InputMaybe<VildarCardSectionSimpleBoxesWhere>;
  simple_boxes_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type VildarContentPosts = {
  __typename?: 'VildarContentPosts';
  card_template_override?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  display_title?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Array<Maybe<Image>>>;
  link_label?: Maybe<Scalars['String']['output']>;
  next_post?: Maybe<Scalars['String']['output']>;
  short_description?: Maybe<Scalars['String']['output']>;
  show_date?: Maybe<Scalars['Boolean']['output']>;
  system?: Maybe<EntrySystemField>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum VildarContentPostsOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type VildarContentPostsWhere = {
  AND?: InputMaybe<Array<InputMaybe<VildarContentPostsWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<VildarContentPostsWhere>>>;
  card_template_override?: InputMaybe<Scalars['String']['input']>;
  card_template_override_exists?: InputMaybe<Scalars['Boolean']['input']>;
  card_template_override_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  card_template_override_ne?: InputMaybe<Scalars['String']['input']>;
  card_template_override_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  date_exists?: InputMaybe<Scalars['Boolean']['input']>;
  date_gt?: InputMaybe<Scalars['DateTime']['input']>;
  date_gte?: InputMaybe<Scalars['DateTime']['input']>;
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  date_lt?: InputMaybe<Scalars['DateTime']['input']>;
  date_lte?: InputMaybe<Scalars['DateTime']['input']>;
  date_ne?: InputMaybe<Scalars['DateTime']['input']>;
  date_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  display_title?: InputMaybe<Scalars['String']['input']>;
  display_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  display_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display_title_ne?: InputMaybe<Scalars['String']['input']>;
  display_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<ImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_label?: InputMaybe<Scalars['String']['input']>;
  link_label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_label_ne?: InputMaybe<Scalars['String']['input']>;
  link_label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  next_post?: InputMaybe<Scalars['String']['input']>;
  next_post_exists?: InputMaybe<Scalars['Boolean']['input']>;
  next_post_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  next_post_ne?: InputMaybe<Scalars['String']['input']>;
  next_post_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  short_description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  short_description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  short_description_ne?: InputMaybe<Scalars['String']['input']>;
  short_description_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  show_date?: InputMaybe<Scalars['Boolean']['input']>;
  show_date_exists?: InputMaybe<Scalars['Boolean']['input']>;
  show_date_ne?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_ne?: InputMaybe<Scalars['String']['input']>;
  text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_ne?: InputMaybe<Scalars['String']['input']>;
  type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type VildarNavigation = {
  __typename?: 'VildarNavigation';
  buttons?: Maybe<Array<Maybe<VildarNavigationButtons>>>;
  footer_specific?: Maybe<VildarNavigationFooterSpecific>;
  links?: Maybe<Array<Maybe<VildarNavigationLinks>>>;
  logo?: Maybe<Image>;
  pagesConnection?: Maybe<VildarNavigationPagesConnection>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};


export type VildarNavigationPagesConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type VildarNavigationButtons = VildarNavigationButtonsCta | VildarNavigationButtonsLanguagePicker;

export type VildarNavigationButtonsCta = {
  __typename?: 'VildarNavigationButtonsCta';
  cta?: Maybe<VildarNavigationButtonsCtaBlock>;
};

export type VildarNavigationButtonsCtaBlock = {
  __typename?: 'VildarNavigationButtonsCtaBlock';
  button_style?: Maybe<Scalars['String']['output']>;
  external_link?: Maybe<Link>;
  internal_linkConnection?: Maybe<VildarNavigationButtonsCtaBlockInternalLinkConnection>;
  label?: Maybe<Scalars['String']['output']>;
};

export type VildarNavigationButtonsCtaBlockInternalLinkConnection = {
  __typename?: 'VildarNavigationButtonsCtaBlockInternalLinkConnection';
  edges?: Maybe<Array<Maybe<VildarNavigationButtonsCtaBlockInternalLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarNavigationButtonsCtaBlockInternalLinkEdge = {
  __typename?: 'VildarNavigationButtonsCtaBlockInternalLinkEdge';
  node?: Maybe<VildarNavigationButtonsCtaBlockInternalLinkNode>;
};

export type VildarNavigationButtonsCtaBlockInternalLinkNode = VildarPage;

export type VildarNavigationButtonsCtaBlockInternalLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_page?: InputMaybe<VildarPageWhere>;
};

export type VildarNavigationButtonsCtaBlockWhere = {
  button_style?: InputMaybe<Scalars['String']['input']>;
  button_style_exists?: InputMaybe<Scalars['Boolean']['input']>;
  button_style_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  button_style_ne?: InputMaybe<Scalars['String']['input']>;
  button_style_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  external_link?: InputMaybe<LinkWhere>;
  external_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_link?: InputMaybe<VildarNavigationButtonsCtaBlockInternalLinkWhere>;
  internal_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarNavigationButtonsLanguagePicker = {
  __typename?: 'VildarNavigationButtonsLanguagePicker';
  language_picker?: Maybe<VildarNavigationButtonsLanguagePickerBlock>;
};

export type VildarNavigationButtonsLanguagePickerBlock = {
  __typename?: 'VildarNavigationButtonsLanguagePickerBlock';
  languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type VildarNavigationButtonsLanguagePickerBlockWhere = {
  languages?: InputMaybe<Scalars['String']['input']>;
  languages_exists?: InputMaybe<Scalars['Boolean']['input']>;
  languages_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  languages_ne?: InputMaybe<Scalars['String']['input']>;
  languages_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarNavigationButtonsWhere = {
  cta?: InputMaybe<VildarNavigationButtonsCtaBlockWhere>;
  cta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  language_picker?: InputMaybe<VildarNavigationButtonsLanguagePickerBlockWhere>;
  language_picker_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarNavigationFooterSpecific = {
  __typename?: 'VildarNavigationFooterSpecific';
  email?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  icelandair_logo?: Maybe<Image>;
  impressum?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type VildarNavigationFooterSpecificWhere = {
  email?: InputMaybe<Scalars['String']['input']>;
  email_exists?: InputMaybe<Scalars['Boolean']['input']>;
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email_ne?: InputMaybe<Scalars['String']['input']>;
  email_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  icelandair_logo?: InputMaybe<ImageWhere>;
  icelandair_logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  impressum?: InputMaybe<Scalars['String']['input']>;
  impressum_exists?: InputMaybe<Scalars['Boolean']['input']>;
  impressum_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  impressum_ne?: InputMaybe<Scalars['String']['input']>;
  impressum_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_exists?: InputMaybe<Scalars['Boolean']['input']>;
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone_ne?: InputMaybe<Scalars['String']['input']>;
  phone_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarNavigationLinks = VildarNavigationLinksCta;

export type VildarNavigationLinksCta = {
  __typename?: 'VildarNavigationLinksCta';
  cta?: Maybe<VildarNavigationLinksCtaBlock>;
};

export type VildarNavigationLinksCtaBlock = {
  __typename?: 'VildarNavigationLinksCtaBlock';
  external_link?: Maybe<Link>;
  internal_linkConnection?: Maybe<VildarNavigationLinksCtaBlockInternalLinkConnection>;
  label?: Maybe<Scalars['String']['output']>;
};

export type VildarNavigationLinksCtaBlockInternalLinkConnection = {
  __typename?: 'VildarNavigationLinksCtaBlockInternalLinkConnection';
  edges?: Maybe<Array<Maybe<VildarNavigationLinksCtaBlockInternalLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarNavigationLinksCtaBlockInternalLinkEdge = {
  __typename?: 'VildarNavigationLinksCtaBlockInternalLinkEdge';
  node?: Maybe<VildarNavigationLinksCtaBlockInternalLinkNode>;
};

export type VildarNavigationLinksCtaBlockInternalLinkNode = VildarPage;

export type VildarNavigationLinksCtaBlockInternalLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_page?: InputMaybe<VildarPageWhere>;
};

export type VildarNavigationLinksCtaBlockWhere = {
  external_link?: InputMaybe<LinkWhere>;
  external_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_link?: InputMaybe<VildarNavigationLinksCtaBlockInternalLinkWhere>;
  internal_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarNavigationLinksWhere = {
  cta?: InputMaybe<VildarNavigationLinksCtaBlockWhere>;
  cta_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VildarNavigationOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type VildarNavigationPagesConnection = {
  __typename?: 'VildarNavigationPagesConnection';
  edges?: Maybe<Array<Maybe<VildarNavigationPagesEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarNavigationPagesEdge = {
  __typename?: 'VildarNavigationPagesEdge';
  node?: Maybe<VildarNavigationPagesNode>;
};

export type VildarNavigationPagesNode = VildarPage;

export type VildarNavigationPagesWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_page?: InputMaybe<VildarPageWhere>;
};

export type VildarNavigationWhere = {
  AND?: InputMaybe<Array<InputMaybe<VildarNavigationWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<VildarNavigationWhere>>>;
  buttons?: InputMaybe<VildarNavigationButtonsWhere>;
  buttons_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  footer_specific?: InputMaybe<VildarNavigationFooterSpecificWhere>;
  footer_specific_exists?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<VildarNavigationLinksWhere>;
  links_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo?: InputMaybe<ImageWhere>;
  logo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  pages?: InputMaybe<VildarNavigationPagesWhere>;
  pages_count?: InputMaybe<Scalars['Int']['input']>;
  pages_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type VildarPage = {
  __typename?: 'VildarPage';
  components?: Maybe<Array<Maybe<VildarPageComponents>>>;
  hidden?: Maybe<Scalars['String']['output']>;
  navigation_title?: Maybe<Scalars['String']['output']>;
  seo?: Maybe<Seo>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  translation_strings?: Maybe<VildarTranslationGroups>;
  url?: Maybe<Scalars['String']['output']>;
};

export type VildarPageComponents = VildarPageComponentsBanner | VildarPageComponentsCards | VildarPageComponentsContentSection | VildarPageComponentsDocuments | VildarPageComponentsForms | VildarPageComponentsTabsSection;

export type VildarPageComponentsBanner = {
  __typename?: 'VildarPageComponentsBanner';
  banner?: Maybe<VildarPageComponentsBannerBlock>;
};

export type VildarPageComponentsBannerBlock = {
  __typename?: 'VildarPageComponentsBannerBlock';
  cta?: Maybe<VildarPageComponentsBannerBlockCta>;
  expand_image?: Maybe<Scalars['Boolean']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  image?: Maybe<VildarPageComponentsBannerBlockImage>;
  layout?: Maybe<Scalars['String']['output']>;
  main_banner?: Maybe<Scalars['Boolean']['output']>;
  mobile_layout?: Maybe<Scalars['String']['output']>;
  object_fit?: Maybe<Scalars['String']['output']>;
  paragraph?: Maybe<Scalars['String']['output']>;
  rounded?: Maybe<Scalars['Boolean']['output']>;
  styled?: Maybe<Scalars['Boolean']['output']>;
};

export type VildarPageComponentsBannerBlockCta = {
  __typename?: 'VildarPageComponentsBannerBlockCta';
  external_link?: Maybe<Link>;
  internal_linkConnection?: Maybe<VildarPageComponentsBannerBlockCtaInternalLinkConnection>;
  label?: Maybe<Scalars['String']['output']>;
};

export type VildarPageComponentsBannerBlockCtaInternalLinkConnection = {
  __typename?: 'VildarPageComponentsBannerBlockCtaInternalLinkConnection';
  edges?: Maybe<Array<Maybe<VildarPageComponentsBannerBlockCtaInternalLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarPageComponentsBannerBlockCtaInternalLinkEdge = {
  __typename?: 'VildarPageComponentsBannerBlockCtaInternalLinkEdge';
  node?: Maybe<VildarPageComponentsBannerBlockCtaInternalLinkNode>;
};

export type VildarPageComponentsBannerBlockCtaInternalLinkNode = VildarPage;

export type VildarPageComponentsBannerBlockCtaInternalLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_page?: InputMaybe<VildarPageWhere>;
};

export type VildarPageComponentsBannerBlockCtaWhere = {
  external_link?: InputMaybe<LinkWhere>;
  external_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_link?: InputMaybe<VildarPageComponentsBannerBlockCtaInternalLinkWhere>;
  internal_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarPageComponentsBannerBlockImage = {
  __typename?: 'VildarPageComponentsBannerBlockImage';
  alt_text?: Maybe<Scalars['String']['output']>;
  crop_resize?: Maybe<Scalars['String']['output']>;
  gravity?: Maybe<Scalars['String']['output']>;
  imgConnection?: Maybe<SysAssetConnection>;
};

export type VildarPageComponentsBannerBlockImageWhere = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  alt_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  alt_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  alt_text_ne?: InputMaybe<Scalars['String']['input']>;
  alt_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crop_resize?: InputMaybe<Scalars['String']['input']>;
  crop_resize_exists?: InputMaybe<Scalars['Boolean']['input']>;
  crop_resize_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crop_resize_ne?: InputMaybe<Scalars['String']['input']>;
  crop_resize_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity?: InputMaybe<Scalars['String']['input']>;
  gravity_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gravity_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity_ne?: InputMaybe<Scalars['String']['input']>;
  gravity_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  img?: InputMaybe<SysAssetWhere>;
  img_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarPageComponentsBannerBlockWhere = {
  cta?: InputMaybe<VildarPageComponentsBannerBlockCtaWhere>;
  cta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  expand_image?: InputMaybe<Scalars['Boolean']['input']>;
  expand_image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  expand_image_ne?: InputMaybe<Scalars['Boolean']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<VildarPageComponentsBannerBlockImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  layout_exists?: InputMaybe<Scalars['Boolean']['input']>;
  layout_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  layout_ne?: InputMaybe<Scalars['String']['input']>;
  layout_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  main_banner?: InputMaybe<Scalars['Boolean']['input']>;
  main_banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  main_banner_ne?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_layout?: InputMaybe<Scalars['String']['input']>;
  mobile_layout_exists?: InputMaybe<Scalars['Boolean']['input']>;
  mobile_layout_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mobile_layout_ne?: InputMaybe<Scalars['String']['input']>;
  mobile_layout_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  object_fit?: InputMaybe<Scalars['String']['input']>;
  object_fit_exists?: InputMaybe<Scalars['Boolean']['input']>;
  object_fit_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  object_fit_ne?: InputMaybe<Scalars['String']['input']>;
  object_fit_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraph_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph_ne?: InputMaybe<Scalars['String']['input']>;
  paragraph_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rounded?: InputMaybe<Scalars['Boolean']['input']>;
  rounded_exists?: InputMaybe<Scalars['Boolean']['input']>;
  rounded_ne?: InputMaybe<Scalars['Boolean']['input']>;
  styled?: InputMaybe<Scalars['Boolean']['input']>;
  styled_exists?: InputMaybe<Scalars['Boolean']['input']>;
  styled_ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarPageComponentsCards = {
  __typename?: 'VildarPageComponentsCards';
  cards?: Maybe<VildarPageComponentsCardsBlock>;
};

export type VildarPageComponentsCardsBlock = {
  __typename?: 'VildarPageComponentsCardsBlock';
  card_sectionConnection?: Maybe<VildarPageComponentsCardsBlockCardSectionConnection>;
};

export type VildarPageComponentsCardsBlockCardSectionConnection = {
  __typename?: 'VildarPageComponentsCardsBlockCardSectionConnection';
  edges?: Maybe<Array<Maybe<VildarPageComponentsCardsBlockCardSectionEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarPageComponentsCardsBlockCardSectionEdge = {
  __typename?: 'VildarPageComponentsCardsBlockCardSectionEdge';
  node?: Maybe<VildarPageComponentsCardsBlockCardSectionNode>;
};

export type VildarPageComponentsCardsBlockCardSectionNode = VildarCardSection;

export type VildarPageComponentsCardsBlockCardSectionWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_card_section?: InputMaybe<VildarCardSectionWhere>;
};

export type VildarPageComponentsCardsBlockWhere = {
  card_section?: InputMaybe<VildarPageComponentsCardsBlockCardSectionWhere>;
  card_section_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarPageComponentsContentSection = {
  __typename?: 'VildarPageComponentsContentSection';
  content_section?: Maybe<VildarPageComponentsContentSectionBlock>;
};

export type VildarPageComponentsContentSectionBlock = {
  __typename?: 'VildarPageComponentsContentSectionBlock';
  content_postConnection?: Maybe<VildarPageComponentsContentSectionBlockContentPostConnection>;
  cta?: Maybe<VildarPageComponentsContentSectionBlockCta>;
  headline?: Maybe<Scalars['String']['output']>;
  image?: Maybe<VildarPageComponentsContentSectionBlockImage>;
  layout?: Maybe<Scalars['String']['output']>;
  paragraph?: Maybe<Scalars['String']['output']>;
  variant?: Maybe<Scalars['String']['output']>;
  video?: Maybe<VildarPageComponentsContentSectionBlockVideo>;
};

export type VildarPageComponentsContentSectionBlockContentPostConnection = {
  __typename?: 'VildarPageComponentsContentSectionBlockContentPostConnection';
  edges?: Maybe<Array<Maybe<VildarPageComponentsContentSectionBlockContentPostEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarPageComponentsContentSectionBlockContentPostEdge = {
  __typename?: 'VildarPageComponentsContentSectionBlockContentPostEdge';
  node?: Maybe<VildarPageComponentsContentSectionBlockContentPostNode>;
};

export type VildarPageComponentsContentSectionBlockContentPostNode = VildarContentPosts;

export type VildarPageComponentsContentSectionBlockContentPostWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_content_posts?: InputMaybe<VildarContentPostsWhere>;
};

export type VildarPageComponentsContentSectionBlockCta = {
  __typename?: 'VildarPageComponentsContentSectionBlockCta';
  external_link?: Maybe<Link>;
  internal_linkConnection?: Maybe<VildarPageComponentsContentSectionBlockCtaInternalLinkConnection>;
  label?: Maybe<Scalars['String']['output']>;
};

export type VildarPageComponentsContentSectionBlockCtaInternalLinkConnection = {
  __typename?: 'VildarPageComponentsContentSectionBlockCtaInternalLinkConnection';
  edges?: Maybe<Array<Maybe<VildarPageComponentsContentSectionBlockCtaInternalLinkEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarPageComponentsContentSectionBlockCtaInternalLinkEdge = {
  __typename?: 'VildarPageComponentsContentSectionBlockCtaInternalLinkEdge';
  node?: Maybe<VildarPageComponentsContentSectionBlockCtaInternalLinkNode>;
};

export type VildarPageComponentsContentSectionBlockCtaInternalLinkNode = VildarContentPosts | VildarPage;

export type VildarPageComponentsContentSectionBlockCtaInternalLinkWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_content_posts?: InputMaybe<VildarContentPostsWhere>;
  vildar_page?: InputMaybe<VildarPageWhere>;
};

export type VildarPageComponentsContentSectionBlockCtaWhere = {
  external_link?: InputMaybe<LinkWhere>;
  external_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_link?: InputMaybe<VildarPageComponentsContentSectionBlockCtaInternalLinkWhere>;
  internal_link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_ne?: InputMaybe<Scalars['String']['input']>;
  label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarPageComponentsContentSectionBlockImage = {
  __typename?: 'VildarPageComponentsContentSectionBlockImage';
  alt_text?: Maybe<Scalars['String']['output']>;
  crop_resize?: Maybe<Scalars['String']['output']>;
  gravity?: Maybe<Scalars['String']['output']>;
  imgConnection?: Maybe<SysAssetConnection>;
};

export type VildarPageComponentsContentSectionBlockImageWhere = {
  alt_text?: InputMaybe<Scalars['String']['input']>;
  alt_text_exists?: InputMaybe<Scalars['Boolean']['input']>;
  alt_text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  alt_text_ne?: InputMaybe<Scalars['String']['input']>;
  alt_text_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crop_resize?: InputMaybe<Scalars['String']['input']>;
  crop_resize_exists?: InputMaybe<Scalars['Boolean']['input']>;
  crop_resize_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crop_resize_ne?: InputMaybe<Scalars['String']['input']>;
  crop_resize_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity?: InputMaybe<Scalars['String']['input']>;
  gravity_exists?: InputMaybe<Scalars['Boolean']['input']>;
  gravity_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gravity_ne?: InputMaybe<Scalars['String']['input']>;
  gravity_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  img?: InputMaybe<SysAssetWhere>;
  img_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarPageComponentsContentSectionBlockVideo = {
  __typename?: 'VildarPageComponentsContentSectionBlockVideo';
  autoplay?: Maybe<Scalars['Boolean']['output']>;
  controls?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type VildarPageComponentsContentSectionBlockVideoWhere = {
  autoplay?: InputMaybe<Scalars['Boolean']['input']>;
  autoplay_exists?: InputMaybe<Scalars['Boolean']['input']>;
  autoplay_ne?: InputMaybe<Scalars['Boolean']['input']>;
  controls?: InputMaybe<Scalars['Boolean']['input']>;
  controls_exists?: InputMaybe<Scalars['Boolean']['input']>;
  controls_ne?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarPageComponentsContentSectionBlockWhere = {
  content_post?: InputMaybe<VildarPageComponentsContentSectionBlockContentPostWhere>;
  content_post_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cta?: InputMaybe<VildarPageComponentsContentSectionBlockCtaWhere>;
  cta_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<VildarPageComponentsContentSectionBlockImageWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  layout_exists?: InputMaybe<Scalars['Boolean']['input']>;
  layout_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  layout_ne?: InputMaybe<Scalars['String']['input']>;
  layout_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraph_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph_ne?: InputMaybe<Scalars['String']['input']>;
  paragraph_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant?: InputMaybe<Scalars['String']['input']>;
  variant_exists?: InputMaybe<Scalars['Boolean']['input']>;
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  variant_ne?: InputMaybe<Scalars['String']['input']>;
  variant_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  video?: InputMaybe<VildarPageComponentsContentSectionBlockVideoWhere>;
  video_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarPageComponentsDocuments = {
  __typename?: 'VildarPageComponentsDocuments';
  documents?: Maybe<VildarPageComponentsDocumentsBlock>;
};

export type VildarPageComponentsDocumentsBlock = {
  __typename?: 'VildarPageComponentsDocumentsBlock';
  files?: Maybe<Array<Maybe<VildarPageComponentsDocumentsBlockFiles>>>;
  headline?: Maybe<Scalars['String']['output']>;
  paragraph?: Maybe<Scalars['String']['output']>;
};

export type VildarPageComponentsDocumentsBlockFiles = {
  __typename?: 'VildarPageComponentsDocumentsBlockFiles';
  file_referenceConnection?: Maybe<SysAssetConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VildarPageComponentsDocumentsBlockFilesWhere = {
  file_reference?: InputMaybe<SysAssetWhere>;
  file_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarPageComponentsDocumentsBlockWhere = {
  files?: InputMaybe<VildarPageComponentsDocumentsBlockFilesWhere>;
  files_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraph_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph_ne?: InputMaybe<Scalars['String']['input']>;
  paragraph_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarPageComponentsForms = {
  __typename?: 'VildarPageComponentsForms';
  forms?: Maybe<VildarPageComponentsFormsBlock>;
};

export type VildarPageComponentsFormsBlock = {
  __typename?: 'VildarPageComponentsFormsBlock';
  iframe_type?: Maybe<Scalars['String']['output']>;
  iframe_url?: Maybe<Link>;
};

export type VildarPageComponentsFormsBlockWhere = {
  iframe_type?: InputMaybe<Scalars['String']['input']>;
  iframe_type_exists?: InputMaybe<Scalars['Boolean']['input']>;
  iframe_type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iframe_type_ne?: InputMaybe<Scalars['String']['input']>;
  iframe_type_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  iframe_url?: InputMaybe<LinkWhere>;
  iframe_url_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarPageComponentsTabsSection = {
  __typename?: 'VildarPageComponentsTabsSection';
  tabs_section?: Maybe<VildarPageComponentsTabsSectionBlock>;
};

export type VildarPageComponentsTabsSectionBlock = {
  __typename?: 'VildarPageComponentsTabsSectionBlock';
  tab?: Maybe<Array<Maybe<VildarPageComponentsTabsSectionBlockTab>>>;
};

export type VildarPageComponentsTabsSectionBlockTab = {
  __typename?: 'VildarPageComponentsTabsSectionBlockTab';
  card_sectionConnection?: Maybe<VildarPageComponentsTabsSectionBlockTabCardSectionConnection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type VildarPageComponentsTabsSectionBlockTabCardSectionConnection = {
  __typename?: 'VildarPageComponentsTabsSectionBlockTabCardSectionConnection';
  edges?: Maybe<Array<Maybe<VildarPageComponentsTabsSectionBlockTabCardSectionEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VildarPageComponentsTabsSectionBlockTabCardSectionEdge = {
  __typename?: 'VildarPageComponentsTabsSectionBlockTabCardSectionEdge';
  node?: Maybe<VildarPageComponentsTabsSectionBlockTabCardSectionNode>;
};

export type VildarPageComponentsTabsSectionBlockTabCardSectionNode = VildarCardSection;

export type VildarPageComponentsTabsSectionBlockTabCardSectionWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  vildar_card_section?: InputMaybe<VildarCardSectionWhere>;
};

export type VildarPageComponentsTabsSectionBlockTabWhere = {
  card_section?: InputMaybe<VildarPageComponentsTabsSectionBlockTabCardSectionWhere>;
  card_section_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarPageComponentsTabsSectionBlockWhere = {
  tab?: InputMaybe<VildarPageComponentsTabsSectionBlockTabWhere>;
  tab_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarPageComponentsWhere = {
  banner?: InputMaybe<VildarPageComponentsBannerBlockWhere>;
  banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  cards?: InputMaybe<VildarPageComponentsCardsBlockWhere>;
  cards_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_section?: InputMaybe<VildarPageComponentsContentSectionBlockWhere>;
  content_section_exists?: InputMaybe<Scalars['Boolean']['input']>;
  documents?: InputMaybe<VildarPageComponentsDocumentsBlockWhere>;
  documents_exists?: InputMaybe<Scalars['Boolean']['input']>;
  forms?: InputMaybe<VildarPageComponentsFormsBlockWhere>;
  forms_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tabs_section?: InputMaybe<VildarPageComponentsTabsSectionBlockWhere>;
  tabs_section_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VildarPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type VildarPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<VildarPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<VildarPageWhere>>>;
  components?: InputMaybe<VildarPageComponentsWhere>;
  components_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hidden?: InputMaybe<Scalars['String']['input']>;
  hidden_exists?: InputMaybe<Scalars['Boolean']['input']>;
  hidden_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hidden_ne?: InputMaybe<Scalars['String']['input']>;
  hidden_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigation_title?: InputMaybe<Scalars['String']['input']>;
  navigation_title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  navigation_title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  navigation_title_ne?: InputMaybe<Scalars['String']['input']>;
  navigation_title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  seo?: InputMaybe<SeoWhere>;
  seo_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translation_strings?: InputMaybe<VildarTranslationGroupsWhere>;
  translation_strings_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type VildarTranslationGroup = {
  __typename?: 'VildarTranslationGroup';
  code_reference?: Maybe<Scalars['String']['output']>;
  strings?: Maybe<Array<Maybe<VildarTranslationGroupStrings>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum VildarTranslationGroupOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type VildarTranslationGroupStrings = VildarTranslationGroupStringsTranslation;

export type VildarTranslationGroupStringsTranslation = {
  __typename?: 'VildarTranslationGroupStringsTranslation';
  translation?: Maybe<VildarTranslationGroupStringsTranslationBlock>;
};

export type VildarTranslationGroupStringsTranslationBlock = {
  __typename?: 'VildarTranslationGroupStringsTranslationBlock';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type VildarTranslationGroupStringsTranslationBlockWhere = {
  key?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_ne?: InputMaybe<Scalars['String']['input']>;
  key_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_ne?: InputMaybe<Scalars['String']['input']>;
  value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VildarTranslationGroupStringsWhere = {
  translation?: InputMaybe<VildarTranslationGroupStringsTranslationBlockWhere>;
  translation_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VildarTranslationGroupWhere = {
  AND?: InputMaybe<Array<InputMaybe<VildarTranslationGroupWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<VildarTranslationGroupWhere>>>;
  code_reference?: InputMaybe<Scalars['String']['input']>;
  code_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  code_reference_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code_reference_ne?: InputMaybe<Scalars['String']['input']>;
  code_reference_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  strings?: InputMaybe<VildarTranslationGroupStringsWhere>;
  strings_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type VildarTranslationGroups = {
  __typename?: 'VildarTranslationGroups';
  groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type VildarTranslationGroupsWhere = {
  groups?: InputMaybe<Scalars['String']['input']>;
  groups_exists?: InputMaybe<Scalars['Boolean']['input']>;
  groups_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groups_ne?: InputMaybe<Scalars['String']['input']>;
  groups_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WinGridsection = {
  __typename?: 'WinGridsection';
  grid?: Maybe<Array<Maybe<WinGridsectionGrid>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type WinGridsectionGrid = WinGridsectionGridColumn;

export type WinGridsectionGridColumn = {
  __typename?: 'WinGridsectionGridColumn';
  column?: Maybe<WinGridsectionGridColumnBlock>;
};

export type WinGridsectionGridColumnBlock = {
  __typename?: 'WinGridsectionGridColumnBlock';
  headline?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  link?: Maybe<WinGridsectionGridColumnBlockLink>;
  paragraph?: Maybe<Scalars['String']['output']>;
};

export type WinGridsectionGridColumnBlockLink = {
  __typename?: 'WinGridsectionGridColumnBlockLink';
  external_page_reference?: Maybe<Link>;
  internal_page_referenceConnection?: Maybe<WinGridsectionGridColumnBlockLinkInternalPageReferenceConnection>;
  link_label?: Maybe<Scalars['String']['output']>;
};

export type WinGridsectionGridColumnBlockLinkInternalPageReferenceConnection = {
  __typename?: 'WinGridsectionGridColumnBlockLinkInternalPageReferenceConnection';
  edges?: Maybe<Array<Maybe<WinGridsectionGridColumnBlockLinkInternalPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type WinGridsectionGridColumnBlockLinkInternalPageReferenceEdge = {
  __typename?: 'WinGridsectionGridColumnBlockLinkInternalPageReferenceEdge';
  node?: Maybe<WinGridsectionGridColumnBlockLinkInternalPageReferenceNode>;
};

export type WinGridsectionGridColumnBlockLinkInternalPageReferenceNode = WinPage;

export type WinGridsectionGridColumnBlockLinkInternalPageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  win_page?: InputMaybe<WinPageWhere>;
};

export type WinGridsectionGridColumnBlockLinkWhere = {
  external_page_reference?: InputMaybe<LinkWhere>;
  external_page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_page_reference?: InputMaybe<WinGridsectionGridColumnBlockLinkInternalPageReferenceWhere>;
  internal_page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_label?: InputMaybe<Scalars['String']['input']>;
  link_label_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link_label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  link_label_ne?: InputMaybe<Scalars['String']['input']>;
  link_label_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WinGridsectionGridColumnBlockWhere = {
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<WinGridsectionGridColumnBlockLinkWhere>;
  link_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraph_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph_ne?: InputMaybe<Scalars['String']['input']>;
  paragraph_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WinGridsectionGridWhere = {
  column?: InputMaybe<WinGridsectionGridColumnBlockWhere>;
  column_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum WinGridsectionOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type WinGridsectionWhere = {
  AND?: InputMaybe<Array<InputMaybe<WinGridsectionWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<WinGridsectionWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  grid?: InputMaybe<WinGridsectionGridWhere>;
  grid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type WinPage = {
  __typename?: 'WinPage';
  components?: Maybe<Array<Maybe<WinPageComponents>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
  translation_groups?: Maybe<WinTranslationGroups>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WinPageComponents = WinPageComponentsBanner | WinPageComponentsGrid | WinPageComponentsTextAndImageSection;

export type WinPageComponentsBanner = {
  __typename?: 'WinPageComponentsBanner';
  banner?: Maybe<WinPageComponentsBannerBlock>;
};

export type WinPageComponentsBannerBlock = {
  __typename?: 'WinPageComponentsBannerBlock';
  headline?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  paragraph?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
};

export type WinPageComponentsBannerBlockWhere = {
  headline?: InputMaybe<Scalars['String']['input']>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraph_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph_ne?: InputMaybe<Scalars['String']['input']>;
  paragraph_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  style?: InputMaybe<Scalars['String']['input']>;
  style_exists?: InputMaybe<Scalars['Boolean']['input']>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  style_ne?: InputMaybe<Scalars['String']['input']>;
  style_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WinPageComponentsGrid = {
  __typename?: 'WinPageComponentsGrid';
  grid?: Maybe<WinPageComponentsGridBlock>;
};

export type WinPageComponentsGridBlock = {
  __typename?: 'WinPageComponentsGridBlock';
  grid_section_referenceConnection?: Maybe<WinPageComponentsGridBlockGridSectionReferenceConnection>;
};


export type WinPageComponentsGridBlockGrid_Section_ReferenceConnectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type WinPageComponentsGridBlockGridSectionReferenceConnection = {
  __typename?: 'WinPageComponentsGridBlockGridSectionReferenceConnection';
  edges?: Maybe<Array<Maybe<WinPageComponentsGridBlockGridSectionReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type WinPageComponentsGridBlockGridSectionReferenceEdge = {
  __typename?: 'WinPageComponentsGridBlockGridSectionReferenceEdge';
  node?: Maybe<WinPageComponentsGridBlockGridSectionReferenceNode>;
};

export type WinPageComponentsGridBlockGridSectionReferenceNode = WinGridsection;

export type WinPageComponentsGridBlockGridSectionReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  win_gridsection?: InputMaybe<WinGridsectionWhere>;
};

export type WinPageComponentsGridBlockWhere = {
  grid_section_reference?: InputMaybe<WinPageComponentsGridBlockGridSectionReferenceWhere>;
  grid_section_reference_count?: InputMaybe<Scalars['Int']['input']>;
  grid_section_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WinPageComponentsTextAndImageSection = {
  __typename?: 'WinPageComponentsTextAndImageSection';
  text_and_image_section?: Maybe<WinPageComponentsTextAndImageSectionBlock>;
};

export type WinPageComponentsTextAndImageSectionBlock = {
  __typename?: 'WinPageComponentsTextAndImageSectionBlock';
  external_page_reference?: Maybe<Link>;
  headline?: Maybe<Scalars['String']['output']>;
  headline2?: Maybe<Scalars['String']['output']>;
  imageConnection?: Maybe<SysAssetConnection>;
  internal_page_referenceConnection?: Maybe<WinPageComponentsTextAndImageSectionBlockInternalPageReferenceConnection>;
  paragraph?: Maybe<Scalars['String']['output']>;
};

export type WinPageComponentsTextAndImageSectionBlockInternalPageReferenceConnection = {
  __typename?: 'WinPageComponentsTextAndImageSectionBlockInternalPageReferenceConnection';
  edges?: Maybe<Array<Maybe<WinPageComponentsTextAndImageSectionBlockInternalPageReferenceEdge>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type WinPageComponentsTextAndImageSectionBlockInternalPageReferenceEdge = {
  __typename?: 'WinPageComponentsTextAndImageSectionBlockInternalPageReferenceEdge';
  node?: Maybe<WinPageComponentsTextAndImageSectionBlockInternalPageReferenceNode>;
};

export type WinPageComponentsTextAndImageSectionBlockInternalPageReferenceNode = WinPage;

export type WinPageComponentsTextAndImageSectionBlockInternalPageReferenceWhere = {
  MATCH?: InputMaybe<EvalReferenceEnum>;
  win_page?: InputMaybe<WinPageWhere>;
};

export type WinPageComponentsTextAndImageSectionBlockWhere = {
  external_page_reference?: InputMaybe<LinkWhere>;
  external_page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  headline2?: InputMaybe<Scalars['String']['input']>;
  headline2_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline2_ne?: InputMaybe<Scalars['String']['input']>;
  headline2_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_exists?: InputMaybe<Scalars['Boolean']['input']>;
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  headline_ne?: InputMaybe<Scalars['String']['input']>;
  headline_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  image?: InputMaybe<SysAssetWhere>;
  image_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internal_page_reference?: InputMaybe<WinPageComponentsTextAndImageSectionBlockInternalPageReferenceWhere>;
  internal_page_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  paragraph_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraph_ne?: InputMaybe<Scalars['String']['input']>;
  paragraph_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WinPageComponentsWhere = {
  banner?: InputMaybe<WinPageComponentsBannerBlockWhere>;
  banner_exists?: InputMaybe<Scalars['Boolean']['input']>;
  grid?: InputMaybe<WinPageComponentsGridBlockWhere>;
  grid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  text_and_image_section?: InputMaybe<WinPageComponentsTextAndImageSectionBlockWhere>;
  text_and_image_section_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum WinPageOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type WinPageWhere = {
  AND?: InputMaybe<Array<InputMaybe<WinPageWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<WinPageWhere>>>;
  components?: InputMaybe<WinPageComponentsWhere>;
  components_exists?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translation_groups?: InputMaybe<WinTranslationGroupsWhere>;
  translation_groups_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_ne?: InputMaybe<Scalars['String']['input']>;
  url_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type WinTranslationGroup = {
  __typename?: 'WinTranslationGroup';
  group_reference?: Maybe<WinTranslationGroups>;
  strings?: Maybe<Array<Maybe<WinTranslationGroupStrings>>>;
  system?: Maybe<EntrySystemField>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum WinTranslationGroupOrderBy {
  CreatedAtAsc = 'created_at_ASC',
  CreatedAtDesc = 'created_at_DESC',
  UpdatedAtAsc = 'updated_at_ASC',
  UpdatedAtDesc = 'updated_at_DESC'
}

export type WinTranslationGroupStrings = {
  __typename?: 'WinTranslationGroupStrings';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type WinTranslationGroupStringsWhere = {
  key?: InputMaybe<Scalars['String']['input']>;
  key_exists?: InputMaybe<Scalars['Boolean']['input']>;
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  key_ne?: InputMaybe<Scalars['String']['input']>;
  key_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_exists?: InputMaybe<Scalars['Boolean']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_ne?: InputMaybe<Scalars['String']['input']>;
  value_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type WinTranslationGroupWhere = {
  AND?: InputMaybe<Array<InputMaybe<WinTranslationGroupWhere>>>;
  OR?: InputMaybe<Array<InputMaybe<WinTranslationGroupWhere>>>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  created_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  created_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  created_by_ne?: InputMaybe<Scalars['String']['input']>;
  created_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  group_reference?: InputMaybe<WinTranslationGroupsWhere>;
  group_reference_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_exists?: InputMaybe<Scalars['Boolean']['input']>;
  locale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  locale_ne?: InputMaybe<Scalars['String']['input']>;
  locale_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publish_details?: InputMaybe<SystemPublishDetailsWhere>;
  strings?: InputMaybe<WinTranslationGroupStringsWhere>;
  strings_exists?: InputMaybe<Scalars['Boolean']['input']>;
  tags_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_ne?: InputMaybe<Scalars['String']['input']>;
  title_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid?: InputMaybe<Scalars['String']['input']>;
  uid_exists?: InputMaybe<Scalars['Boolean']['input']>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uid_ne?: InputMaybe<Scalars['String']['input']>;
  uid_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_at?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_at_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_ne?: InputMaybe<Scalars['DateTime']['input']>;
  updated_at_nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updated_by?: InputMaybe<Scalars['String']['input']>;
  updated_by_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updated_by_ne?: InputMaybe<Scalars['String']['input']>;
  updated_by_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_ne?: InputMaybe<Scalars['Int']['input']>;
  version_nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type WinTranslationGroups = {
  __typename?: 'WinTranslationGroups';
  groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type WinTranslationGroupsWhere = {
  groups?: InputMaybe<Scalars['String']['input']>;
  groups_exists?: InputMaybe<Scalars['Boolean']['input']>;
  groups_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  groups_ne?: InputMaybe<Scalars['String']['input']>;
  groups_nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HeroBannerFragmentFragment = { __typename: 'VildarPageComponentsBanner', banner?: { __typename?: 'VildarPageComponentsBannerBlock', headline?: string | null, layout?: string | null, paragraph?: string | null, rounded?: boolean | null, styled?: boolean | null, mobile_layout?: string | null, object_fit?: string | null, expand_image?: boolean | null, main_banner?: boolean | null, cta?: { __typename?: 'VildarPageComponentsBannerBlockCta', label?: string | null, external_link?: { __typename?: 'Link', href?: string | null, title?: string | null } | null, internal_linkConnection?: { __typename?: 'VildarPageComponentsBannerBlockCtaInternalLinkConnection', edges?: Array<{ __typename?: 'VildarPageComponentsBannerBlockCtaInternalLinkEdge', node?: { __typename?: 'VildarPage', navigation_title?: string | null, url?: string | null } | null } | null> | null } | null } | null, image?: { __typename?: 'VildarPageComponentsBannerBlockImage', alt_text?: string | null, crop_resize?: string | null, gravity?: string | null, imgConnection?: (
        { __typename?: 'SysAssetConnection' }
        & { ' $fragmentRefs'?: { 'SysAssetConnectionFragmentFragment': SysAssetConnectionFragmentFragment } }
      ) | null } | null } | null } & { ' $fragmentName'?: 'HeroBannerFragmentFragment' };

export type VildarCardSectionFragmentFragment = { __typename: 'VildarPageComponentsCards', cards?: { __typename?: 'VildarPageComponentsCardsBlock', card_sectionConnection?: { __typename?: 'VildarPageComponentsCardsBlockCardSectionConnection', edges?: Array<{ __typename: 'VildarPageComponentsCardsBlockCardSectionEdge', node?: (
          { __typename?: 'VildarCardSection' }
          & { ' $fragmentRefs'?: { 'VildarCardSectionCardsFragmentFragment': VildarCardSectionCardsFragmentFragment } }
        ) | null } | null> | null } | null } | null } & { ' $fragmentName'?: 'VildarCardSectionFragmentFragment' };

export type VildarCardSectionCardsFragmentFragment = (
  { __typename: 'VildarCardSection', title?: string | null, display_title?: string | null, sidebar?: boolean | null, post_limit?: number | null, paragraph?: string | null, pagination?: boolean | null, type?: string | null, card_template?: string | null, link?: { __typename?: 'VildarCardSectionLink', label?: string | null, external_url?: { __typename?: 'Link', href?: string | null, title?: string | null } | null, internal_urlConnection?: { __typename?: 'VildarCardSectionLinkInternalUrlConnection', edges?: Array<{ __typename?: 'VildarCardSectionLinkInternalUrlEdge', node?: { __typename?: 'VildarPage', navigation_title?: string | null, url?: string | null } | null } | null> | null } | null } | null }
  & { ' $fragmentRefs'?: { 'CardSectionSimpleBoxesFragmentFragment': CardSectionSimpleBoxesFragmentFragment;'CardSectionPersonsFragmentFragment': CardSectionPersonsFragmentFragment } }
) & { ' $fragmentName'?: 'VildarCardSectionCardsFragmentFragment' };

export type CardSectionSimpleBoxesFragmentFragment = { __typename?: 'VildarCardSection', simple_boxes?: Array<{ __typename: 'VildarCardSectionSimpleBoxesBox', box?: { __typename?: 'VildarCardSectionSimpleBoxesBoxBlock', background_color?: string | null, headline?: string | null, text?: string | null } | null } | null> | null } & { ' $fragmentName'?: 'CardSectionSimpleBoxesFragmentFragment' };

export type CardSectionPersonsFragmentFragment = { __typename?: 'VildarCardSection', persons?: Array<{ __typename: 'VildarCardSectionPersonsPerson', person?: { __typename?: 'VildarCardSectionPersonsPersonBlock', description?: string | null, name?: string | null, image?: { __typename?: 'VildarCardSectionPersonsPersonBlockImage', alt_text?: string | null, crop_resize?: string | null, gravity?: string | null, assetConnection?: { __typename?: 'SysAssetConnection', edges?: Array<{ __typename?: 'SysAssetEdge', node?: { __typename?: 'SysAsset', content_type?: string | null, dimension?: { __typename?: 'SysAssetDimension', height?: number | null, width?: number | null } | null, system?: { __typename?: 'SysAssetSystemField', uid?: string | null } | null } | null } | null> | null } | null } | null } | null } | null> | null } & { ' $fragmentName'?: 'CardSectionPersonsFragmentFragment' };

export type ContentSectionFragmentFragment = { __typename: 'VildarPageComponentsContentSection', content_section?: { __typename?: 'VildarPageComponentsContentSectionBlock', headline?: string | null, layout?: string | null, paragraph?: string | null, variant?: string | null, cta?: { __typename?: 'VildarPageComponentsContentSectionBlockCta', label?: string | null, external_link?: { __typename?: 'Link', href?: string | null, title?: string | null } | null, internal_linkConnection?: { __typename?: 'VildarPageComponentsContentSectionBlockCtaInternalLinkConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'VildarPageComponentsContentSectionBlockCtaInternalLinkEdge', node?: { __typename?: 'VildarContentPosts', title?: string | null, url?: string | null } | { __typename?: 'VildarPage', title?: string | null, url?: string | null } | null } | null> | null } | null } | null, image?: { __typename?: 'VildarPageComponentsContentSectionBlockImage', alt_text?: string | null, crop_resize?: string | null, gravity?: string | null, imgConnection?: (
        { __typename?: 'SysAssetConnection' }
        & { ' $fragmentRefs'?: { 'SysAssetConnectionFragmentFragment': SysAssetConnectionFragmentFragment } }
      ) | null } | null, video?: { __typename?: 'VildarPageComponentsContentSectionBlockVideo', url?: string | null, autoplay?: boolean | null, controls?: boolean | null } | null, content_postConnection?: { __typename?: 'VildarPageComponentsContentSectionBlockContentPostConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'VildarPageComponentsContentSectionBlockContentPostEdge', node?: { __typename?: 'VildarContentPosts', date?: any | null, show_date?: boolean | null, display_title?: string | null, short_description?: string | null, url?: string | null, type?: string | null, image?: Array<{ __typename?: 'Image', crop_resize?: string | null, gravity?: string | null, alt_text?: string | null, assetConnection?: (
              { __typename?: 'SysAssetConnection' }
              & { ' $fragmentRefs'?: { 'SysAssetConnectionFragmentFragment': SysAssetConnectionFragmentFragment } }
            ) | null } | null> | null } | null } | null> | null } | null } | null } & { ' $fragmentName'?: 'ContentSectionFragmentFragment' };

export type DocumentsSectionFragmentFragment = { __typename: 'VildarPageComponentsDocuments', documents?: { __typename?: 'VildarPageComponentsDocumentsBlock', headline?: string | null, paragraph?: string | null, files?: Array<{ __typename?: 'VildarPageComponentsDocumentsBlockFiles', title?: string | null, file_referenceConnection?: { __typename?: 'SysAssetConnection', edges?: Array<{ __typename?: 'SysAssetEdge', node?: { __typename?: 'SysAsset', url?: string | null } | null } | null> | null } | null } | null> | null } | null } & { ' $fragmentName'?: 'DocumentsSectionFragmentFragment' };

export type VildarIframeSectionFragmentFragment = { __typename: 'VildarPageComponentsForms', forms?: { __typename?: 'VildarPageComponentsFormsBlock', iframe_type?: string | null, iframe_url?: { __typename?: 'Link', href?: string | null, title?: string | null } | null } | null } & { ' $fragmentName'?: 'VildarIframeSectionFragmentFragment' };

export type NavigationAndFooterQueryVariables = Exact<{
  navId: Scalars['String']['input'];
  footerId: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;


export type NavigationAndFooterQuery = { __typename?: 'Query', navigation?: (
    { __typename?: 'VildarNavigation' }
    & { ' $fragmentRefs'?: { 'NavigationMenuFragmentFragment': NavigationMenuFragmentFragment } }
  ) | null, footer?: (
    { __typename?: 'VildarNavigation' }
    & { ' $fragmentRefs'?: { 'NavigationAndFooterFragmentFragment': NavigationAndFooterFragmentFragment } }
  ) | null };

export type NavigationMenuQueryVariables = Exact<{
  uid: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  footer: Scalars['Boolean']['input'];
}>;


export type NavigationMenuQuery = { __typename?: 'Query', vildar_navigation?: (
    { __typename?: 'VildarNavigation', footer_specific?: (
      { __typename?: 'VildarNavigationFooterSpecific' }
      & { ' $fragmentRefs'?: { 'FooterSpecificFragmentFragment': FooterSpecificFragmentFragment } }
    ) | null }
    & { ' $fragmentRefs'?: { 'NavigationMenuFragmentFragment': NavigationMenuFragmentFragment } }
  ) | null };

export type NavigationAndFooterFragmentFragment = (
  { __typename?: 'VildarNavigation', footer_specific?: (
    { __typename?: 'VildarNavigationFooterSpecific' }
    & { ' $fragmentRefs'?: { 'FooterSpecificFragmentFragment': FooterSpecificFragmentFragment } }
  ) | null }
  & { ' $fragmentRefs'?: { 'NavigationMenuFragmentFragment': NavigationMenuFragmentFragment } }
) & { ' $fragmentName'?: 'NavigationAndFooterFragmentFragment' };

export type NavigationMenuFragmentFragment = { __typename?: 'VildarNavigation', title?: string | null, buttons?: Array<{ __typename: 'VildarNavigationButtonsCta', cta?: { __typename?: 'VildarNavigationButtonsCtaBlock', button_style?: string | null, label?: string | null, external_link?: { __typename?: 'Link', href?: string | null, title?: string | null } | null, internal_linkConnection?: { __typename?: 'VildarNavigationButtonsCtaBlockInternalLinkConnection', edges?: Array<{ __typename?: 'VildarNavigationButtonsCtaBlockInternalLinkEdge', node?: { __typename?: 'VildarPage', navigation_title?: string | null, url?: string | null } | null } | null> | null } | null } | null } | { __typename: 'VildarNavigationButtonsLanguagePicker', language_picker?: { __typename?: 'VildarNavigationButtonsLanguagePickerBlock', languages?: Array<string | null> | null } | null } | null> | null, links?: Array<{ __typename: 'VildarNavigationLinksCta', cta?: { __typename?: 'VildarNavigationLinksCtaBlock', label?: string | null, external_link?: { __typename?: 'Link', href?: string | null, title?: string | null } | null, internal_linkConnection?: { __typename?: 'VildarNavigationLinksCtaBlockInternalLinkConnection', edges?: Array<{ __typename?: 'VildarNavigationLinksCtaBlockInternalLinkEdge', node?: { __typename?: 'VildarPage', navigation_title?: string | null, url?: string | null } | null } | null> | null } | null } | null } | null> | null, logo?: { __typename?: 'Image', alt_text?: string | null, crop_resize?: string | null, gravity?: string | null, assetConnection?: (
      { __typename?: 'SysAssetConnection' }
      & { ' $fragmentRefs'?: { 'SysAssetConnectionFragmentFragment': SysAssetConnectionFragmentFragment } }
    ) | null } | null, pagesConnection?: { __typename?: 'VildarNavigationPagesConnection', edges?: Array<{ __typename?: 'VildarNavigationPagesEdge', node?: { __typename?: 'VildarPage', navigation_title?: string | null, url?: string | null } | null } | null> | null } | null } & { ' $fragmentName'?: 'NavigationMenuFragmentFragment' };

export type LanguagePickerFragmentFragment = { __typename: 'VildarNavigationButtonsLanguagePicker', language_picker?: { __typename?: 'VildarNavigationButtonsLanguagePickerBlock', languages?: Array<string | null> | null } | null } & { ' $fragmentName'?: 'LanguagePickerFragmentFragment' };

export type FooterSpecificFragmentFragment = { __typename?: 'VildarNavigationFooterSpecific', headline?: string | null, email?: string | null, impressum?: string | null, phone?: string | null, icelandair_logo?: { __typename?: 'Image', crop_resize?: string | null, gravity?: string | null, assetConnection?: { __typename?: 'SysAssetConnection', edges?: Array<{ __typename?: 'SysAssetEdge', node?: { __typename?: 'SysAsset', content_type?: string | null, system?: { __typename?: 'SysAssetSystemField', uid?: string | null } | null, dimension?: { __typename?: 'SysAssetDimension', height?: number | null, width?: number | null } | null } | null } | null> | null } | null } | null } & { ' $fragmentName'?: 'FooterSpecificFragmentFragment' };

export type GetPageByUrlQueryVariables = Exact<{
  url: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;


export type GetPageByUrlQuery = { __typename?: 'Query', all_vildar_page?: { __typename: 'AllVildarPage', items?: Array<(
      { __typename?: 'VildarPage' }
      & { ' $fragmentRefs'?: { 'PageFragmentFragment': PageFragmentFragment;'ComponentsFragmentFragment': ComponentsFragmentFragment } }
    ) | null> | null } | null };

export type VildarPageQueryFragmentFragment = (
  { __typename?: 'VildarPage' }
  & { ' $fragmentRefs'?: { 'PageFragmentFragment': PageFragmentFragment;'ComponentsFragmentFragment': ComponentsFragmentFragment } }
) & { ' $fragmentName'?: 'VildarPageQueryFragmentFragment' };

export type ComponentsFragmentFragment = { __typename?: 'VildarPage', components?: Array<(
    { __typename?: 'VildarPageComponentsBanner' }
    & { ' $fragmentRefs'?: { 'HeroBannerFragmentFragment': HeroBannerFragmentFragment } }
  ) | (
    { __typename?: 'VildarPageComponentsCards' }
    & { ' $fragmentRefs'?: { 'VildarCardSectionFragmentFragment': VildarCardSectionFragmentFragment } }
  ) | (
    { __typename?: 'VildarPageComponentsContentSection' }
    & { ' $fragmentRefs'?: { 'ContentSectionFragmentFragment': ContentSectionFragmentFragment } }
  ) | (
    { __typename?: 'VildarPageComponentsDocuments' }
    & { ' $fragmentRefs'?: { 'DocumentsSectionFragmentFragment': DocumentsSectionFragmentFragment } }
  ) | (
    { __typename?: 'VildarPageComponentsForms' }
    & { ' $fragmentRefs'?: { 'VildarIframeSectionFragmentFragment': VildarIframeSectionFragmentFragment } }
  ) | (
    { __typename?: 'VildarPageComponentsTabsSection' }
    & { ' $fragmentRefs'?: { 'VildarTabSectionFragmentFragment': VildarTabSectionFragmentFragment } }
  ) | null> | null } & { ' $fragmentName'?: 'ComponentsFragmentFragment' };

export type PageFragmentFragment = { __typename: 'VildarPage', title?: string | null, url?: string | null, hidden?: string | null, seo?: { __typename?: 'Seo', description?: string | null, title?: string | null, imageConnection?: { __typename: 'SysAssetConnection', edges?: Array<{ __typename?: 'SysAssetEdge', node?: { __typename: 'SysAsset', content_type?: string | null, dimension?: { __typename?: 'SysAssetDimension', height?: number | null, width?: number | null } | null, system?: { __typename?: 'SysAssetSystemField', uid?: string | null } | null } | null } | null> | null } | null } | null, translation_strings?: { __typename?: 'VildarTranslationGroups', groups?: Array<string | null> | null } | null, system?: { __typename?: 'EntrySystemField', uid?: string | null, content_type_uid?: string | null, updated_at?: any | null } | null } & { ' $fragmentName'?: 'PageFragmentFragment' };

export type TabSectionCardSectionFragmentFragment = { __typename?: 'VildarCardSection', title?: string | null, display_title?: string | null, card_template?: string | null, pagination?: boolean | null, paragraph?: string | null, post_limit?: number | null, sidebar?: boolean | null, type?: string | null, link?: { __typename?: 'VildarCardSectionLink', label?: string | null, external_url?: { __typename?: 'Link', href?: string | null, title?: string | null } | null, internal_urlConnection?: { __typename?: 'VildarCardSectionLinkInternalUrlConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'VildarCardSectionLinkInternalUrlEdge', node?: { __typename?: 'VildarPage', title?: string | null, navigation_title?: string | null, url?: string | null } | null } | null> | null } | null } | null, simple_boxes?: Array<{ __typename: 'VildarCardSectionSimpleBoxesBox', box?: { __typename?: 'VildarCardSectionSimpleBoxesBoxBlock', background_color?: string | null, headline?: string | null, text?: string | null } | null } | null> | null, persons?: Array<{ __typename: 'VildarCardSectionPersonsPerson', person?: { __typename?: 'VildarCardSectionPersonsPersonBlock', description?: string | null, name?: string | null } | null } | null> | null } & { ' $fragmentName'?: 'TabSectionCardSectionFragmentFragment' };

export type VildarTabSectionFragmentFragment = { __typename: 'VildarPageComponentsTabsSection', tabs_section?: { __typename?: 'VildarPageComponentsTabsSectionBlock', tab?: Array<{ __typename?: 'VildarPageComponentsTabsSectionBlockTab', title?: string | null, card_sectionConnection?: { __typename?: 'VildarPageComponentsTabsSectionBlockTabCardSectionConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'VildarPageComponentsTabsSectionBlockTabCardSectionEdge', node?: (
            { __typename?: 'VildarCardSection' }
            & { ' $fragmentRefs'?: { 'TabSectionCardSectionFragmentFragment': TabSectionCardSectionFragmentFragment } }
          ) | null } | null> | null } | null } | null> | null } | null } & { ' $fragmentName'?: 'VildarTabSectionFragmentFragment' };

export type GetAllTranslationGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTranslationGroupsQuery = { __typename?: 'Query', all_vildar_translation_group?: { __typename?: 'AllVildarTranslationGroup', items?: Array<{ __typename?: 'VildarTranslationGroup', code_reference?: string | null, system?: { __typename?: 'EntrySystemField', uid?: string | null } | null } | null> | null } | null };

export type GetContentPostsQueryVariables = Exact<{
  type: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetContentPostsQuery = { __typename?: 'Query', all_vildar_content_posts?: (
    { __typename?: 'AllVildarContentPosts' }
    & { ' $fragmentRefs'?: { 'ContentPostsListFragmentFragment': ContentPostsListFragmentFragment } }
  ) | null };

export type GetContentPostByLocaleQueryVariables = Exact<{
  type: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  locale: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
}>;


export type GetContentPostByLocaleQuery = { __typename?: 'Query', all_vildar_content_posts?: (
    { __typename?: 'AllVildarContentPosts' }
    & { ' $fragmentRefs'?: { 'ContentPostsListFragmentFragment': ContentPostsListFragmentFragment } }
  ) | null };

export type GetContentPostsByUrlAndTypeQueryVariables = Exact<{
  type: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  url: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;


export type GetContentPostsByUrlAndTypeQuery = { __typename?: 'Query', all_vildar_content_posts?: (
    { __typename?: 'AllVildarContentPosts' }
    & { ' $fragmentRefs'?: { 'ContentPostsListFragmentFragment': ContentPostsListFragmentFragment } }
  ) | null };

export type GetContentPostsByUrlQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  url: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;


export type GetContentPostsByUrlQuery = { __typename?: 'Query', all_vildar_content_posts?: (
    { __typename?: 'AllVildarContentPosts' }
    & { ' $fragmentRefs'?: { 'ContentPostsListFragmentFragment': ContentPostsListFragmentFragment } }
  ) | null };

export type ContentPostsListFragmentFragment = { __typename?: 'AllVildarContentPosts', total?: number | null, items?: Array<(
    { __typename?: 'VildarContentPosts' }
    & { ' $fragmentRefs'?: { 'ContentPostFragmentFragment': ContentPostFragmentFragment } }
  ) | null> | null } & { ' $fragmentName'?: 'ContentPostsListFragmentFragment' };

export type ImageFragmentFragment = { __typename?: 'VildarContentPosts', image?: Array<{ __typename?: 'Image', alt_text?: string | null, crop_resize?: string | null, gravity?: string | null, assetConnection?: (
      { __typename?: 'SysAssetConnection' }
      & { ' $fragmentRefs'?: { 'SysAssetConnectionFragmentFragment': SysAssetConnectionFragmentFragment } }
    ) | null } | null> | null } & { ' $fragmentName'?: 'ImageFragmentFragment' };

export type ContentPostFragmentFragment = (
  { __typename: 'VildarContentPosts', text?: string | null, title?: string | null, type?: string | null, url?: string | null, short_description?: string | null, display_title?: string | null, date?: any | null, show_date?: boolean | null, link_label?: string | null, next_post?: string | null, system?: { __typename?: 'EntrySystemField', updated_at?: any | null } | null }
  & { ' $fragmentRefs'?: { 'ImageFragmentFragment': ImageFragmentFragment } }
) & { ' $fragmentName'?: 'ContentPostFragmentFragment' };

export type GetAllContentPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContentPostsQuery = { __typename?: 'Query', is?: { __typename?: 'AllVildarContentPosts', items?: Array<{ __typename?: 'VildarContentPosts', url?: string | null, type?: string | null, title?: string | null, system?: { __typename?: 'EntrySystemField', uid?: string | null, locale?: string | null, updated_at?: any | null } | null } | null> | null } | null, en?: { __typename?: 'AllVildarContentPosts', items?: Array<{ __typename?: 'VildarContentPosts', url?: string | null, type?: string | null, title?: string | null, system?: { __typename?: 'EntrySystemField', uid?: string | null, locale?: string | null, updated_at?: any | null } | null } | null> | null } | null };

export type GetAllPagesByQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPagesByQuery = { __typename?: 'Query', is?: { __typename?: 'AllVildarPage', items?: Array<{ __typename?: 'VildarPage', url?: string | null, navigation_title?: string | null, system?: { __typename?: 'EntrySystemField', uid?: string | null, updated_at?: any | null } | null } | null> | null } | null, en?: { __typename?: 'AllVildarPage', items?: Array<{ __typename?: 'VildarPage', url?: string | null, navigation_title?: string | null, system?: { __typename?: 'EntrySystemField', uid?: string | null, updated_at?: any | null } | null } | null> | null } | null };

export type GetSingleTranslationGroupQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  uid: Scalars['String']['input'];
}>;


export type GetSingleTranslationGroupQuery = { __typename?: 'Query', vildar_translation_group?: { __typename?: 'VildarTranslationGroup', code_reference?: string | null, strings?: Array<{ __typename: 'VildarTranslationGroupStringsTranslation', translation?: { __typename?: 'VildarTranslationGroupStringsTranslationBlock', key?: string | null, value?: string | null } | null } | null> | null } | null };

export type SysAssetConnectionFragmentFragment = { __typename: 'SysAssetConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'SysAssetEdge', node?: { __typename?: 'SysAsset', content_type?: string | null, description?: string | null, dimension?: { __typename?: 'SysAssetDimension', height?: number | null, width?: number | null } | null, system?: { __typename?: 'SysAssetSystemField', uid?: string | null } | null } | null } | null> | null } & { ' $fragmentName'?: 'SysAssetConnectionFragmentFragment' };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}
export const SysAssetConnectionFragmentFragmentDoc = new TypedDocumentString(`
    fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}
    `, {"fragmentName":"SysAssetConnectionFragment"}) as unknown as TypedDocumentString<SysAssetConnectionFragmentFragment, unknown>;
export const NavigationMenuFragmentFragmentDoc = new TypedDocumentString(`
    fragment NavigationMenuFragment on VildarNavigation {
  buttons {
    ... on VildarNavigationButtonsLanguagePicker {
      __typename
      language_picker {
        languages
      }
    }
    ... on VildarNavigationButtonsCta {
      __typename
      cta {
        button_style
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
        label
      }
    }
  }
  links {
    ... on VildarNavigationLinksCta {
      __typename
      cta {
        external_link {
          href
          title
        }
        label
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
      }
    }
  }
  logo {
    alt_text
    assetConnection {
      ...SysAssetConnectionFragment
    }
    crop_resize
    gravity
  }
  pagesConnection {
    edges {
      node {
        ... on VildarPage {
          navigation_title
          url
        }
      }
    }
  }
  title
}
    fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"NavigationMenuFragment"}) as unknown as TypedDocumentString<NavigationMenuFragmentFragment, unknown>;
export const FooterSpecificFragmentFragmentDoc = new TypedDocumentString(`
    fragment FooterSpecificFragment on VildarNavigationFooterSpecific {
  headline
  email
  icelandair_logo {
    assetConnection {
      edges {
        node {
          system {
            uid
          }
          content_type
          dimension {
            height
            width
          }
        }
      }
    }
    crop_resize
    gravity
  }
  impressum
  phone
}
    `, {"fragmentName":"FooterSpecificFragment"}) as unknown as TypedDocumentString<FooterSpecificFragmentFragment, unknown>;
export const NavigationAndFooterFragmentFragmentDoc = new TypedDocumentString(`
    fragment NavigationAndFooterFragment on VildarNavigation {
  ...NavigationMenuFragment
  footer_specific {
    ...FooterSpecificFragment
  }
}
    fragment NavigationMenuFragment on VildarNavigation {
  buttons {
    ... on VildarNavigationButtonsLanguagePicker {
      __typename
      language_picker {
        languages
      }
    }
    ... on VildarNavigationButtonsCta {
      __typename
      cta {
        button_style
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
        label
      }
    }
  }
  links {
    ... on VildarNavigationLinksCta {
      __typename
      cta {
        external_link {
          href
          title
        }
        label
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
      }
    }
  }
  logo {
    alt_text
    assetConnection {
      ...SysAssetConnectionFragment
    }
    crop_resize
    gravity
  }
  pagesConnection {
    edges {
      node {
        ... on VildarPage {
          navigation_title
          url
        }
      }
    }
  }
  title
}
fragment FooterSpecificFragment on VildarNavigationFooterSpecific {
  headline
  email
  icelandair_logo {
    assetConnection {
      edges {
        node {
          system {
            uid
          }
          content_type
          dimension {
            height
            width
          }
        }
      }
    }
    crop_resize
    gravity
  }
  impressum
  phone
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"NavigationAndFooterFragment"}) as unknown as TypedDocumentString<NavigationAndFooterFragmentFragment, unknown>;
export const LanguagePickerFragmentFragmentDoc = new TypedDocumentString(`
    fragment LanguagePickerFragment on VildarNavigationButtonsLanguagePicker {
  __typename
  language_picker {
    languages
  }
}
    `, {"fragmentName":"LanguagePickerFragment"}) as unknown as TypedDocumentString<LanguagePickerFragmentFragment, unknown>;
export const PageFragmentFragmentDoc = new TypedDocumentString(`
    fragment PageFragment on VildarPage {
  __typename
  title
  url
  hidden
  seo {
    description
    title
    imageConnection {
      __typename
      edges {
        node {
          __typename
          dimension {
            height
            width
          }
          system {
            uid
          }
          content_type
        }
      }
    }
  }
  translation_strings {
    groups
  }
  system {
    uid
    content_type_uid
    updated_at
  }
}
    `, {"fragmentName":"PageFragment"}) as unknown as TypedDocumentString<PageFragmentFragment, unknown>;
export const HeroBannerFragmentFragmentDoc = new TypedDocumentString(`
    fragment HeroBannerFragment on VildarPageComponentsBanner {
  __typename
  banner {
    cta {
      external_link {
        href
        title
      }
      internal_linkConnection {
        edges {
          node {
            ... on VildarPage {
              navigation_title
              url
            }
          }
        }
      }
      label
    }
    headline
    image {
      alt_text
      crop_resize
      gravity
      imgConnection {
        ...SysAssetConnectionFragment
      }
    }
    layout
    paragraph
    rounded
    styled
    mobile_layout
    object_fit
    expand_image
    main_banner
  }
}
    fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"HeroBannerFragment"}) as unknown as TypedDocumentString<HeroBannerFragmentFragment, unknown>;
export const ContentSectionFragmentFragmentDoc = new TypedDocumentString(`
    fragment ContentSectionFragment on VildarPageComponentsContentSection {
  ... on VildarPageComponentsContentSection {
    __typename
    content_section {
      cta {
        label
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                title
                url
              }
              ... on VildarContentPosts {
                title
                url
              }
            }
          }
          totalCount
        }
      }
      headline
      layout
      paragraph
      variant
      image {
        alt_text
        crop_resize
        gravity
        imgConnection {
          ...SysAssetConnectionFragment
        }
      }
      video {
        url
        autoplay
        controls
      }
      content_postConnection {
        edges {
          node {
            ... on VildarContentPosts {
              date
              show_date
              display_title
              short_description
              url
              type
              image {
                crop_resize
                gravity
                alt_text
                assetConnection {
                  ...SysAssetConnectionFragment
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
}
    fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"ContentSectionFragment"}) as unknown as TypedDocumentString<ContentSectionFragmentFragment, unknown>;
export const CardSectionSimpleBoxesFragmentFragmentDoc = new TypedDocumentString(`
    fragment CardSectionSimpleBoxesFragment on VildarCardSection {
  simple_boxes {
    ... on VildarCardSectionSimpleBoxesBox {
      __typename
      box {
        background_color
        headline
        text
      }
    }
  }
}
    `, {"fragmentName":"CardSectionSimpleBoxesFragment"}) as unknown as TypedDocumentString<CardSectionSimpleBoxesFragmentFragment, unknown>;
export const CardSectionPersonsFragmentFragmentDoc = new TypedDocumentString(`
    fragment CardSectionPersonsFragment on VildarCardSection {
  persons {
    ... on VildarCardSectionPersonsPerson {
      __typename
      person {
        description
        name
        image {
          alt_text
          crop_resize
          gravity
          assetConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                system {
                  uid
                }
                content_type
              }
            }
          }
        }
      }
    }
  }
}
    `, {"fragmentName":"CardSectionPersonsFragment"}) as unknown as TypedDocumentString<CardSectionPersonsFragmentFragment, unknown>;
export const VildarCardSectionCardsFragmentFragmentDoc = new TypedDocumentString(`
    fragment VildarCardSectionCardsFragment on VildarCardSection {
  __typename
  title
  display_title
  ...CardSectionSimpleBoxesFragment
  sidebar
  post_limit
  ...CardSectionPersonsFragment
  paragraph
  pagination
  link {
    external_url {
      href
      title
    }
    internal_urlConnection {
      edges {
        node {
          ... on VildarPage {
            navigation_title
            url
          }
        }
      }
    }
    label
  }
  type
  card_template
}
    fragment CardSectionSimpleBoxesFragment on VildarCardSection {
  simple_boxes {
    ... on VildarCardSectionSimpleBoxesBox {
      __typename
      box {
        background_color
        headline
        text
      }
    }
  }
}
fragment CardSectionPersonsFragment on VildarCardSection {
  persons {
    ... on VildarCardSectionPersonsPerson {
      __typename
      person {
        description
        name
        image {
          alt_text
          crop_resize
          gravity
          assetConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                system {
                  uid
                }
                content_type
              }
            }
          }
        }
      }
    }
  }
}`, {"fragmentName":"VildarCardSectionCardsFragment"}) as unknown as TypedDocumentString<VildarCardSectionCardsFragmentFragment, unknown>;
export const VildarCardSectionFragmentFragmentDoc = new TypedDocumentString(`
    fragment VildarCardSectionFragment on VildarPageComponentsCards {
  __typename
  cards {
    card_sectionConnection {
      edges {
        __typename
        node {
          ...VildarCardSectionCardsFragment
        }
      }
    }
  }
}
    fragment VildarCardSectionCardsFragment on VildarCardSection {
  __typename
  title
  display_title
  ...CardSectionSimpleBoxesFragment
  sidebar
  post_limit
  ...CardSectionPersonsFragment
  paragraph
  pagination
  link {
    external_url {
      href
      title
    }
    internal_urlConnection {
      edges {
        node {
          ... on VildarPage {
            navigation_title
            url
          }
        }
      }
    }
    label
  }
  type
  card_template
}
fragment CardSectionSimpleBoxesFragment on VildarCardSection {
  simple_boxes {
    ... on VildarCardSectionSimpleBoxesBox {
      __typename
      box {
        background_color
        headline
        text
      }
    }
  }
}
fragment CardSectionPersonsFragment on VildarCardSection {
  persons {
    ... on VildarCardSectionPersonsPerson {
      __typename
      person {
        description
        name
        image {
          alt_text
          crop_resize
          gravity
          assetConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                system {
                  uid
                }
                content_type
              }
            }
          }
        }
      }
    }
  }
}`, {"fragmentName":"VildarCardSectionFragment"}) as unknown as TypedDocumentString<VildarCardSectionFragmentFragment, unknown>;
export const VildarIframeSectionFragmentFragmentDoc = new TypedDocumentString(`
    fragment VildarIframeSectionFragment on VildarPageComponentsForms {
  __typename
  forms {
    iframe_type
    iframe_url {
      href
      title
    }
  }
}
    `, {"fragmentName":"VildarIframeSectionFragment"}) as unknown as TypedDocumentString<VildarIframeSectionFragmentFragment, unknown>;
export const TabSectionCardSectionFragmentFragmentDoc = new TypedDocumentString(`
    fragment TabSectionCardSectionFragment on VildarPageComponentsTabsSectionBlockTabCardSectionNode {
  ... on VildarCardSection {
    title
    display_title
    link {
      external_url {
        href
        title
      }
      internal_urlConnection {
        edges {
          node {
            ... on VildarPage {
              title
              navigation_title
              url
            }
          }
        }
        totalCount
      }
      label
    }
    card_template
    pagination
    paragraph
    post_limit
    sidebar
    type
    simple_boxes {
      ... on VildarCardSectionSimpleBoxesBox {
        __typename
        box {
          background_color
          headline
          text
        }
      }
    }
    persons {
      ... on VildarCardSectionPersonsPerson {
        __typename
        person {
          description
          name
        }
      }
    }
  }
}
    `, {"fragmentName":"TabSectionCardSectionFragment"}) as unknown as TypedDocumentString<TabSectionCardSectionFragmentFragment, unknown>;
export const VildarTabSectionFragmentFragmentDoc = new TypedDocumentString(`
    fragment VildarTabSectionFragment on VildarPageComponentsTabsSection {
  ... on VildarPageComponentsTabsSection {
    __typename
    tabs_section {
      tab {
        title
        card_sectionConnection {
          edges {
            node {
              ...TabSectionCardSectionFragment
            }
          }
          totalCount
        }
      }
    }
  }
}
    fragment TabSectionCardSectionFragment on VildarPageComponentsTabsSectionBlockTabCardSectionNode {
  ... on VildarCardSection {
    title
    display_title
    link {
      external_url {
        href
        title
      }
      internal_urlConnection {
        edges {
          node {
            ... on VildarPage {
              title
              navigation_title
              url
            }
          }
        }
        totalCount
      }
      label
    }
    card_template
    pagination
    paragraph
    post_limit
    sidebar
    type
    simple_boxes {
      ... on VildarCardSectionSimpleBoxesBox {
        __typename
        box {
          background_color
          headline
          text
        }
      }
    }
    persons {
      ... on VildarCardSectionPersonsPerson {
        __typename
        person {
          description
          name
        }
      }
    }
  }
}`, {"fragmentName":"VildarTabSectionFragment"}) as unknown as TypedDocumentString<VildarTabSectionFragmentFragment, unknown>;
export const DocumentsSectionFragmentFragmentDoc = new TypedDocumentString(`
    fragment DocumentsSectionFragment on VildarPageComponentsDocuments {
  __typename
  documents {
    headline
    paragraph
    files {
      title
      file_referenceConnection {
        edges {
          node {
            url
          }
        }
      }
    }
  }
}
    `, {"fragmentName":"DocumentsSectionFragment"}) as unknown as TypedDocumentString<DocumentsSectionFragmentFragment, unknown>;
export const ComponentsFragmentFragmentDoc = new TypedDocumentString(`
    fragment ComponentsFragment on VildarPage {
  components {
    ...HeroBannerFragment
    ...ContentSectionFragment
    ...VildarCardSectionFragment
    ...VildarIframeSectionFragment
    ...VildarTabSectionFragment
    ...DocumentsSectionFragment
  }
}
    fragment HeroBannerFragment on VildarPageComponentsBanner {
  __typename
  banner {
    cta {
      external_link {
        href
        title
      }
      internal_linkConnection {
        edges {
          node {
            ... on VildarPage {
              navigation_title
              url
            }
          }
        }
      }
      label
    }
    headline
    image {
      alt_text
      crop_resize
      gravity
      imgConnection {
        ...SysAssetConnectionFragment
      }
    }
    layout
    paragraph
    rounded
    styled
    mobile_layout
    object_fit
    expand_image
    main_banner
  }
}
fragment VildarCardSectionFragment on VildarPageComponentsCards {
  __typename
  cards {
    card_sectionConnection {
      edges {
        __typename
        node {
          ...VildarCardSectionCardsFragment
        }
      }
    }
  }
}
fragment VildarCardSectionCardsFragment on VildarCardSection {
  __typename
  title
  display_title
  ...CardSectionSimpleBoxesFragment
  sidebar
  post_limit
  ...CardSectionPersonsFragment
  paragraph
  pagination
  link {
    external_url {
      href
      title
    }
    internal_urlConnection {
      edges {
        node {
          ... on VildarPage {
            navigation_title
            url
          }
        }
      }
    }
    label
  }
  type
  card_template
}
fragment CardSectionSimpleBoxesFragment on VildarCardSection {
  simple_boxes {
    ... on VildarCardSectionSimpleBoxesBox {
      __typename
      box {
        background_color
        headline
        text
      }
    }
  }
}
fragment CardSectionPersonsFragment on VildarCardSection {
  persons {
    ... on VildarCardSectionPersonsPerson {
      __typename
      person {
        description
        name
        image {
          alt_text
          crop_resize
          gravity
          assetConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                system {
                  uid
                }
                content_type
              }
            }
          }
        }
      }
    }
  }
}
fragment ContentSectionFragment on VildarPageComponentsContentSection {
  ... on VildarPageComponentsContentSection {
    __typename
    content_section {
      cta {
        label
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                title
                url
              }
              ... on VildarContentPosts {
                title
                url
              }
            }
          }
          totalCount
        }
      }
      headline
      layout
      paragraph
      variant
      image {
        alt_text
        crop_resize
        gravity
        imgConnection {
          ...SysAssetConnectionFragment
        }
      }
      video {
        url
        autoplay
        controls
      }
      content_postConnection {
        edges {
          node {
            ... on VildarContentPosts {
              date
              show_date
              display_title
              short_description
              url
              type
              image {
                crop_resize
                gravity
                alt_text
                assetConnection {
                  ...SysAssetConnectionFragment
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
}
fragment DocumentsSectionFragment on VildarPageComponentsDocuments {
  __typename
  documents {
    headline
    paragraph
    files {
      title
      file_referenceConnection {
        edges {
          node {
            url
          }
        }
      }
    }
  }
}
fragment VildarIframeSectionFragment on VildarPageComponentsForms {
  __typename
  forms {
    iframe_type
    iframe_url {
      href
      title
    }
  }
}
fragment TabSectionCardSectionFragment on VildarPageComponentsTabsSectionBlockTabCardSectionNode {
  ... on VildarCardSection {
    title
    display_title
    link {
      external_url {
        href
        title
      }
      internal_urlConnection {
        edges {
          node {
            ... on VildarPage {
              title
              navigation_title
              url
            }
          }
        }
        totalCount
      }
      label
    }
    card_template
    pagination
    paragraph
    post_limit
    sidebar
    type
    simple_boxes {
      ... on VildarCardSectionSimpleBoxesBox {
        __typename
        box {
          background_color
          headline
          text
        }
      }
    }
    persons {
      ... on VildarCardSectionPersonsPerson {
        __typename
        person {
          description
          name
        }
      }
    }
  }
}
fragment VildarTabSectionFragment on VildarPageComponentsTabsSection {
  ... on VildarPageComponentsTabsSection {
    __typename
    tabs_section {
      tab {
        title
        card_sectionConnection {
          edges {
            node {
              ...TabSectionCardSectionFragment
            }
          }
          totalCount
        }
      }
    }
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"ComponentsFragment"}) as unknown as TypedDocumentString<ComponentsFragmentFragment, unknown>;
export const VildarPageQueryFragmentFragmentDoc = new TypedDocumentString(`
    fragment VildarPageQueryFragment on VildarPage {
  ...PageFragment
  ...ComponentsFragment
}
    fragment HeroBannerFragment on VildarPageComponentsBanner {
  __typename
  banner {
    cta {
      external_link {
        href
        title
      }
      internal_linkConnection {
        edges {
          node {
            ... on VildarPage {
              navigation_title
              url
            }
          }
        }
      }
      label
    }
    headline
    image {
      alt_text
      crop_resize
      gravity
      imgConnection {
        ...SysAssetConnectionFragment
      }
    }
    layout
    paragraph
    rounded
    styled
    mobile_layout
    object_fit
    expand_image
    main_banner
  }
}
fragment VildarCardSectionFragment on VildarPageComponentsCards {
  __typename
  cards {
    card_sectionConnection {
      edges {
        __typename
        node {
          ...VildarCardSectionCardsFragment
        }
      }
    }
  }
}
fragment VildarCardSectionCardsFragment on VildarCardSection {
  __typename
  title
  display_title
  ...CardSectionSimpleBoxesFragment
  sidebar
  post_limit
  ...CardSectionPersonsFragment
  paragraph
  pagination
  link {
    external_url {
      href
      title
    }
    internal_urlConnection {
      edges {
        node {
          ... on VildarPage {
            navigation_title
            url
          }
        }
      }
    }
    label
  }
  type
  card_template
}
fragment CardSectionSimpleBoxesFragment on VildarCardSection {
  simple_boxes {
    ... on VildarCardSectionSimpleBoxesBox {
      __typename
      box {
        background_color
        headline
        text
      }
    }
  }
}
fragment CardSectionPersonsFragment on VildarCardSection {
  persons {
    ... on VildarCardSectionPersonsPerson {
      __typename
      person {
        description
        name
        image {
          alt_text
          crop_resize
          gravity
          assetConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                system {
                  uid
                }
                content_type
              }
            }
          }
        }
      }
    }
  }
}
fragment ContentSectionFragment on VildarPageComponentsContentSection {
  ... on VildarPageComponentsContentSection {
    __typename
    content_section {
      cta {
        label
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                title
                url
              }
              ... on VildarContentPosts {
                title
                url
              }
            }
          }
          totalCount
        }
      }
      headline
      layout
      paragraph
      variant
      image {
        alt_text
        crop_resize
        gravity
        imgConnection {
          ...SysAssetConnectionFragment
        }
      }
      video {
        url
        autoplay
        controls
      }
      content_postConnection {
        edges {
          node {
            ... on VildarContentPosts {
              date
              show_date
              display_title
              short_description
              url
              type
              image {
                crop_resize
                gravity
                alt_text
                assetConnection {
                  ...SysAssetConnectionFragment
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
}
fragment DocumentsSectionFragment on VildarPageComponentsDocuments {
  __typename
  documents {
    headline
    paragraph
    files {
      title
      file_referenceConnection {
        edges {
          node {
            url
          }
        }
      }
    }
  }
}
fragment VildarIframeSectionFragment on VildarPageComponentsForms {
  __typename
  forms {
    iframe_type
    iframe_url {
      href
      title
    }
  }
}
fragment ComponentsFragment on VildarPage {
  components {
    ...HeroBannerFragment
    ...ContentSectionFragment
    ...VildarCardSectionFragment
    ...VildarIframeSectionFragment
    ...VildarTabSectionFragment
    ...DocumentsSectionFragment
  }
}
fragment PageFragment on VildarPage {
  __typename
  title
  url
  hidden
  seo {
    description
    title
    imageConnection {
      __typename
      edges {
        node {
          __typename
          dimension {
            height
            width
          }
          system {
            uid
          }
          content_type
        }
      }
    }
  }
  translation_strings {
    groups
  }
  system {
    uid
    content_type_uid
    updated_at
  }
}
fragment TabSectionCardSectionFragment on VildarPageComponentsTabsSectionBlockTabCardSectionNode {
  ... on VildarCardSection {
    title
    display_title
    link {
      external_url {
        href
        title
      }
      internal_urlConnection {
        edges {
          node {
            ... on VildarPage {
              title
              navigation_title
              url
            }
          }
        }
        totalCount
      }
      label
    }
    card_template
    pagination
    paragraph
    post_limit
    sidebar
    type
    simple_boxes {
      ... on VildarCardSectionSimpleBoxesBox {
        __typename
        box {
          background_color
          headline
          text
        }
      }
    }
    persons {
      ... on VildarCardSectionPersonsPerson {
        __typename
        person {
          description
          name
        }
      }
    }
  }
}
fragment VildarTabSectionFragment on VildarPageComponentsTabsSection {
  ... on VildarPageComponentsTabsSection {
    __typename
    tabs_section {
      tab {
        title
        card_sectionConnection {
          edges {
            node {
              ...TabSectionCardSectionFragment
            }
          }
          totalCount
        }
      }
    }
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"VildarPageQueryFragment"}) as unknown as TypedDocumentString<VildarPageQueryFragmentFragment, unknown>;
export const ImageFragmentFragmentDoc = new TypedDocumentString(`
    fragment ImageFragment on VildarContentPosts {
  image {
    alt_text
    crop_resize
    gravity
    assetConnection {
      ...SysAssetConnectionFragment
    }
  }
}
    fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"ImageFragment"}) as unknown as TypedDocumentString<ImageFragmentFragment, unknown>;
export const ContentPostFragmentFragmentDoc = new TypedDocumentString(`
    fragment ContentPostFragment on VildarContentPosts {
  __typename
  text
  title
  type
  url
  short_description
  display_title
  date
  show_date
  link_label
  next_post
  ...ImageFragment
  system {
    updated_at
  }
}
    fragment ImageFragment on VildarContentPosts {
  image {
    alt_text
    crop_resize
    gravity
    assetConnection {
      ...SysAssetConnectionFragment
    }
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"ContentPostFragment"}) as unknown as TypedDocumentString<ContentPostFragmentFragment, unknown>;
export const ContentPostsListFragmentFragmentDoc = new TypedDocumentString(`
    fragment ContentPostsListFragment on AllVildarContentPosts {
  total
  items {
    ...ContentPostFragment
  }
}
    fragment ImageFragment on VildarContentPosts {
  image {
    alt_text
    crop_resize
    gravity
    assetConnection {
      ...SysAssetConnectionFragment
    }
  }
}
fragment ContentPostFragment on VildarContentPosts {
  __typename
  text
  title
  type
  url
  short_description
  display_title
  date
  show_date
  link_label
  next_post
  ...ImageFragment
  system {
    updated_at
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`, {"fragmentName":"ContentPostsListFragment"}) as unknown as TypedDocumentString<ContentPostsListFragmentFragment, unknown>;
export const NavigationAndFooterDocument = new TypedDocumentString(`
    query NavigationAndFooter($navId: String!, $footerId: String!, $locale: String!) {
  navigation: vildar_navigation(
    uid: $navId
    locale: $locale
    fallback_locale: true
  ) {
    ...NavigationMenuFragment
  }
  footer: vildar_navigation(
    uid: $footerId
    locale: $locale
    fallback_locale: true
  ) {
    ...NavigationAndFooterFragment
  }
}
    fragment NavigationAndFooterFragment on VildarNavigation {
  ...NavigationMenuFragment
  footer_specific {
    ...FooterSpecificFragment
  }
}
fragment NavigationMenuFragment on VildarNavigation {
  buttons {
    ... on VildarNavigationButtonsLanguagePicker {
      __typename
      language_picker {
        languages
      }
    }
    ... on VildarNavigationButtonsCta {
      __typename
      cta {
        button_style
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
        label
      }
    }
  }
  links {
    ... on VildarNavigationLinksCta {
      __typename
      cta {
        external_link {
          href
          title
        }
        label
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
      }
    }
  }
  logo {
    alt_text
    assetConnection {
      ...SysAssetConnectionFragment
    }
    crop_resize
    gravity
  }
  pagesConnection {
    edges {
      node {
        ... on VildarPage {
          navigation_title
          url
        }
      }
    }
  }
  title
}
fragment FooterSpecificFragment on VildarNavigationFooterSpecific {
  headline
  email
  icelandair_logo {
    assetConnection {
      edges {
        node {
          system {
            uid
          }
          content_type
          dimension {
            height
            width
          }
        }
      }
    }
    crop_resize
    gravity
  }
  impressum
  phone
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`) as unknown as TypedDocumentString<NavigationAndFooterQuery, NavigationAndFooterQueryVariables>;
export const NavigationMenuDocument = new TypedDocumentString(`
    query NavigationMenu($uid: String!, $locale: String!, $footer: Boolean!) {
  vildar_navigation(uid: $uid, locale: $locale, fallback_locale: true) {
    ...NavigationMenuFragment
    footer_specific @include(if: $footer) {
      ...FooterSpecificFragment
    }
  }
}
    fragment NavigationMenuFragment on VildarNavigation {
  buttons {
    ... on VildarNavigationButtonsLanguagePicker {
      __typename
      language_picker {
        languages
      }
    }
    ... on VildarNavigationButtonsCta {
      __typename
      cta {
        button_style
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
        label
      }
    }
  }
  links {
    ... on VildarNavigationLinksCta {
      __typename
      cta {
        external_link {
          href
          title
        }
        label
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                navigation_title
                url
              }
            }
          }
        }
      }
    }
  }
  logo {
    alt_text
    assetConnection {
      ...SysAssetConnectionFragment
    }
    crop_resize
    gravity
  }
  pagesConnection {
    edges {
      node {
        ... on VildarPage {
          navigation_title
          url
        }
      }
    }
  }
  title
}
fragment FooterSpecificFragment on VildarNavigationFooterSpecific {
  headline
  email
  icelandair_logo {
    assetConnection {
      edges {
        node {
          system {
            uid
          }
          content_type
          dimension {
            height
            width
          }
        }
      }
    }
    crop_resize
    gravity
  }
  impressum
  phone
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`) as unknown as TypedDocumentString<NavigationMenuQuery, NavigationMenuQueryVariables>;
export const GetPageByUrlDocument = new TypedDocumentString(`
    query getPageByUrl($url: String!, $locale: String!) {
  all_vildar_page(fallback_locale: true, locale: $locale, where: {url: $url}) {
    __typename
    items {
      ...PageFragment
      ...ComponentsFragment
    }
  }
}
    fragment HeroBannerFragment on VildarPageComponentsBanner {
  __typename
  banner {
    cta {
      external_link {
        href
        title
      }
      internal_linkConnection {
        edges {
          node {
            ... on VildarPage {
              navigation_title
              url
            }
          }
        }
      }
      label
    }
    headline
    image {
      alt_text
      crop_resize
      gravity
      imgConnection {
        ...SysAssetConnectionFragment
      }
    }
    layout
    paragraph
    rounded
    styled
    mobile_layout
    object_fit
    expand_image
    main_banner
  }
}
fragment VildarCardSectionFragment on VildarPageComponentsCards {
  __typename
  cards {
    card_sectionConnection {
      edges {
        __typename
        node {
          ...VildarCardSectionCardsFragment
        }
      }
    }
  }
}
fragment VildarCardSectionCardsFragment on VildarCardSection {
  __typename
  title
  display_title
  ...CardSectionSimpleBoxesFragment
  sidebar
  post_limit
  ...CardSectionPersonsFragment
  paragraph
  pagination
  link {
    external_url {
      href
      title
    }
    internal_urlConnection {
      edges {
        node {
          ... on VildarPage {
            navigation_title
            url
          }
        }
      }
    }
    label
  }
  type
  card_template
}
fragment CardSectionSimpleBoxesFragment on VildarCardSection {
  simple_boxes {
    ... on VildarCardSectionSimpleBoxesBox {
      __typename
      box {
        background_color
        headline
        text
      }
    }
  }
}
fragment CardSectionPersonsFragment on VildarCardSection {
  persons {
    ... on VildarCardSectionPersonsPerson {
      __typename
      person {
        description
        name
        image {
          alt_text
          crop_resize
          gravity
          assetConnection {
            edges {
              node {
                dimension {
                  height
                  width
                }
                system {
                  uid
                }
                content_type
              }
            }
          }
        }
      }
    }
  }
}
fragment ContentSectionFragment on VildarPageComponentsContentSection {
  ... on VildarPageComponentsContentSection {
    __typename
    content_section {
      cta {
        label
        external_link {
          href
          title
        }
        internal_linkConnection {
          edges {
            node {
              ... on VildarPage {
                title
                url
              }
              ... on VildarContentPosts {
                title
                url
              }
            }
          }
          totalCount
        }
      }
      headline
      layout
      paragraph
      variant
      image {
        alt_text
        crop_resize
        gravity
        imgConnection {
          ...SysAssetConnectionFragment
        }
      }
      video {
        url
        autoplay
        controls
      }
      content_postConnection {
        edges {
          node {
            ... on VildarContentPosts {
              date
              show_date
              display_title
              short_description
              url
              type
              image {
                crop_resize
                gravity
                alt_text
                assetConnection {
                  ...SysAssetConnectionFragment
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
}
fragment DocumentsSectionFragment on VildarPageComponentsDocuments {
  __typename
  documents {
    headline
    paragraph
    files {
      title
      file_referenceConnection {
        edges {
          node {
            url
          }
        }
      }
    }
  }
}
fragment VildarIframeSectionFragment on VildarPageComponentsForms {
  __typename
  forms {
    iframe_type
    iframe_url {
      href
      title
    }
  }
}
fragment ComponentsFragment on VildarPage {
  components {
    ...HeroBannerFragment
    ...ContentSectionFragment
    ...VildarCardSectionFragment
    ...VildarIframeSectionFragment
    ...VildarTabSectionFragment
    ...DocumentsSectionFragment
  }
}
fragment PageFragment on VildarPage {
  __typename
  title
  url
  hidden
  seo {
    description
    title
    imageConnection {
      __typename
      edges {
        node {
          __typename
          dimension {
            height
            width
          }
          system {
            uid
          }
          content_type
        }
      }
    }
  }
  translation_strings {
    groups
  }
  system {
    uid
    content_type_uid
    updated_at
  }
}
fragment TabSectionCardSectionFragment on VildarPageComponentsTabsSectionBlockTabCardSectionNode {
  ... on VildarCardSection {
    title
    display_title
    link {
      external_url {
        href
        title
      }
      internal_urlConnection {
        edges {
          node {
            ... on VildarPage {
              title
              navigation_title
              url
            }
          }
        }
        totalCount
      }
      label
    }
    card_template
    pagination
    paragraph
    post_limit
    sidebar
    type
    simple_boxes {
      ... on VildarCardSectionSimpleBoxesBox {
        __typename
        box {
          background_color
          headline
          text
        }
      }
    }
    persons {
      ... on VildarCardSectionPersonsPerson {
        __typename
        person {
          description
          name
        }
      }
    }
  }
}
fragment VildarTabSectionFragment on VildarPageComponentsTabsSection {
  ... on VildarPageComponentsTabsSection {
    __typename
    tabs_section {
      tab {
        title
        card_sectionConnection {
          edges {
            node {
              ...TabSectionCardSectionFragment
            }
          }
          totalCount
        }
      }
    }
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`) as unknown as TypedDocumentString<GetPageByUrlQuery, GetPageByUrlQueryVariables>;
export const GetAllTranslationGroupsDocument = new TypedDocumentString(`
    query getAllTranslationGroups {
  all_vildar_translation_group {
    items {
      code_reference
      system {
        uid
      }
    }
  }
}
    `) as unknown as TypedDocumentString<GetAllTranslationGroupsQuery, GetAllTranslationGroupsQueryVariables>;
export const GetContentPostsDocument = new TypedDocumentString(`
    query getContentPosts($type: String!, $limit: Int!) {
  all_vildar_content_posts(
    where: {type: $type}
    limit: $limit
    order_by: updated_at_DESC
  ) {
    ...ContentPostsListFragment
  }
}
    fragment ContentPostsListFragment on AllVildarContentPosts {
  total
  items {
    ...ContentPostFragment
  }
}
fragment ImageFragment on VildarContentPosts {
  image {
    alt_text
    crop_resize
    gravity
    assetConnection {
      ...SysAssetConnectionFragment
    }
  }
}
fragment ContentPostFragment on VildarContentPosts {
  __typename
  text
  title
  type
  url
  short_description
  display_title
  date
  show_date
  link_label
  next_post
  ...ImageFragment
  system {
    updated_at
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`) as unknown as TypedDocumentString<GetContentPostsQuery, GetContentPostsQueryVariables>;
export const GetContentPostByLocaleDocument = new TypedDocumentString(`
    query getContentPostByLocale($type: String!, $limit: Int!, $locale: String!, $skip: Int!) {
  all_vildar_content_posts(
    where: {type: $type}
    locale: $locale
    limit: $limit
    skip: $skip
    order_by: updated_at_DESC
  ) {
    ...ContentPostsListFragment
  }
}
    fragment ContentPostsListFragment on AllVildarContentPosts {
  total
  items {
    ...ContentPostFragment
  }
}
fragment ImageFragment on VildarContentPosts {
  image {
    alt_text
    crop_resize
    gravity
    assetConnection {
      ...SysAssetConnectionFragment
    }
  }
}
fragment ContentPostFragment on VildarContentPosts {
  __typename
  text
  title
  type
  url
  short_description
  display_title
  date
  show_date
  link_label
  next_post
  ...ImageFragment
  system {
    updated_at
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`) as unknown as TypedDocumentString<GetContentPostByLocaleQuery, GetContentPostByLocaleQueryVariables>;
export const GetContentPostsByUrlAndTypeDocument = new TypedDocumentString(`
    query getContentPostsByUrlAndType($type: String!, $limit: Int!, $url: String!, $locale: String!) {
  all_vildar_content_posts(
    where: {type: $type, url: $url}
    locale: $locale
    limit: $limit
    order_by: updated_at_DESC
  ) {
    ...ContentPostsListFragment
  }
}
    fragment ContentPostsListFragment on AllVildarContentPosts {
  total
  items {
    ...ContentPostFragment
  }
}
fragment ImageFragment on VildarContentPosts {
  image {
    alt_text
    crop_resize
    gravity
    assetConnection {
      ...SysAssetConnectionFragment
    }
  }
}
fragment ContentPostFragment on VildarContentPosts {
  __typename
  text
  title
  type
  url
  short_description
  display_title
  date
  show_date
  link_label
  next_post
  ...ImageFragment
  system {
    updated_at
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`) as unknown as TypedDocumentString<GetContentPostsByUrlAndTypeQuery, GetContentPostsByUrlAndTypeQueryVariables>;
export const GetContentPostsByUrlDocument = new TypedDocumentString(`
    query getContentPostsByUrl($limit: Int!, $url: String!, $locale: String!) {
  all_vildar_content_posts(
    where: {url: $url}
    locale: $locale
    limit: $limit
    order_by: updated_at_DESC
  ) {
    ...ContentPostsListFragment
  }
}
    fragment ContentPostsListFragment on AllVildarContentPosts {
  total
  items {
    ...ContentPostFragment
  }
}
fragment ImageFragment on VildarContentPosts {
  image {
    alt_text
    crop_resize
    gravity
    assetConnection {
      ...SysAssetConnectionFragment
    }
  }
}
fragment ContentPostFragment on VildarContentPosts {
  __typename
  text
  title
  type
  url
  short_description
  display_title
  date
  show_date
  link_label
  next_post
  ...ImageFragment
  system {
    updated_at
  }
}
fragment SysAssetConnectionFragment on SysAssetConnection {
  __typename
  edges {
    node {
      content_type
      description
      dimension {
        height
        width
      }
      system {
        uid
      }
    }
  }
  totalCount
}`) as unknown as TypedDocumentString<GetContentPostsByUrlQuery, GetContentPostsByUrlQueryVariables>;
export const GetAllContentPostsDocument = new TypedDocumentString(`
    query getAllContentPosts {
  is: all_vildar_content_posts(locale: "is-is", where: {locale: "is-is"}) {
    items {
      url
      system {
        uid
        locale
        updated_at
      }
      type
      title
    }
  }
  en: all_vildar_content_posts(locale: "en-us", where: {locale: "en-us"}) {
    items {
      url
      system {
        uid
        locale
        updated_at
      }
      type
      title
    }
  }
}
    `) as unknown as TypedDocumentString<GetAllContentPostsQuery, GetAllContentPostsQueryVariables>;
export const GetAllPagesByDocument = new TypedDocumentString(`
    query GetAllPagesBy {
  is: all_vildar_page(fallback_locale: true, locale: "is-is") {
    items {
      url
      navigation_title
      system {
        uid
        updated_at
      }
    }
  }
  en: all_vildar_page(fallback_locale: true, locale: "en-us") {
    items {
      url
      navigation_title
      system {
        uid
        updated_at
      }
    }
  }
}
    `) as unknown as TypedDocumentString<GetAllPagesByQuery, GetAllPagesByQueryVariables>;
export const GetSingleTranslationGroupDocument = new TypedDocumentString(`
    query getSingleTranslationGroup($locale: String!, $uid: String!) {
  vildar_translation_group(uid: $uid, locale: $locale, fallback_locale: true) {
    code_reference
    strings {
      ... on VildarTranslationGroupStringsTranslation {
        __typename
        translation {
          key
          value
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<GetSingleTranslationGroupQuery, GetSingleTranslationGroupQueryVariables>;